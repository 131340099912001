import defaultMessages from './en.json';
import getFieldLabel from 'helpers/getFieldLabel.js';

class Translate {
  constructor(messages) {
    this.messages = messages;
  }

  jsx(key, data) {
    let value = this.messages ? this.messages[key] || defaultMessages[key].value : defaultMessages[key].value;

    return getFieldLabel(value, data || {}, 'jsx');
  }

  text(key, data) {
    let value = this.messages ? this.messages[key] || defaultMessages[key].value : defaultMessages[key].value;

    return getFieldLabel(value, data || {}, 'text');
  }
}

export default Translate;
