/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import React, { useState, useMemo, useRef, useCallback } from 'react';
import { LabelAtom, DescriptionAtom } from './atoms.js';
import ShortText from './ShortText.js';
import isJsonString from 'helpers/isJsonString.js';
import getFieldLabel from 'helpers/getFieldLabel.js';
import Translate from '../messages/Translate.js';

import SingleMultipleChoiceOptions from './SingleMultipleChoiceOptions';

const alignOptions = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

const Checkbox = ({
  form,
  field,
  theme,
  value,
  values,
  onChange,
  onAfterChange,
  onPartialResponse,
  onNextClick,
  fieldsOptions,
  variables,
  urlParams,
  onBlur,
  onClick
}) => {
  if (!onClick) onClick = () => {};
  if (!onBlur) onBlur = () => {};

  const translate = useMemo(() => new Translate(form.messages), [form.messages]);

  const [expandOther, setShowOther] = useState(false);

  let options = useMemo(() => field.options, [field.options]);

  const mainStyle = useMemo(
    () =>
      css({
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: alignOptions[theme.singleMultipleChoiceAlignment],
        margin: `${theme.singleMultipleChoiceTopMargin || 0}px 0 ${theme.singleMultipleChoiceBottomMargin || 0}px 0`,
        '.ps > .ps__rail-x, .ps > .ps__rail-y': {
          opacity: 0.6
        }
      }),
    [theme.singleMultipleChoiceAlignment, theme.singleMultipleChoiceBottomMargin, theme.singleMultipleChoiceTopMargin]
  );

  const contentStyle = useMemo(
    () =>
      css({
        width: '100%',
        maxWidth: `${theme.singleMultipleChoiceWidth}${theme.singleMultipleChoiceWidthType}`
      }),
    [theme.singleMultipleChoiceWidth, theme.singleMultipleChoiceWidthType]
  );

  const contentRef = useRef();

  const handleOptionClick = useCallback(
    (ref) => {
      if (field.readonly) return;

      let data = value == null ? { values: [], other: '' } : JSON.parse(value);
      let refIndex = data.values.indexOf(ref);

      if (refIndex !== -1) {
        data.values.splice(refIndex, 1);
      } else {
        data.values.push(ref);
      }

      if (field.selectionLimits && field.selectionLimitsMax !== null && data.values.length > field.selectionLimitsMax) return;

      onChange({ [field._id]: JSON.stringify(data) });
      if (onAfterChange) onAfterChange({ [field._id]: JSON.stringify(data) });
      onPartialResponse(field._id);

      if ((field.selectionLimits && data.values.length === field.selectionLimitsMax) || options.length === data.values.length) {
        if (field.section === 'root' || !field.section) onNextClick(field);
      }
    },
    [field, onChange, onAfterChange, onNextClick, onPartialResponse, options.length, value]
  );

  const toggleExpandOther = useCallback(() => {
    setShowOther(!expandOther);
  }, [expandOther]);

  const handleOtherChange = useCallback(
    (otherValue) => {
      if (!expandOther) return;

      let data = value == null ? { values: [], other: '' } : JSON.parse(value);

      data.other = otherValue;

      onChange({ [field._id]: JSON.stringify(data) });
      if (onAfterChange) onAfterChange({ [field._id]: JSON.stringify(data) });
    },
    [field._id, onChange, onAfterChange, expandOther, value]
  );

  return (
    <>
      <div css={mainStyle}>
        <div css={contentStyle} ref={contentRef} onClick={() => onClick(field._id)}>
          <LabelAtom required={field.required} error={field.error} theme={theme}>
            {getFieldLabel(field.label, values, 'jsx', fieldsOptions, variables, urlParams)}
          </LabelAtom>

          <SingleMultipleChoiceOptions
            isMultipleChoice
            options={options}
            value={value}
            onOptionClick={handleOptionClick}
            expandOther={expandOther}
            onToggleExpandOther={toggleExpandOther}
            field={field}
            form={form}
            theme={theme}
            translate={translate}
          />

          {expandOther && (
            <ShortText
              field={{
                _id: 'other'
              }}
              form={form}
              theme={{
                ...theme,
                ...{ inputFieldsDropdownsWidth: 100, inputFieldsDropdownsWidthType: '%' }
              }}
              onChange={(obj) => handleOtherChange(obj.other)}
              autoFocus={true}
              onBlur={(e) => {
                onBlur(field);
                onPartialResponse(field._id);
              }}
              onPartialResponse={() => {}}
              country={{ code: null }}
              owner={{ country: null }}
              values={{
                other: {
                  value: JSON.parse(isJsonString(value) ? value : '{"value":null,"other":null}').other
                }
              }}
            />
          )}

          <DescriptionAtom theme={theme}>
            {getFieldLabel(field.description, values, 'jsx', fieldsOptions, variables, urlParams)}
          </DescriptionAtom>
        </div>
      </div>
    </>
  );
};

export default Checkbox;
