module.exports = typeof window !== 'undefined' ? {
  // client
  apiUrl: window.env.REACT_APP_API_URL,
  socketUrl: window.env.REACT_APP_SOCKET_URL,
  fullpageLicense: window.env.FULLPAGE_LICENSE,
  recaptchaSiteKey: window.env.RECAPTCHA_SITE_KEY
} : {
  // server
  port: process.env.PORT,
  development: process.env.DEVELOPMENT === 'true',

  mongo: {
    user: process.env.MONGO_USER,
    password: process.env.MONGO_PASSWORD,
    host: process.env.MONGO_HOST,
    port: process.env.MONGO_PORT,
    db: process.env.MONGO_DB,
    fullpageLicense: process.env.FULLPAGE_LICENSE
  }
};

