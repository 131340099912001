const _groupBy = require('lodash/groupBy');
const _orderBy = require('lodash/orderBy');

const getSectionFields = (fields, allFields) => {
  let newFields = [];
  let childrenField;

  for (let field of fields) {
    newFields.push(field);
    
    if (field.type === 'section' && Array.isArray(field.childrens) && field.childrens.length > 0) {
      for (let childrenId of field.childrens) {
        childrenField = allFields.find((f) => String(f._id) === String(childrenId));

        if (!childrenField) continue;

        if (childrenField.type === 'section') {
          newFields = [ ...newFields, ...getSectionFields([childrenField], allFields) ];
        } else {
          newFields.push(childrenField);
        }
      }
    }

  }

  return newFields;
};

export default function orderFields(fields) {
  const groups = _groupBy(fields, 'section');
  let newFields = fields.filter((f) => (f.section === 'root' || !f.section) && f.type !== 'section');
  let field = null;

  for (let group of Object.keys(groups)) {
    field = fields.find((f) => String(f._id) === String(group));

    if (field && (field.section === 'root' || !field.section)) newFields.push(field);
  }

  newFields = _orderBy(newFields, 'position');

  return getSectionFields(newFields, fields);
};
