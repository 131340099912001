/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import isEmail from 'validator/lib/isEmail';
import IntlTelInput from 'react-intl-tel-input';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import getFieldLabel from 'helpers/getFieldLabel.js';

import { LabelAtom, DescriptionAtom } from './atoms.js';

const formats = {
  phone: 'tel',
  number: 'number',
  email: 'email'
};

const alignOptions = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

const parsePreFilledValue = (value, urlParams = []) => {
  const isString = typeof value === "string";
  const isSlateLikeString = isString && value.trim().indexOf('[{') === 0;

  if (isSlateLikeString) {
    const result = getFieldLabel(value, undefined, 'text', undefined, undefined, urlParams);
    return typeof result === 'string' ? result : value;
  } else {
    return value;
  }
};

const ShortText = (props) => {
  let {
    field,
    theme,
    values,
    autoFocus,
    urlParams,
    onChange, 
    onAfterChange,
    onBlur,
    onPartialResponse,
    country,
    owner,
    fieldsOptions,
    variables,
    onClick
  } = props;

  const [active, setActive] = useState(false);

  const value = useMemo(() => values[field._id].value, [field._id, values]);
  const [preFilledValue] = useState(()=>parsePreFilledValue(field.value));

  if (!onBlur) onBlur = () => {};
  if (!onClick) onClick = () => {};

  const inputContainerStyleTypes = useMemo(
    () => ({
      style1: {
        borderBottom: `${theme.inputFieldsIdleBorderSize}px solid ${theme.inputFieldsIdleBorderColor}`
      },
      style2: {
        border: 'none'
      },
      style3: {
        border: `${theme.inputFieldsIdleBorderSize}px solid ${theme.inputFieldsIdleBorderColor}`
      }
    }),
    [theme.inputFieldsIdleBorderSize, theme.inputFieldsIdleBorderColor]
  );

  const mainStyle = useMemo(
    () =>
      css({
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: alignOptions[theme.inputFieldsDropdownsAlignment],
        margin: `${theme.inputFieldsDropdownsTopMargin || 0}px 0 ${theme.inputFieldsDropdownsBottomMargin || 0}px 0`
      }),
    [theme.inputFieldsDropdownsAlignment, theme.inputFieldsDropdownsTopMargin, theme.inputFieldsDropdownsBottomMargin]
  );

  const contentStyle = useMemo(
    () =>
      css({
        width: '100%',
        maxWidth: `${theme.inputFieldsDropdownsWidth}${theme.inputFieldsDropdownsWidthType}`
      }),
    [theme.inputFieldsDropdownsWidth, theme.inputFieldsDropdownsWidthType]
  );

  const inputContainerStyle = useMemo(
    () =>
      css({
        background: theme.inputFieldsIdleBackground,
        fontFamily: theme.typographyContentFontFamily,
        lineHeight: '142%',
        color: theme.inputFieldsIdleTextColor,
        fontWeight: theme.inputFieldsIdleTextWeight,
        fontSize: theme.inputFieldsIdleTextFontSize,
        borderRadius: `${theme.inputFieldsIdleRoundness}px`,
        boxShadow: theme.inputFieldsIdleShadow
          ? `${theme.inputFieldsIdleShadowOffsetX}px ${theme.inputFieldsIdleShadowOffsetY}px ${theme.inputFieldsIdleShadowBlur}px ${theme.inputFieldsIdleShadowSpread}px ${theme.inputFieldsIdleShadowColor}`
          : 'none',
        ...inputContainerStyleTypes[theme.inputFieldsDropdownsType],
        '& input': {
          width: '100%',
          lineHeight: '142%',
          color: theme.inputFieldsIdleTextColor,
          fontWeight: theme.inputFieldsIdleTextWeight,
          fontSize: theme.inputFieldsIdleTextFontSize,
          padding: `${theme.inputFieldsIdleVerticalPadding || 0}px ${theme.inputFieldsIdleHorizontalPadding || 0}px`,
          boxSizing: 'border-box'
        },
        '& input::placeholder': {
          color: theme.inputFieldsIdlePlaceholderColor,
          fontWeight: theme.inputFieldsIdleTextWeight,
          fontSize: theme.inputFieldsIdlePlaceholderFontSize
        },
        'input:-webkit-autofill::first-line, input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
          {
            transition: 'background-color 5000s ease-in-out 0s',
            fontFamily: theme.typographyContentFontFamily,
            color: theme.inputFieldsIdleTextColor,
            fontWeight: theme.inputFieldsIdleTextWeight,
            fontSize: theme.inputFieldsIdleTextFontSize
          },
        '&:hover': (() => {
          if (!theme.inputFieldsHoverEnable || active) return {};

          const obj = {
            background: theme.inputFieldsHoverBackground,
            borderColor: theme.inputFieldsHoverBorderColor,
            color: theme.inputFieldsHoverTextColor,
            '& input': {
              color: theme.inputFieldsHoverTextColor
            },
            '& input::placeholder': {
              color: theme.inputFieldsHoverPlaceholderColor
            }
          };

          if (theme.inputFieldsHoverShadow) {
            obj.boxShadow = `${theme.inputFieldsHoverShadowOffsetX}px ${theme.inputFieldsHoverShadowOffsetY}px ${theme.inputFieldsHoverShadowBlur}px ${theme.inputFieldsHoverShadowSpread}px ${theme.inputFieldsHoverShadowColor}`;
          } else {
            obj.boxShadow = 'none';
          }

          return obj;
        })(),
        '&.isActive': (() => {
          if (!theme.inputFieldsActiveEnable) return {};

          const obj = {
            background: theme.inputFieldsActiveBackground,
            borderColor: theme.inputFieldsActiveBorderColor,
            color: theme.inputFieldsActiveTextColor,
            '& input': {
              color: theme.inputFieldsActiveTextColor
            },
            '& input::placeholder': {
              color: theme.inputFieldsActivePlaceholderColor
            }
          };

          if (theme.inputFieldsActiveShadow) {
            obj.boxShadow = `${theme.inputFieldsActiveShadowOffsetX}px ${theme.inputFieldsActiveShadowOffsetY}px ${theme.inputFieldsActiveShadowBlur}px ${theme.inputFieldsActiveShadowSpread}px ${theme.inputFieldsActiveShadowColor}`;
          } else {
            obj.boxShadow = 'none';
          }

          return obj;
        })()
      }),
    [
      active,
      inputContainerStyleTypes,
      theme.inputFieldsActiveBackground,
      theme.inputFieldsActiveBorderColor,
      theme.inputFieldsActiveEnable,
      theme.inputFieldsActivePlaceholderColor,
      theme.inputFieldsActiveShadow,
      theme.inputFieldsActiveShadowBlur,
      theme.inputFieldsActiveShadowColor,
      theme.inputFieldsActiveShadowOffsetX,
      theme.inputFieldsActiveShadowOffsetY,
      theme.inputFieldsActiveShadowSpread,
      theme.inputFieldsActiveTextColor,
      theme.inputFieldsDropdownsType,
      theme.inputFieldsHoverBackground,
      theme.inputFieldsHoverBorderColor,
      theme.inputFieldsHoverEnable,
      theme.inputFieldsHoverPlaceholderColor,
      theme.inputFieldsHoverShadow,
      theme.inputFieldsHoverShadowBlur,
      theme.inputFieldsHoverShadowColor,
      theme.inputFieldsHoverShadowOffsetX,
      theme.inputFieldsHoverShadowOffsetY,
      theme.inputFieldsHoverShadowSpread,
      theme.inputFieldsHoverTextColor,
      theme.inputFieldsIdleBackground,
      theme.inputFieldsIdleHorizontalPadding,
      theme.inputFieldsIdlePlaceholderColor,
      theme.inputFieldsIdlePlaceholderFontSize,
      theme.inputFieldsIdleRoundness,
      theme.inputFieldsIdleShadow,
      theme.inputFieldsIdleShadowBlur,
      theme.inputFieldsIdleShadowColor,
      theme.inputFieldsIdleShadowOffsetX,
      theme.inputFieldsIdleShadowOffsetY,
      theme.inputFieldsIdleShadowSpread,
      theme.inputFieldsIdleTextColor,
      theme.inputFieldsIdleTextFontSize,
      theme.inputFieldsIdleTextWeight,
      theme.inputFieldsIdleVerticalPadding,
      theme.typographyContentFontFamily
    ]
  );

  const phoneContainerStyle = useMemo(
    () =>
      css({
        '.phone-container': {
          width: '100%',
          background: theme.inputFieldsIdleBackground,
          fontFamily: theme.typographyContentFontFamily,
          borderRadius: `${theme.inputFieldsIdleRoundness}px`,
          boxShadow: theme.inputFieldsIdleShadow
            ? `${theme.inputFieldsIdleShadowOffsetX}px ${theme.inputFieldsIdleShadowOffsetY}px ${theme.inputFieldsIdleShadowBlur}px ${theme.inputFieldsIdleShadowSpread}px ${theme.inputFieldsIdleShadowColor}`
            : 'none',
          ...inputContainerStyleTypes[theme.inputFieldsDropdownsType],
          boxSizing: 'border-box',
          padding: `${theme.inputFieldsIdleVerticalPadding || 0}px ${theme.inputFieldsIdleHorizontalPadding || 0}px`,
          '.country-list': {
            zIndex: 12
          }
        },
        '.phone-input': {
          width: '100%',
          lineHeight: '142%',
          color: theme.inputFieldsIdleTextColor,
          fontWeight: theme.inputFieldsIdleTextWeight,
          fontSize: theme.inputFieldsIdleTextFontSize,
          boxSizing: 'border-box'
        },
        '.phone-input::placeholder': {
          color: theme.inputFieldsIdlePlaceholderColor,
          fontWeight: theme.inputFieldsIdleTextWeight,
          fontSize: theme.inputFieldsIdlePlaceholderFontSize
        },
        '.phone-input:-webkit-autofill::first-line, .phone-input:-webkit-autofill, .phone-input:-webkit-autofill:hover, .phone-input:-webkit-autofill:focus, .phone-input:-webkit-autofill:active':
          {
            transition: 'background-color 5000s ease-in-out 0s',
            fontFamily: theme.typographyContentFontFamily,
            color: theme.inputFieldsIdleTextColor,
            fontWeight: theme.inputFieldsIdleTextWeight,
            fontSize: theme.inputFieldsIdleTextFontSize
          },
        '.selected-flag': {
          background: 'transparent !important',
          opacity: !value ? 0 : 1
        },
        '&:hover .phone-container': (() => {
          if (!theme.inputFieldsHoverEnable || active) return {};

          const obj = {
            background: theme.inputFieldsHoverBackground,
            borderColor: theme.inputFieldsHoverBorderColor,
            '& .phone-input': {
              color: theme.inputFieldsHoverTextColor
            },
            '& .phone-input::placeholder': {
              color: theme.inputFieldsHoverPlaceholderColor
            },
            '& .iti-arrow:not(.up)': {
              borderTop: `4px solid ${theme.inputFieldsHoverBorderColor}`
            },
            '& .iti-arrow.up': {
              borderBottom: `4px solid ${theme.inputFieldsHoverBorderColor}`
            }
          };

          if (theme.inputFieldsHoverShadow) {
            obj.boxShadow = `${theme.inputFieldsHoverShadowOffsetX}px ${theme.inputFieldsHoverShadowOffsetY}px ${theme.inputFieldsHoverShadowBlur}px ${theme.inputFieldsHoverShadowSpread}px ${theme.inputFieldsHoverShadowColor}`;
          } else {
            obj.boxShadow = 'none';
          }

          return obj;
        })(),
        '&.isActive .phone-container': (() => {
          if (!theme.inputFieldsActiveEnable) return {};

          const obj = {
            background: theme.inputFieldsActiveBackground,
            borderColor: theme.inputFieldsActiveBorderColor,
            '& .phone-input': {
              color: theme.inputFieldsActiveTextColor
            },
            '& .phone-input::placeholder': {
              color: theme.inputFieldsActivePlaceholderColor
            },
            '& .iti-arrow': {
              borderTop: `4px solid ${theme.inputFieldsActiveBorderColor}`
            },
            '& .iti-arrow.up': {
              borderBottom: `4px solid ${theme.inputFieldsActiveBorderColor}`
            }
          };

          if (theme.inputFieldsActiveShadow) {
            obj.boxShadow = `${theme.inputFieldsActiveShadowOffsetX}px ${theme.inputFieldsActiveShadowOffsetY}px ${theme.inputFieldsActiveShadowBlur}px ${theme.inputFieldsActiveShadowSpread}px ${theme.inputFieldsActiveShadowColor}`;
          } else {
            obj.boxShadow = 'none';
          }

          return obj;
        })()
      }),
    [
      active,
      inputContainerStyleTypes,
      theme.inputFieldsActiveBackground,
      theme.inputFieldsActiveBorderColor,
      theme.inputFieldsActiveEnable,
      theme.inputFieldsActivePlaceholderColor,
      theme.inputFieldsActiveShadow,
      theme.inputFieldsActiveShadowBlur,
      theme.inputFieldsActiveShadowColor,
      theme.inputFieldsActiveShadowOffsetX,
      theme.inputFieldsActiveShadowOffsetY,
      theme.inputFieldsActiveShadowSpread,
      theme.inputFieldsActiveTextColor,
      theme.inputFieldsDropdownsType,
      theme.inputFieldsHoverBackground,
      theme.inputFieldsHoverBorderColor,
      theme.inputFieldsHoverEnable,
      theme.inputFieldsHoverPlaceholderColor,
      theme.inputFieldsHoverShadow,
      theme.inputFieldsHoverShadowBlur,
      theme.inputFieldsHoverShadowColor,
      theme.inputFieldsHoverShadowOffsetX,
      theme.inputFieldsHoverShadowOffsetY,
      theme.inputFieldsHoverShadowSpread,
      theme.inputFieldsHoverTextColor,
      theme.inputFieldsIdleBackground,
      theme.inputFieldsIdleHorizontalPadding,
      theme.inputFieldsIdlePlaceholderColor,
      theme.inputFieldsIdlePlaceholderFontSize,
      theme.inputFieldsIdleRoundness,
      theme.inputFieldsIdleShadow,
      theme.inputFieldsIdleShadowBlur,
      theme.inputFieldsIdleShadowColor,
      theme.inputFieldsIdleShadowOffsetX,
      theme.inputFieldsIdleShadowOffsetY,
      theme.inputFieldsIdleShadowSpread,
      theme.inputFieldsIdleTextColor,
      theme.inputFieldsIdleTextFontSize,
      theme.inputFieldsIdleTextWeight,
      theme.inputFieldsIdleVerticalPadding,
      theme.typographyContentFontFamily,
      value
    ]
  );

  const handlePhoneChange = useCallback(
    (valid, value) => {
      onChange({ [field._id]: value }, valid);
      if (onAfterChange) onAfterChange({ [field._id]: value }, valid);
    },
    [field._id, onAfterChange, onChange]
  );

  useEffect(() => {
    if (typeof value === 'string' && field.format === 'email' && !isEmail(value)) {
      handlePhoneChange(false, '');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const labelJsx = useMemo(
    () => (
      <LabelAtom required={field.required} error={field.error} theme={theme}>
        {getFieldLabel(field.label, values, 'jsx', fieldsOptions, variables, urlParams)}
      </LabelAtom>
    ),
    [field.error, field.label, field.required, fieldsOptions, theme, urlParams, values, variables]
  );

  const descriptionAtomJsx = useMemo(
    () => (
      <DescriptionAtom theme={theme}>
        {getFieldLabel(field.description, values, 'jsx', fieldsOptions, variables, urlParams)}
      </DescriptionAtom>
    ),
    [field.description, fieldsOptions, theme, urlParams, values, variables]
  );

  const labelText = useMemo(
    () => getFieldLabel(field.label, values, 'text', fieldsOptions, variables, urlParams),
    [field.label, fieldsOptions, urlParams, values, variables]
  );
  const rand = useMemo(() => Math.random().toString(36).substring(7), []);

  const phoneInputJsx = useMemo(() => {
    return (
      <div css={phoneContainerStyle} className={active ? 'isActive' : ''}>
        <IntlTelInput
          autoFocus={autoFocus || false}
          containerClassName="intl-tel-input phone-container"
          inputClassName="form-control phone-input"
          onPhoneNumberChange={handlePhoneChange}
          onPhoneNumberBlur={() => {
            setActive(false);
            onPartialResponse(field._id);
            onBlur(field);
          }}
          id={field._id}
          customPlaceholder={field.placeholder ? () => field.placeholder : typeof country.code === 'string' ? null : () => ''}
          onPhoneNumberFocus={() => setActive(true)}
          autoHideDialCode={false}
          allowDropdown={false}
          formatOnInit={false}
          readOnly={field.readonly}
          // Nice to have TODO:
          // Use pre-filled value to get the country code. Once user clears the input, old flag is still shown,
          // but if he will go to the next page of the form, and then then go back to initial page,
          // a fallback flag will be shown. A mode that would enforce pre-filled beginning would be nice too.
          defaultCountry={preFilledValue ? undefined : typeof country.code === 'string' ? country.code.toLowerCase() : (owner.country || '').toLowerCase()}
          value={value || ''}
          fieldName={`${labelText}-${rand}`}
        />
      </div>
    );
  }, [
    active,
    autoFocus,
    country.code,
    field,
    handlePhoneChange,
    labelText,
    onBlur,
    onPartialResponse,
    owner.country,
    phoneContainerStyle,
    rand,
    value
  ]);

  const textInputJsx = useMemo(() => {
    return (
      <div css={inputContainerStyle} className={active ? 'isActive' : ''}>
        <input
          type={formats[field.format] || 'text'}
          autoComplete="chrome-off"
          name={`${labelText}-${rand}`}
          autoFocus={autoFocus || false}
          value={value || ''}
          onChange={(e) => {
            onChange({ [field._id]: e.target.value });
            if (onAfterChange) onAfterChange({ [field._id]: e.target.value });
          }}
          placeholder={field.placeholder || ''}
          readOnly={field.readonly}
          onBlur={() => {
            setActive(false);
            onPartialResponse(field._id);
            onBlur(field);
          }}
          onFocus={() => setActive(true)}
        />
      </div>
    );
  }, [active, autoFocus, field, inputContainerStyle, labelText, onBlur, onChange, onAfterChange, onPartialResponse, rand, value]);

  return (
    <>
      <div css={mainStyle}>
        <div css={contentStyle} onClick={() => onClick(field._id)}>
          {labelJsx}

          {field.format === 'phone' && phoneInputJsx}
          {field.format !== 'phone' && textInputJsx}

          {descriptionAtomJsx}
        </div>
      </div>
    </>
  );
};

export default ShortText;
