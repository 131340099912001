/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import React, { useState, useMemo, useRef, useCallback } from 'react';
import { LabelAtom, DescriptionAtom } from './atoms.js';
import ShortText from './ShortText.js';
import isJsonString from 'helpers/isJsonString.js';
import getFieldLabel from 'helpers/getFieldLabel.js';
import Translate from '../messages/Translate.js';

import SingleMultipleChoiceOptions from './SingleMultipleChoiceOptions';

const alignOptions = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

const Radio = ({
  form,
  field,
  theme,
  value,
  values,
  onChange,
  onAfterChange,
  onPartialResponse,
  onNextClick,
  fieldsOptions,
  variables,
  urlParams,
  onBlur,
  onClick
}) => {
  if (!onBlur) onBlur = () => {};
  if (!onClick) onClick = () => {};

  const translate = useMemo(() => new Translate(form.messages), [form.messages]);

  const [expandOther, setShowOther] = useState(false);

  let options = useMemo(() => field.options, [field.options]);

  const mainStyle = useMemo(
    () =>
      css({
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: alignOptions[theme.singleMultipleChoiceAlignment],
        margin: `${theme.singleMultipleChoiceTopMargin || 0}px 0 ${theme.singleMultipleChoiceBottomMargin || 0}px 0`,
        '.ps > .ps__rail-x, .ps > .ps__rail-y': {
          opacity: 0.6
        }
      }),
    [theme.singleMultipleChoiceAlignment, theme.singleMultipleChoiceBottomMargin, theme.singleMultipleChoiceTopMargin]
  );

  const contentStyle = useMemo(
    () =>
      css({
        width: '100%',
        maxWidth: `${theme.singleMultipleChoiceWidth}${theme.singleMultipleChoiceWidthType}`
      }),
    [theme.singleMultipleChoiceWidth, theme.singleMultipleChoiceWidthType]
  );

  const contentRef = useRef();

  const handleOptionClick = useCallback(
    (ref) => {
      if (field.readonly) return;

      let data = value == null ? { value: null, other: null } : JSON.parse(value);

      data.value = ref;
      data.other = null;

      setShowOther(false);

      onChange({ [field._id]: JSON.stringify(data) });
      if (onAfterChange) onAfterChange({ [field._id]: JSON.stringify(data) });
      onPartialResponse(field._id);
      if (field.section === 'root' || !field.section) onNextClick(field);
    },
    [field, onChange, onAfterChange, onNextClick, onPartialResponse, value]
  );

  const toggleExpandOther = useCallback(() => {
    if (expandOther) return;

    onChange({ [field._id]: JSON.stringify({ value: null, other: null }) });
    if (onAfterChange) onAfterChange({ [field._id]: JSON.stringify({ value: null, other: null }) });
    setShowOther(!expandOther);
  }, [field._id, onChange, onAfterChange, expandOther]);

  const handleOtherChange = useCallback(
    (otherValue) => {
      if (!expandOther) return;

      let data = value == null ? { values: null, other: null } : JSON.parse(value);

      data.value = null;
      data.other = otherValue;

      onChange({ [field._id]: JSON.stringify(data) });
      if (onAfterChange) onAfterChange({ [field._id]: JSON.stringify(data) });
    },
    [field._id, onChange, onAfterChange, expandOther, value]
  );

  return (
    <>
      <div css={mainStyle}>
        <div css={contentStyle} ref={contentRef} onClick={() => onClick(field._id)}>
          <LabelAtom required={field.required} error={field.error} theme={theme}>
            {getFieldLabel(field.label, values, 'jsx', fieldsOptions, variables, urlParams)}
          </LabelAtom>

          <SingleMultipleChoiceOptions
            options={options}
            value={value}
            onOptionClick={handleOptionClick}
            expandOther={expandOther}
            onToggleExpandOther={toggleExpandOther}
            field={field}
            form={form}
            theme={theme}
            translate={translate}
          />

          {expandOther && (
            <ShortText
              field={{
                _id: 'other'
              }}
              form={form}
              theme={{
                ...theme,
                ...{ inputFieldsDropdownsWidth: 100, inputFieldsDropdownsWidthType: '%' }
              }}
              onChange={(obj) => handleOtherChange(obj.other)}
              onBlur={() => {
                onPartialResponse(field._id);
                onBlur(field);
              }}
              onPartialResponse={() => {}}
              autoFocus={true}
              country={{ code: null }}
              owner={{ country: null }}
              values={{
                other: {
                  value: JSON.parse(isJsonString(value) ? value : '{"value":null,"other":null}').other
                }
              }}
            />
          )}

          <DescriptionAtom theme={theme}>
            {getFieldLabel(field.description, values, 'jsx', fieldsOptions, variables, urlParams)}
          </DescriptionAtom>
        </div>
      </div>
    </>
  );
};

export default Radio;
