import isEmail from 'validator/lib/isEmail';
import isDecimal from 'validator/lib/isDecimal';
import isFieldEmpty from './isFieldEmpty.js';
import isJsonString from './isJsonString.js';

const ifAnyParentSectionHidden = (object, values) => {
  if (!object.section || object.section === 'root') return false;

  if (!values[object.section].visible) {
    return true;
  } else {
    return ifAnyParentSectionHidden(values[object.section], values);
  }
};

export default function checkRequired(page, fields, values, options = {}, all = {}) {
  let allGood = true;
  let field;
  let setRequired = [];
  let removeRequired = [];
  let valueObject;
  let count;
  let childField = null;

  for (let [id, object] of Object.entries(values)) {
    try {
      field = fields.find((field) => field._id === id);

      valueObject = {};
      count = 0;
  
      if (!field || !object) continue;
      if (!options.ignorePages && page && field.page !== page) continue;
      if (ifAnyParentSectionHidden(object, values)) continue;
  
      if (field.type === 'checkbox' || field.type === 'imageChoice') {
        valueObject = (typeof object.value == 'string' && isJsonString(object.value)) ? JSON.parse(object.value || '{}') : object.value;
        count = valueObject ? (valueObject.values || []).length : 0;
  
        if (valueObject.other) count += 1;
      } else if (field.type === 'radio') {
        valueObject = (typeof object.value == 'string' && isJsonString(object.value)) ? JSON.parse(object.value || '{}') : object.value;
      } else if (field.type === 'matrix') {
        valueObject = (typeof object.value == 'string' && isJsonString(object.value)) ? JSON.parse(object.value || '[]') : [];
      }
  
      if (field.type === 'section' && object.visible && Array.isArray(field.childrens)) {
        for (let childFieldId of field.childrens) {
          childField = (all.fields || []).find((f) => f._id === childFieldId);
  
          if (!childField) continue;
  
          if (!checkRequired(page, [childField], all.values || {}, options, all).valid) {
            setRequired.push(childFieldId);
  
            allGood = false;
          }
        }
      } else if (field.required && object.visible && field.type === 'imageChoice' && !field.selectionLimits && count === 0) {
        setRequired.push(id);
  
        allGood = false;
      } else if (field.required && object.visible && field.type === 'checkbox' && !field.selectionLimits && count === 0 && !valueObject.other) {
        setRequired.push(id);
  
        allGood = false;
      } else if (field.required && object.visible && (field.type === 'checkbox' || field.type === 'imageChoice') && field.selectionLimits && count > field.selectionLimitsMax) {
        setRequired.push(id);
  
        allGood = false;
      } else if (field.required && object.visible && field.type === 'matrix' && valueObject.length !== field.options.length) {
        setRequired.push(id);
  
        allGood = false;
      } else if (field.required && object.visible && (field.type === 'checkbox' || field.type === 'imageChoice') && field.selectionLimits && count < field.selectionLimitsMin) {
        setRequired.push(id);
  
        allGood = false;
      } else if (field.required && object.visible && field.type === 'radio' && (!valueObject || (!valueObject.value && !valueObject.other))) {
        setRequired.push(id);
  
        allGood = false;
      } else if (field.required && object.visible && field.format === 'number' && !isDecimal(object.value || '')) {
        setRequired.push(id);
  
        allGood = false;
      } else if (field.required && object.visible && field.format === 'email' && !isEmail(object.value || '')) {
        setRequired.push(id);
  
        allGood = false;
      } else if (field.required && object.visible && field.format === 'phone' && (typeof object.valid === 'boolean' && object.valid === false)) {
        setRequired.push(id);
  
        allGood = false;
      } else if (field.required && object.visible && isFieldEmpty(object.value, object.type)) {
        setRequired.push(id);
  
        allGood = false;
      } else if (field.required && object.visible && field.type === 'datetime' && (isFieldEmpty(object.value, object.type) || (typeof object.valid === 'boolean' && object.valid === false))) {
        setRequired.push(id);
  
        allGood = false;
      } else {
        removeRequired.push(id);
      }
    } catch (e) { }
  }

  return {
    valid: allGood,
    toSet: setRequired,
    toRemove: removeRequired
  };
}