/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import isJsonString from 'helpers/isJsonString.js';
import getFieldLabel from 'helpers/getFieldLabel.js';
import React, { useMemo } from 'react';

const alignOptions = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

const Description = ({ form, field, values, theme, fieldsOptions, variables, urlParams, onClick }) => {
  if (!onClick) onClick = () => { };

  const mainStyle = useMemo(() => css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: alignOptions[theme.descriptionsAlignment],
    margin: `${theme.descriptionsTopMargin || 0}px 0 ${theme.descriptionsBottomMargin || 0}px 0`,
    lineHeight: '142%'
  }), [theme.descriptionsAlignment, theme.descriptionsBottomMargin, theme.descriptionsTopMargin]);

  const textStyle = useMemo(() => css({
    width: `${theme.descriptionsWidth}${theme.descriptionsWidthType}`,
    textAlign: theme.descriptionsFontAlignment,
    fontSize: theme.descriptionsFontSize,
    color: theme.descriptionsFontColor,
    fontFamily: theme.typographySupportingFontFamily,
    wordBreak: 'break-word',
    lineHeight: '142%',
    '& a': {
      color: theme.descriptionsLinkColor,
      textDecoration: 'underline'
    },
  }), [theme.descriptionsFontAlignment, theme.descriptionsFontColor, theme.descriptionsFontSize, theme.descriptionsLinkColor, theme.descriptionsWidth, theme.descriptionsWidthType, theme.typographySupportingFontFamily]);

  return <>
    <div css={mainStyle} onClick={() => onClick(field._id)}>
      {isJsonString(field.value)
        ? <div css={textStyle}>{getFieldLabel(field.value, values, 'jsx', fieldsOptions, variables, urlParams)}</div>
        : <div css={textStyle} dangerouslySetInnerHTML={{ __html: field.value }} />}
    </div>
  </>;
};

export default Description;
