/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx } from '@emotion/core';
import React, { useEffect, useMemo } from 'react';

import FormWrapper from 'form/components/FormWrapper.js';

// When state of fields hidden with logic changes, 
// string generated by this function will change
// (can be used to refresh UI dimensions).
const createFieldsVisibilityHashFromValues = (values = {})=>{
  let hash = '';

  for(const fieldId in values){
    const fieldState = values[fieldId];
    hash += fieldId + (fieldState.visible ? 'T' : 'F');
  }

  return hash;
}


const Section = (props) => {
  /* ---------------------------------- STATE --------------------------------- */

  const formCopy = useMemo(() => ({ ...props.form, type: 'classic'}), [props.form])

  /* ----------- FIX FOR OVERFLOWING SECTIONS IN CONVERSATIONAL FORM ---------- */
  
  /*
    When new field is unhidden some data can escape outside of fullscreen container.
    This code makes sure that container will be refreshed.
  */

  const fieldsVisibilityHash = createFieldsVisibilityHashFromValues(props?.values);

  // Refresh UI when fields logic within this section changes.
  useEffect(()=>{
    const reBuild = window?.fullpage_api?.reBuild();
    if(reBuild){
      reBuild();
    }
  }, [fieldsVisibilityHash])

  /* ----------------------------------- JSX ---------------------------------- */

  return <FormWrapper {...props} form={formCopy} />;
};

export default Section;
