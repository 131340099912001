export default function isFieldEmpty(value, type) {
  const types = {
    shortText: () => {
      if (typeof value === 'string') value = value.replace(/\s/g, '');

      return value === null || value === '' || typeof value === 'undefined';
    },
    longText: () => {
      if (typeof value === 'string') value = value.replace(/\s/g, '');

      return value === null || value === '' || typeof value === 'undefined';
    },
    dropdown: () => {
      return !value;
    },
    datetime: () => {
      if (value === null) return true;

      const entries = Object.entries(JSON.parse(value));
      let foundEmpty = false;

      entries.map((entry) => {
        if (entry[1] === '') {
          foundEmpty = true;
        }
      });

      return foundEmpty;
    },
    radio: () => {
      return !value || value === '{"value":null,"other":null}' || value === '{"value":null,"other":""}';
    },
    checkbox: () => {
      return !value || value === '{"values":[],"other":null}' || value === '{"values":[],"other":""}';
    },
    imageChoice: () => {
      return !value || value === '{"values":[]}' || value === '{"values":[]}';
    },
    fileUpload: () => {
      return !value || value === '[]';
    },
    signature: () => {
      return !value;
    },
    scale: () => {
      return !/^\d+$/.test(value);
    },
  };

  if (typeof types[type] === 'undefined') return false;

  return types[type]();
};
