/** @jsx jsx */

import { jsx, css } from '@emotion/core';
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import ReactFullpage from '@fullpage/react-fullpage';
import checkRequired from 'helpers/checkRequired.js';
import isRequired from 'helpers/isRequired.js';
import scrollIntoView from 'scroll-into-view';
import getRootSectionValueObj from 'helpers/getRootSectionValueObj.js';
import config from '../config.js';

import Submit from './fields/Submit.js';

import Background from './components/Background.js';
import Backup from './components/Backup.js';
import Paused from './components/Paused.js';
import Footer from './components/Footer.js';
import Submitted from './components/Submitted.js';
import RequiredHelper from './components/RequiredHelper.js';
import FormWrapper from './components/FormWrapper.js';
import WelcomePage from './components/WelcomePage.js';
import ProgressBar from './components/ProgressBar.js';
import SubmitError from './components/SubmitError.js';

const keyCodeToAlphabetIndex = [65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90];

const alignOptions = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

let nextClickDisabled = false;

const getScrollbarWidth = () => {
  const div = document.createElement('div');

  document.body.appendChild(div);

  div.style.width = '100px';
  div.style.height = '100px';
  div.style.overflow = 'scroll';
  div.style.opacity = '0';
  div.style.position = 'absolute';
  div.style.top = '-9999px';

  setTimeout(() => document.body.removeChild(div), 1000);

  return div.offsetWidth - div.clientWidth;
};

const isTouchDevice = typeof window !== 'undefined' && ((('ontouchstart' in (window)) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)));

const getFormPadding = (type, theme, mode, device, withBottomBar) => {
  const bottomExtraMargin = withBottomBar ? 50 : 0;

  const options = {
    desktop: {
      top: `${type === 'conversational' ? 0 : (theme.displaySettingsFormDisplayDesktopVerticalMargin || 0)}px`,
      right: `${theme.displaySettingsFormDisplayDesktopHorizontalMargin || 0}px`,
      bottom: `${type === 'conversational' ? 50 : (theme.displaySettingsFormDisplayBoxMinimumHeight !== 'window' ? (theme.displaySettingsFormDisplayDesktopVerticalMargin || 0) + bottomExtraMargin : (theme.displaySettingsFormDisplayDesktopVerticalMargin || 0))}px`,
      left: `${theme.displaySettingsFormDisplayDesktopHorizontalMargin || 0}px`
    },
    tablet: {
      top: `${type === 'conversational' ? 0 : (theme.displaySettingsFormDisplayTabletVerticalMargin || 0)}px`,
      right: `${theme.displaySettingsFormDisplayTabletHorizontalMargin || 0}px`,
      bottom: `${type === 'conversational' ? 50 : (theme.displaySettingsFormDisplayBoxMinimumHeight !== 'window' ? (theme.displaySettingsFormDisplayTabletVerticalMargin || 0) + bottomExtraMargin : (theme.displaySettingsFormDisplayTabletVerticalMargin || 0))}px`,
      left: `${theme.displaySettingsFormDisplayTabletHorizontalMargin || 0}px`
    },
    phone: {
      top: `${type === 'conversational' ? 0 : (theme.displaySettingsFormDisplayMobileVerticalMargin || 0)}px `,
      right: `${theme.displaySettingsFormDisplayMobileHorizontalMargin || 0}px`,
      bottom: `${type === 'conversational' ? 50 : (theme.displaySettingsFormDisplayBoxMinimumHeight !== 'window' ? (theme.displaySettingsFormDisplayMobileVerticalMargin || 0) + bottomExtraMargin : (theme.displaySettingsFormDisplayMobileVerticalMargin || 0))}px`,
      left: `${theme.displaySettingsFormDisplayMobileHorizontalMargin || 0}px`
    }
  };

  if (mode === 'preview') return options[device];

  if (window.screen.width <= 700) {
    return options.phone;
  } else if (isTouchDevice) {
    return options.tablet;
  } else if (!isTouchDevice) {
    return options.desktop;
  }
};

const getScaleArray = (range) => {
  const arr = [];

  for (let i = range[0]; i <= range[1]; i++) {
    arr.push(i);
  }

  return arr;
};

let fullpage;

const QuestionscoutFormEngine = ({
  form, theme, values, inIframe, seed, files, device, mode, status, submitted, onChange, placement,
  waitingForSubmissionId,
  handleUpload, handleDelete, handleCancel,
  submitIsDisabled, handleHighlightField,
  handleNextPage, handlePreviousPage, handleSubmit, handleSubmitAgain,
  updateFieldHighlighted,
  userUiSettings,
  handlePartialResponses,
  backupExist, handleRestoreBackup, handleClearBackup,
  thankYouPages, defaultThankYouPage,
  welcomePage, showWelcomePage, handleWelcomePageClick,
  showProgressBar, country, variables,
  paymentsProvider, urlParams,
  submitError, clearSubmitError,
  builderControl,
  handleFieldClick
}) => {
  const [scrollbarWidth, setScrollbarWidth] = useState(null);
  const [fullpageReady, setFullpageReady] = useState(false);

  const [allowGoUp, setAllowGoUp] = useState(false);
  const [allowGoDown, setAllowGoDown] = useState(false);

  const [activeFieldIndex, setActiveFieldIndex] = useState(0);

  const scrollToField = useSelector(state => state.form ? state.form.scrollToField : null);

  if (scrollbarWidth === null) {
    setScrollbarWidth(getScrollbarWidth());
  }

  const previewWidth = useMemo(() => ({
    desktop: `${theme.displaySettingsFormDisplayDesktopMaxWidth}${theme.displaySettingsFormDisplayDesktopMaxWidthType}`,
    tablet: `${theme.displaySettingsFormDisplayTabletMaxWidth}${theme.displaySettingsFormDisplayTabletMaxWidthType}`,
    phone: `${theme.displaySettingsFormDisplayMobileMaxWidth}${theme.displaySettingsFormDisplayMobileMaxWidthType}`
  }), [theme.displaySettingsFormDisplayDesktopMaxWidth, theme.displaySettingsFormDisplayDesktopMaxWidthType, theme.displaySettingsFormDisplayMobileMaxWidth, theme.displaySettingsFormDisplayMobileMaxWidthType, theme.displaySettingsFormDisplayTabletMaxWidth, theme.displaySettingsFormDisplayTabletMaxWidthType]);

  const containerStyle = useMemo(() => css({
    position: 'relative'
  }), []);

  const mainStyle = useMemo(() => css({
    width: '100%',
    minHeight: theme.displaySettingsFormDisplayBoxMinimumHeight === 'window' ? '100vh' : 'auto',
    padding: (() => {
      const withBottomBar = (!form.owner.pro) || (theme.backgroundImage && theme.backgroundUseImage && theme.backgroundImageSource === 'unsplash') || (!(form.owner ? form.owner.hideBranding : false)) || form.type === 'conversational';
      const { top, right, bottom, left } = getFormPadding(form.type, theme, mode, device, withBottomBar);

      return `${top} ${right} ${bottom} ${left}`;
    })(),
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'row',
    justifyContent: alignOptions[theme.displaySettingsFormDisplayAlignment],
    zIndex: 2,
    position: 'relative',
    '& *': {
      WebkitTapHighlightColor: 'transparent'
    }
  }), [device, form.owner, form.type, mode, theme]);

  const contentStyle = useMemo(() => mode === 'preview' ? css({
      boxSizing: 'border-box',
      width: previewWidth[device],
      height: theme.displaySettingsFormDisplayBoxMinimumHeight === 'window' ? '100%' : 'auto',
      minHeight: theme.displaySettingsFormDisplayBoxMinimumHeight === 'window' ? '100vh' : 'auto',
    }) : css({
      boxSizing: 'border-box',
      height: theme.displaySettingsFormDisplayBoxMinimumHeight === 'window' ? '100%' : 'auto',
      width: `${theme.displaySettingsFormDisplayDesktopMaxWidth}${theme.displaySettingsFormDisplayDesktopMaxWidthType}`,
      '@media (max-width: 800px)': {
        width: `${theme.displaySettingsFormDisplayTabletMaxWidth}${theme.displaySettingsFormDisplayTabletMaxWidthType}`
      },
      '@media (max-width: 600px)': {
        width: `${theme.displaySettingsFormDisplayMobileMaxWidth}${theme.displaySettingsFormDisplayMobileMaxWidthType}`
      }
  }), [device, mode, previewWidth, theme.displaySettingsFormDisplayBoxMinimumHeight, theme.displaySettingsFormDisplayDesktopMaxWidth, theme.displaySettingsFormDisplayDesktopMaxWidthType, theme.displaySettingsFormDisplayMobileMaxWidth, theme.displaySettingsFormDisplayMobileMaxWidthType, theme.displaySettingsFormDisplayTabletMaxWidth, theme.displaySettingsFormDisplayTabletMaxWidthType]);

  const formStyle = useMemo(() => css({
    height: theme.displaySettingsFormDisplayBoxMinimumHeight === 'window' ? '100%' : 'auto',
    minHeight: theme.displaySettingsFormDisplayBoxMinimumHeight === 'window' ? '100vh' : 'auto',
    display: showWelcomePage ? 'none' : 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    boxSizing: 'border-box',
    position: 'relative',
    zIndex: 2,
    padding: (() => {
      if (form.type === 'conversational') {
        return 0;
      } else {
        return theme.displaySettingsFormDisplayBox ? `${theme.displaySettingsFormDisplayBoxVerticalPadding || 0}px ${theme.displaySettingsFormDisplayBoxHorizontalPadding || 0}px` : 0;
      }
    })(),
    paddingBottom: form.type === 'conversational' ? 0 : (theme.displaySettingsFormDisplayBoxMinimumHeight === 'window' ? (theme.displaySettingsFormDisplayBoxVerticalPadding || 0) + 50 : (theme.displaySettingsFormDisplayBoxVerticalPadding || 0)),
    borderRadius: form.type === 'conversational' ? 0 : (theme.displaySettingsFormDisplayBox ? `${theme.displaySettingsFormDisplayBoxRoundness || 0}${theme.displaySettingsFormDisplayBoxRoundnessType}` : 0),
    background: form.type === 'conversational' ? 'none' : (theme.displaySettingsFormDisplayBox ? theme.displaySettingsFormDisplayBoxBackground : 'transparent'),
    boxShadow: form.type === 'conversational' ? 'none' : (theme.displaySettingsFormDisplayBox && theme.displaySettingsFormDisplayBoxShadow ? `${theme.displaySettingsFormDisplayBoxShadowOffsetX}px ${theme.displaySettingsFormDisplayBoxShadowOffsetY}px ${theme.displaySettingsFormDisplayBoxShadowBlur}px ${theme.displaySettingsFormDisplayBoxShadowSpread}px ${theme.displaySettingsFormDisplayBoxShadowColor}` : 'none'),
  }), [form.type, showWelcomePage, theme.displaySettingsFormDisplayBox, theme.displaySettingsFormDisplayBoxBackground, theme.displaySettingsFormDisplayBoxHorizontalPadding, theme.displaySettingsFormDisplayBoxMinimumHeight, theme.displaySettingsFormDisplayBoxRoundness, theme.displaySettingsFormDisplayBoxRoundnessType, theme.displaySettingsFormDisplayBoxShadow, theme.displaySettingsFormDisplayBoxShadowBlur, theme.displaySettingsFormDisplayBoxShadowColor, theme.displaySettingsFormDisplayBoxShadowOffsetX, theme.displaySettingsFormDisplayBoxShadowOffsetY, theme.displaySettingsFormDisplayBoxShadowSpread, theme.displaySettingsFormDisplayBoxVerticalPadding]);

  const scrollIfNeeded = useCallback((activeField) => {
    if (form.type === 'classic') return;

    const activeValueObj = getRootSectionValueObj(activeField._id, values);
    if(!activeValueObj) return;

    let activeSection = null;

    if (activeValueObj.type === 'section') activeSection = form.fields.find((f) => f._id === activeValueObj._id);

    const move = () => setTimeout(() => {
      if (window.fullpage_api) window.fullpage_api.moveSectionDown();
    }, 300);

    if (!activeField) return;

    if (isRequired(activeField._id, form.fields) && !checkRequired(form.page, [(activeSection || activeField)], { [(activeSection || activeField)._id]: values[(activeSection || activeField)._id] }, {
      ignorePages: form.type === 'conversational'
    }, {
      fields: form.fields,
      values: values
    }).valid) return;

    if (['title', 'image', 'title', 'checkbox', 'imageChoice', 'description', 'fileUpload', 'signature', 'datetime', 'longText', 'shortText', 'radio', 'dropdown', 'scale', 'section', 'matrix'].indexOf(activeField.type) !== -1) {
      move();
    }
  }, [form.fields, form.page, form.type, values]);

  const handleKeydown = useCallback((e) => {
    if (!form.type === 'classic' || !fullpage || submitted) return;

    let data, ref, index;
    const activeSection = window.fullpage_api.getActiveSection();
    const activeField = form.fields.find((f) => f._id === activeSection.item.id);

    if (!activeField) return;

    if (e.keyCode === 13 && getRootSectionValueObj(activeField._id, values)?.isLast && !submitIsDisabled()) {
      handleSubmit();

      return;
    }

    if (['title', 'image', 'title', 'datetime', 'description', 'shortText', 'fileUpload', 'signature', 'dropdown', 'checkbox', 'radio', 'imageChoice', 'scale', 'section'].indexOf(activeField.type) !== -1 && e.keyCode === 13) {
      e.preventDefault();
      e.target && e.target.blur();
      scrollIfNeeded(activeField);

      return;
    }

    if (activeField.type === 'longText' && e.keyCode === 13 && !e.shiftKey && !isTouchDevice) {
      e.preventDefault();
      e.target && e.target.blur();
      scrollIfNeeded(activeField);

      return true;
    }

    if (activeField.type === 'checkbox' && keyCodeToAlphabetIndex.indexOf(e.keyCode) !== -1 && document.activeElement.tagName !== 'INPUT') {
      data = (!values[activeField._id] || values[activeField._id].value == null) ? { values: [], other: '' } : JSON.parse(values[activeField._id].value);
      index = keyCodeToAlphabetIndex.indexOf(e.keyCode);

      if (index >= activeField.options.length) return;

      ref = activeField.options[index].ref;

      if (data.values.indexOf(ref) !== -1) {
        data.values.splice(data.values.indexOf(ref), 1);
      } else {
        data.values.push(ref);
      }

      if (activeField.selectionLimits) {
        if (data.values.length === activeField.selectionLimitsMax) scrollIfNeeded(activeField);
        if (data.values.length > activeField.selectionLimitsMax) return;
      }

      onChange({ [activeField._id]: JSON.stringify(data) });
    }

    if (activeField.type === 'imageChoice' && keyCodeToAlphabetIndex.indexOf(e.keyCode) !== -1 && document.activeElement.tagName !== 'INPUT') {
      data = (!values[activeField._id] || values[activeField._id].value == null) ? { values: [] } : JSON.parse(values[activeField._id].value);
      index = keyCodeToAlphabetIndex.indexOf(e.keyCode);

      if (index >= activeField.options.length) return;

      ref = activeField.options[index].ref;

      if (data.values.indexOf(ref) !== -1) {
        data.values.splice(data.values.indexOf(ref), 1);
      } else {
        data.values.push(ref);
      }

      if (activeField.selectionLimits) {
        if (data.values.length === activeField.selectionLimitsMax) scrollIfNeeded(activeField);
        if (data.values.length > activeField.selectionLimitsMax) return;
      }

      onChange({ [activeField._id]: JSON.stringify(data) });
    }

    if (activeField.type === 'radio' && keyCodeToAlphabetIndex.indexOf(e.keyCode) !== -1 && document.activeElement.tagName !== 'INPUT') {
      data = (!values[activeField._id] || values[activeField._id].value == null) ? { value: null, other: null } : JSON.parse(values[activeField._id].value);
      index = keyCodeToAlphabetIndex.indexOf(e.keyCode);

      if (index >= activeField.options.length) return;

      data.value = activeField.options[index].ref;
      data.other = null;

      onChange({ [activeField._id]: JSON.stringify(data) });
      scrollIfNeeded(activeField);
    }

    if (activeField.type === 'scale') {
      if (e.keyCode === 39 && (activeField.highlighted || 0) < (activeField.scaleRange[1] - activeField.scaleRange[0])) {
        e.preventDefault();
        updateFieldHighlighted(activeField._id, typeof activeField.highlighted === 'undefined' ? 0 : activeField.highlighted + 1);
      }

      if (e.keyCode === 37 && (activeField.highlighted > 0 || typeof activeField.highlighted === 'undefined')) {
        e.preventDefault();
        updateFieldHighlighted(activeField._id, typeof activeField.highlighted === 'undefined' ? (activeField.scaleRange[1] - activeField.scaleRange[0]) : activeField.highlighted - 1);
      }

      onChange({ [activeField._id]: getScaleArray(activeField.scaleRange)[activeField.highlighted] });
    }
  }, [form.fields, form.type, handleSubmit, onChange, scrollIfNeeded, submitIsDisabled, submitted, updateFieldHighlighted, values]);

  const handleChange = useCallback((obj, isValid) => {
    onChange(obj, isValid);
  }, [onChange]);

  const handleAfterChange = useCallback((obj, isValid) => {
    if (form.type !== 'conversational') return;

    const activeField = form.fields.find((f) => f._id === Object.keys(obj)[0]);
    const activeValueObj = getRootSectionValueObj(activeField._id, values);
    let activeSection = null;

    if (activeValueObj.type === 'section') activeSection = form.fields.find((f) => f._id === activeValueObj._id);

    if (activeField && window.fullpage_api && ['dropdown', 'radio', 'checkbox', 'imageChoice'].indexOf(activeField.type) !== -1) {
      setTimeout(() => window.fullpage_api.reBuild());
    }

    const valid = isRequired(activeField._id, form.fields) && !checkRequired(form.page, [(activeSection || activeField)], { [(activeSection || activeField)._id]: values[(activeSection || activeField)._id] }, {
      ignorePages: form.type === 'conversational'
    }, {
      fields: form.fields,
      values: values
    }).valid ? false : true;

    if (getRootSectionValueObj(activeField._id, values).isLast) {
      setAllowGoDown(false);
    } else if (valid) {
      setAllowGoDown(valid);
    }
  }, [form.fields, form.page, form.type, values]);

  const handleOnNextClick = useCallback((field, e) => {
    if (nextClickDisabled) return;

    nextClickDisabled = true;
    setTimeout(() => nextClickDisabled = false, 500);

    scrollIfNeeded(field);
  }, [scrollIfNeeded]);

  const handleOnPrevClick = useCallback(() => {
    if (window.fullpage_api) window.fullpage_api.moveSectionUp();
  }, []);

  const handleGoDown = useCallback(() => {
    const activeSection = window.fullpage_api.getActiveSection();
    const activeField = form.fields.find((f) => f._id === activeSection.item.id);

    scrollIfNeeded(activeField);
  }, [form.fields, scrollIfNeeded]);

  const handleGoUp = useCallback(() => {
    setTimeout(() => {
      if (window.fullpage_api) window.fullpage_api.moveSectionUp();
    }, 300);
  }, []);

  const getNearestVisibleSectionId = useCallback((destination, direction) => {
    const step = direction === 'up' ? -1 : 1;
    const currentField = form.fields.find((field) => field._id === destination.anchor);

    let nextField;

    let firstFieldId = null;
    let lastFieldId = null;

    for (const [key] of Object.entries(values)) {
      if (values[key].isLast) lastFieldId = key;
      if (values[key].isFirst) firstFieldId = key;
    }

    if (!currentField && direction === 'down') return lastFieldId;
    if (!currentField && direction === 'up') return firstFieldId;

    nextField = form.fields.find((field) => field.section === 'root' && field.position === currentField.position + step);
    if (!nextField) nextField = form.fields.find((field) => field.section === 'root' && field.position === currentField.position + step + step);
    if (!nextField) nextField = form.fields.find((field) => field.section === 'root' && field.position === currentField.position + step + step + step);
    if (!nextField) nextField = form.fields.find((field) => field.section === 'root' && field.position === currentField.position + step + step + step + step);

    if (!nextField && direction === 'down') {
      return lastFieldId;
    } else if (nextField) {
      return nextField._id;
    }
  }, [form.fields, values]);

  const isVisible = useCallback((destination) => {
    return destination.getAttribute('data-hidden') === 'false';
  }, []);

  const handleInputBlur = useCallback((field) => {
    if (!isTouchDevice || !field || form.type !== 'conversational' || field.section === 'root') return;

    window.scrollTo(0, 0);
    window.document.body.scrollTop = 0;
  }, [form.type]);

  useEffect(() => {
    if (form.type === 'conversational') {
      let interval;
      let loops = 0;
      let goDownAllowed;

      const firstField = form.fields.filter((f) => f.section === 'root' || !f.section)[0];
      const activeValueObj = firstField ? getRootSectionValueObj(firstField._id, values) : null;
      let activeSection = null;

      if (activeValueObj && activeValueObj.type === 'section') activeSection = form.fields.find((f) => f._id === activeValueObj._id);

      interval = setInterval(() => {
        loops += 1;

        if (loops >= 500) clearInterval(interval);
        if (!fullpage) return;

        if (firstField) {
          goDownAllowed = isRequired(firstField._id, form.fields) && !checkRequired(form.page, [(activeSection || firstField)], { [(activeSection || firstField)._id]: values[(activeSection || firstField)._id] }, {
            ignorePages: form.type === 'conversational'
          }, {
            fields: form.fields,
            values: values
          }).valid ? false : true;

          setAllowGoUp(false);
          if (form.fields.length > 1 && goDownAllowed) setAllowGoDown(true);
          window.fullpage_api.setKeyboardScrolling(goDownAllowed, 'down');
        }

        clearInterval(interval);
      }, loops === 0 ? 0 : 5);
    }
  }, []);

  useEffect(() => {
    if (scrollToField === null) return;

    try {
      const { _id } = getRootSectionValueObj(scrollToField, values);

      if (window.fullpage_api) window.fullpage_api.moveTo(_id);
    } catch (e) { }
  }, [scrollToField, values]);

  const fieldsOptions = useMemo(() => {
    let options = {};

    for (let f of form.fields){
       options[f._id] = f.options || [];
    }

    return options;
  }, [form.fields]);


  const scrollPreviewToFieldTimeoutRef = useRef(null);
  const handleHighlightFieldRef = useRef(null);
  handleHighlightFieldRef.current = handleHighlightField;
  
  // Will scroll to an existing DOM node (by #) but takes into consideration
  // that it may not be created immediately. Will keep trying every 100ms
  // until it will find it, or until will be called with fieldId === null.
  const persistentScrollPreviewToField = useCallback(
    (fieldId) => {
      const REFRESH_TIMEOUT_MS = 100;

      const clear = ()=>{
        if (scrollPreviewToFieldTimeoutRef.current) {
          clearTimeout(scrollPreviewToFieldTimeoutRef.current);
          scrollPreviewToFieldTimeoutRef.current = null;
        }
      }

      const scrollToPreviewOrTryAgainLater = () => {
        clear();
  
        if (!fieldId) return;
  
        const node = document.getElementById(fieldId);
  
        if (!node) {
          scrollPreviewToFieldTimeoutRef.current = setTimeout(scrollToPreviewOrTryAgainLater, REFRESH_TIMEOUT_MS);
        } else if (form.type === "classic") {
          scrollIntoView(node, {
            align: {
              top: isTouchDevice ? 1 : 0.5,
            },
            time: 100,
          });
  
          if (handleHighlightFieldRef.current) handleHighlightFieldRef.current(fieldId, 200);
          
          clear();
        } else if (form.type === "conversational" && window.fullpage_api) {
          window.fullpage_api.moveTo(fieldId);
          clear();
        }
      };
  
      scrollToPreviewOrTryAgainLater();
    },
    [form.type]
  );
  
  useEffect(() => {
    persistentScrollPreviewToField(builderControl.scrollPreviewToField)
  }, [builderControl.scrollPreviewToField, persistentScrollPreviewToField]);

  const rootFields = useMemo(() => form.fields.filter((field) => field.section === 'root' || !field.section), [form.fields]);

  const containerRef = useRef(null);
  
  useEffect(()=>{
    const handleKeyUp = (e)=>{
      const container = containerRef.current;
      const focusedElement = document.activeElement;

      // FormPreview will handle keydown only if nothing is focused
      // or if focused element is inside FormPreview container.
      const somethingIsFocused = focusedElement && focusedElement !== document.body;
      if(somethingIsFocused && !container.contains(focusedElement)){
        return;
      }

      handleKeydown(e);
    }

    document.addEventListener('keyup', handleKeyUp, false);
    return () => document.removeEventListener('keyup', handleKeyUp, false);
  }, [handleKeydown])

  /*
  // Better solution for scrolling issues that doesn't work yet

  const containerRef = useRef(null);
  const fullpageApiRef = useRef(null);

  useEffect(()=>{
    const container = containerRef.current;

    if(container){
      const handleMouseEnter = (e)=>{
        const fullpageApi = fullpageApiRef.current;
        if(fullpageApi){
          fullpageApi.setAutoScrolling(true);
        }
      }
      const handleMouseLeave = (e)=>{
        const fullpageApi = fullpageApiRef.current;
         if(fullpageApi){
          fullpageApi.setAutoScrolling(false);
        }
      }

      container.addEventListener('mouseenter', handleMouseEnter);
      container.addEventListener('mouseleave', handleMouseLeave);
      return ()=>{
        container.removeEventListener('mouseenter', handleMouseEnter);
        container.removeEventListener('mouseleave', handleMouseLeave);
      }
    }
  }, []);
  */

  return (
    <div css={containerStyle} ref={containerRef}>
      <Background formId={form._id} theme={theme} mode={mode} device={device} placement={placement} isTouchDevice={isTouchDevice} scrollbarWidth={form.type === 'conversational' ? 0 : scrollbarWidth} userUiSettings={userUiSettings || {}} />

      {showWelcomePage && status !== 'paused' && !submitted && !backupExist && <WelcomePage {...welcomePage}
        onClick={handleWelcomePageClick}
        form={form}
        theme={theme}
        formType={form.type}
        urlParams={urlParams}
        inIframe={inIframe}
        values={values}
        fieldsOptions={fieldsOptions}
        variables={variables}
        mainStyles={{
          main: mainStyle,
          content: contentStyle
        }} />}

      {form.type === 'conversational' && !submitted && !backupExist && status !== 'paused' && !showWelcomePage && <div css={formStyle}>
        {!fullpageReady && <div className="spinnerServer" />}

        <ReactFullpage
          licenseKey={config.fullpageLicense}
          scrollingSpeed={1000}
          controlArrows={false}
          bigSectionsDestination={'top'}
          touchSensitivity={isTouchDevice ? 30 : 15}
          fitToSection={true}
          dragAndMove="fingersonly"
          lockAnchors={true}
          scrollOverflow={true}
          scrollOverflowOptions={{
            bounce: false,
            click: false,
            tap: false,
            preventDefault: false,
   
            // Would fix unnecessary drag scroll on PC
            // but would prevent drag scroll on mobile.
            // disablePointer: true,
          }}
          paddingTop={0}
          paddingBottom={0}
          afterRender={() => {
            setTimeout(() => {
              if (window.fullpage_api && mode === 'builder') window.fullpage_api.setMouseWheelScrolling(false, 'down, up');
            });
          }}
          onLeave={(origin, destination, direction) => {
            if ('activeElement' in document) document.activeElement.blur();

            const nearestVisibleSectionId = getNearestVisibleSectionId(destination, direction);
            const originContent = origin.item.querySelector('.sectionContent');
            const originInput = origin.item.querySelector('input, textarea');

            if (nearestVisibleSectionId === origin.anchor) return false; // stay here if no need to move

            setActiveFieldIndex(destination.index);

            if (originContent) originContent.style.opacity = 0;
            if (originInput) originInput.blur();

            ((item) => {
              setTimeout(() => {
                if (item) item.style.opacity = 1;
              }, 1200);
            })(originContent);

            if (!isVisible(destination.item) && nearestVisibleSectionId) {
              if (window.fullpage_api) window.fullpage_api.moveTo(nearestVisibleSectionId);

              return false;
            }
          }}
          afterLoad={(origin, destination, direction) => {
            const destinationInput = destination.item.querySelector('input, textarea');
            const originInput = origin.item.querySelector('input, textarea');
            const activeField = form.fields.find((f) => f._id === destination.anchor);

            if (!activeField) return;

            const activeValueObj = getRootSectionValueObj(activeField._id, values);
            let activeSection = null;

            if (activeValueObj.type === 'section') activeSection = form.fields.find((f) => f._id === activeValueObj._id);

            let fullpageInterval;
            let fullpageLoops = 0;

            if (activeField && window.fullpage_api && ['radio', 'checkbox', 'imageChoice'].indexOf(activeField.type) !== -1) {
              setTimeout(() => window.fullpage_api.reBuild());
            }

            if (originInput) originInput.blur();
            if (destinationInput && !isTouchDevice && activeField.type !== 'section') destinationInput.focus();

            fullpageInterval = setInterval(() => {
              fullpageLoops += 1;

              if (fullpageLoops >= 200) clearInterval(fullpageInterval);

              clearInterval(fullpageInterval);

              if (!activeField) {
                setAllowGoDown(false);
                return;
              };

              const allowScroll = getRootSectionValueObj(destination.anchor, values).isLast ? false : (isRequired(activeField._id, form.fields) && !checkRequired(form.page, [(activeSection || activeField)], { [(activeSection || activeField)._id]: values[(activeSection || activeField)._id] }, {
                ignorePages: form.type === 'conversational'
              }, {
                fields: form.fields,
                values: values
              }).valid ? false : true);

              setAllowGoDown(allowScroll);
              setAllowGoUp(!getRootSectionValueObj(destination.anchor, values).isFirst);

              if (window.fullpage_api) window.fullpage_api.setKeyboardScrolling(allowScroll, allowScroll ? 'down, up' : 'down');
            }, fullpageLoops === 0 ? 0 : 10);
          }}
          render={({ fullpageApi }) => {            
            fullpage = fullpageApi;

            if (fullpageApi && !fullpageReady) {
              if (mode === 'life') fullpageApi.setAllowScrolling(false, 'down, up');
              setTimeout(() => setFullpageReady(true));
            }

            return (
              <ReactFullpage.Wrapper>
                <FormWrapper
                  form={form}
                  handleInputBlur={handleInputBlur}
                  fields={rootFields}
                  fullpageReady={fullpageReady}
                  values={values}
                  theme={theme}
                  device={device}
                  isTouchDevice={isTouchDevice}
                  submitError={submitError}
                  urlParams={urlParams}
                  mode={mode}
                  seed={seed}
                  variables={variables}
                  country={country}
                  fieldsOptions={fieldsOptions}
                  submitIsDisabled={submitIsDisabled}
                  handleSubmit={handleSubmit}
                  handleChange={handleChange}
                  handleAfterChange={handleAfterChange}
                  waitingForSubmissionId={waitingForSubmissionId}
                  handlePartialResponses={handlePartialResponses}
                  handleOnNextClick={handleOnNextClick}
                  handleOnPrevClick={handleOnPrevClick}
                  handleNextPage={handleNextPage}
                  handlePreviousPage={handlePreviousPage}
                  handleUpload={handleUpload}
                  handleDelete={handleDelete}
                  handleCancel={handleCancel}
                  paymentsProvider={paymentsProvider}
                  handleFieldClick={handleFieldClick}
                  files={files}
                  placement={placement}
                />

                <div data-anchor="submit" className="section" data-hidden="true" />
              </ReactFullpage.Wrapper>
            );
          }}
        />
      </div>}

      {!submitted && status !== 'paused' && !showWelcomePage && !backupExist && form.type === 'classic' && <div css={mainStyle}>
        <div css={contentStyle}>
          <div css={formStyle}>
            <FormWrapper
              form={form}
              fields={rootFields}
              values={values}
              theme={theme}
              device={device}
              isTouchDevice={isTouchDevice}
              submitError={submitError}
              urlParams={urlParams}
              mode={mode}
              seed={seed}
              variables={variables}
              country={country}
              fieldsOptions={fieldsOptions}
              submitIsDisabled={submitIsDisabled}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              waitingForSubmissionId={waitingForSubmissionId}
              handlePartialResponses={handlePartialResponses}
              handleOnNextClick={handleOnNextClick}
              handleOnPrevClick={handleOnPrevClick}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              handleUpload={handleUpload}
              handleDelete={handleDelete}
              handleCancel={handleCancel}
              paymentsProvider={paymentsProvider}
              handleFieldClick={handleFieldClick}
              files={files}
            />

            {form.page === form.pages && <div id="submit" style={{ zIndex: 2, position: 'relative' }}>
              <Submit submitError={submitError} onSubmit={handleSubmit} onPreviousPage={handlePreviousPage} form={form} theme={theme} loading={waitingForSubmissionId} disabled={submitIsDisabled() || waitingForSubmissionId} paymentsProvider={paymentsProvider} placement={placement}/>
            </div>}
          </div>
        </div>
      </div>}


      {!submitted && status !== 'paused' && <RequiredHelper inIframe={inIframe}
        fullpage={fullpage}
        messages={form.messages}
        urlParams={urlParams}
        fieldsOptions={fieldsOptions}
        variables={variables}
        formType={form.type}
        values={values}
        isTouchDevice={isTouchDevice}
        highlightField={handleHighlightField}
        fields={form.fields.filter((field) => values && values[field._id] && values[field._id].visible && field.error && field.page === form.page)} />}

      {status === 'paused' && <div css={mainStyle}>
        <div css={contentStyle}>
          <Paused messages={form.messages} hideBranding={form.owner ? form.owner.hideBranding : false} />
        </div>
      </div>}

      {submitted && <div css={mainStyle}>
        <div css={contentStyle}>
          <Submitted inIframe={inIframe}
            messages={form.messages}
            submittedText={form.submittedText}
            respondentLimits={form.respondentLimits}
            respondentLimitsNumber={form.respondentLimitsNumber}
            thankYouPages={thankYouPages}
            defaultThankYouPage={defaultThankYouPage}
            fields={form.fields}
            values={values}
            fieldsOptions={fieldsOptions}
            urlParams={urlParams}
            forceThankYouPage={builderControl.previewThankYouPage}
            mode={mode}
            onSubmitAgain={handleSubmitAgain}
            theme={theme}
            variables={variables}
            hideBranding={form.owner ? form.owner.hideBranding : false} />
        </div>
      </div>}

      {backupExist && !submitted && status === 'live' && <div css={mainStyle}>
        <div css={contentStyle}>
          <Backup messages={form.messages}
            onRestoreBackup={handleRestoreBackup}
            onClearBackup={handleClearBackup}
            theme={theme}
            hideBranding={form.owner ? form.owner.hideBranding : false} />
        </div>
      </div>}

      {!showWelcomePage && <Footer inIframe={inIframe}
        formId={form._id}
        messages={form.messages}
        submitted={submitted}
        status={status}
        mode={mode}
        ownerIsPaidPlan={form?.owner?.billing?.plan && form?.owner?.billing?.plan !== 'trial'}
        isTouchDevice={isTouchDevice}
        form={form}
        allowGoUp={allowGoUp}
        allowGoDown={allowGoDown}
        onGoDown={() => handleGoDown()}
        onGoUp={() => handleGoUp()}
        userUiSettings={userUiSettings || {}}
        device={device}
        placement={placement}
        scrollbarWidth={form.type === 'conversational' ? 0 : scrollbarWidth}
        theme={theme}
        hideBranding={form.owner ? form.owner.hideBranding : false} />}

      {showProgressBar && !showWelcomePage && !submitted && !backupExist && status !== 'paused' && <ProgressBar theme={theme}
        formId={form._id}
        fields={form.fields}
        activeFieldIndex={activeFieldIndex}
        messages={form.messages}
        formType={form.type}
        totalPages={form.pages}
        activePageNumber={form.page}
        values={values}
        mode={mode}
        device={device}
        placement={placement}
        userUiSettings={userUiSettings || {}}
        scrollbarWidth={form.type === 'conversational' ? 0 : scrollbarWidth} />}

      {submitError && <SubmitError messages={form.messages} onClick={() => clearSubmitError()}>{submitError}</SubmitError>}
    </div>
  );
};

export default QuestionscoutFormEngine;