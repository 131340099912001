export default function isRequired(_id, fields = []) {
  const field = fields.find((f) => f._id === _id);
  let anyRequired = false;
  let childField = null;

  if (!field) return false;

  if (field.type === 'section') {
    if (Array.isArray(field.childrens)) {
      for (let childFieldId of field.childrens) {
        childField = fields.find((f) => f._id === childFieldId);

        if (!childField) continue;

        if (childField.type === 'section') {
          if (isRequired(childFieldId, fields)) anyRequired = true;
        } else if (childField.required) {
          anyRequired = true;
        }
      }

      return anyRequired;
    } else {
      return false;
    }
  } else {
    return field.required;
  }
};
