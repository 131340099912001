
import App from './App.js';
import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import * as Sentry from '@sentry/browser';
import cookie from './cookies.js';

import './index.css';
import 'normalize.css';
import 'react-scrollbar/dist/css/scrollArea.css';

let iframeScript;

if (window.self !== window.top) { // inIframe
  iframeScript = document.createElement('script');
  iframeScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.2.10/iframeResizer.contentWindow.min.js';
  document.head.appendChild(iframeScript);
}

const cookieSubmissionsCount = cookie.get(`qs-form-submissions-${window.QS_FORM._id}`) || '0';

if (window.QS_FORM.respondentLimits && parseInt(cookieSubmissionsCount) >= window.QS_FORM.respondentLimitsNumber) {
  window.QS_FORM.submitted = true;
}

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({ dsn: 'https://b304182ee9ef4bd7ba7ec6d0c67f1abd@sentry.io/1492831' });
}

hydrate(
  <Provider store={store}>
    <App form={window.QS_FORM} theme={window.QS_THEME} seed={window.QS_SEED} submission={window.QS_SUBMISSION} />
  </Provider>,
  document.getElementById('root')
);

window.QS_FORM = undefined;
delete window.QS_FORM;

window.QS_THEME = undefined;
delete window.QS_THEME;

window.QS_SUBMISSION = undefined;
delete window.QS_SUBMISSION;

if (module.hot) {
  module.hot.accept();
}
