export default function isJsonString(str) {
  if (str === null) return false;
  if (typeof str !== 'string') return false;

  str = str.trim();

  if (typeof str === 'string' && (str[0] === '"' || str[str.length] === '"')) {
    str = str.slice(1, -1).trim();
  }

  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
};
