/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import React, { useMemo, useCallback } from 'react';
import getFieldLabel from 'helpers/getFieldLabel.js';
import Translate from '../messages/Translate.js';

import { LabelAtom, DescriptionAtom } from './atoms.js';

const alignOptions = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

const Dropdown = ({ form, field, theme, seed, value, values, onChange, onAfterChange, onPartialResponse, onNextClick, fieldsOptions, variables, urlParams, onClick }) => {  
  if (!onClick) onClick = () => { };

  const translate = useMemo(() => new Translate(form.messages), [form.messages]);

  const inputContainerStyleTypes = useMemo(() => ({
    style1: {
      borderBottom: `${theme.inputFieldsIdleBorderSize}px solid ${theme.inputFieldsIdleBorderColor}`,
    },
    style2: {
      border: 'none'
    },
    style3: {
      border: `${theme.inputFieldsIdleBorderSize}px solid ${theme.inputFieldsIdleBorderColor}`,
    }
  }), [theme.inputFieldsIdleBorderColor, theme.inputFieldsIdleBorderSize]);

  const mainStyle = useMemo(() => css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: alignOptions[theme.inputFieldsDropdownsAlignment],
    margin: `${theme.inputFieldsDropdownsTopMargin || 0}px 0 ${theme.inputFieldsDropdownsBottomMargin || 0}px 0`
  }), [theme.inputFieldsDropdownsAlignment, theme.inputFieldsDropdownsBottomMargin, theme.inputFieldsDropdownsTopMargin]);

  const contentStyle = useMemo(() => css({
    width: '100%',
    maxWidth: `${theme.inputFieldsDropdownsWidth}${theme.inputFieldsDropdownsWidthType}`,
    position: 'relative'
  }), [theme.inputFieldsDropdownsWidth, theme.inputFieldsDropdownsWidthType]);

  const containerStyle = useMemo(() => css({
    position: 'relative',

    '&:hover div': (() => {
      if (!theme.inputFieldsHoverEnable) return {};

      const obj = {
        background: theme.inputFieldsHoverBackground,
        borderColor: theme.inputFieldsHoverBorderColor,
        color: theme.inputFieldsHoverTextColor,
        '& .value': {
          color: theme.inputFieldsHoverTextColor
        },
        '& .placeholder': {
          color: theme.inputFieldsHoverPlaceholderColor
        },
        '& svg *': {
          fill: theme.inputFieldsHoverBorderColor
        }
      };

      if (theme.inputFieldsHoverShadow) {
        obj.boxShadow = `${theme.inputFieldsHoverShadowOffsetX}px ${theme.inputFieldsHoverShadowOffsetY}px ${theme.inputFieldsHoverShadowBlur}px ${theme.inputFieldsHoverShadowSpread}px ${theme.inputFieldsHoverShadowColor}`;
      } else {
        obj.boxShadow = 'none';
      }

      return obj;
    })()
  }), [theme.inputFieldsHoverBackground, theme.inputFieldsHoverBorderColor, theme.inputFieldsHoverEnable, theme.inputFieldsHoverPlaceholderColor, theme.inputFieldsHoverShadow, theme.inputFieldsHoverShadowBlur, theme.inputFieldsHoverShadowColor, theme.inputFieldsHoverShadowOffsetX, theme.inputFieldsHoverShadowOffsetY, theme.inputFieldsHoverShadowSpread, theme.inputFieldsHoverTextColor]);

  const previewStyle = useMemo(() => css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: theme.inputFieldsIdleBackground,
    fontFamily: theme.typographyContentFontFamily,
    borderRadius: `${theme.inputFieldsIdleRoundness}px`,
    boxSizing: 'border-box',
    width: '100%',
    ...inputContainerStyleTypes[theme.inputFieldsDropdownsType],
    boxShadow: theme.inputFieldsIdleShadow ? `${theme.inputFieldsIdleShadowOffsetX}px ${theme.inputFieldsIdleShadowOffsetY}px ${theme.inputFieldsIdleShadowBlur}px ${theme.inputFieldsIdleShadowSpread}px ${theme.inputFieldsIdleShadowColor}` : 'none',
    padding: `${theme.inputFieldsIdleVerticalPadding || 0}px ${theme.inputFieldsIdleHorizontalPadding || 0}px`,
    '& .value': {
      width: `calc(100% - ${theme.inputFieldsIdleTextFontSize}px)`,
      display: 'block',
      lineHeight: `142%`,
      color: theme.inputFieldsIdleTextColor,
      fontWeight: theme.inputFieldsIdleTextWeight,
      fontSize: `${theme.inputFieldsIdleTextFontSize}px`,
      boxSizing: 'border-box',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '& .placeholder': {
      display: 'block',
      width: `calc(100% - ${theme.inputFieldsIdleTextFontSize}px)`,
      lineHeight: `142%`,
      color: theme.inputFieldsIdlePlaceholderColor,
      fontWeight: theme.inputFieldsIdleTextWeight,
      fontSize: `${theme.inputFieldsIdlePlaceholderFontSize}px`,
      boxSizing: 'border-box',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '& svg': {
      width: `${theme.inputFieldsIdleTextFontSize}px`
    },
    '& svg *': {
      fill: theme.inputFieldsIdleBorderColor
    }
  }), [inputContainerStyleTypes, theme.inputFieldsDropdownsType, theme.inputFieldsIdleBackground, theme.inputFieldsIdleBorderColor, theme.inputFieldsIdleHorizontalPadding, theme.inputFieldsIdlePlaceholderColor, theme.inputFieldsIdlePlaceholderFontSize, theme.inputFieldsIdleRoundness, theme.inputFieldsIdleShadow, theme.inputFieldsIdleShadowBlur, theme.inputFieldsIdleShadowColor, theme.inputFieldsIdleShadowOffsetX, theme.inputFieldsIdleShadowOffsetY, theme.inputFieldsIdleShadowSpread, theme.inputFieldsIdleTextColor, theme.inputFieldsIdleTextFontSize, theme.inputFieldsIdleTextWeight, theme.inputFieldsIdleVerticalPadding, theme.typographyContentFontFamily]);

  const inputStyle = useMemo(() => css({
    width: '100%',
    boxSizing: 'border-box',
    appearance: 'none',
    borderRadius: 0,
    position: 'absolute',
    opacity: 0,
    top: 0,
    left: 0,
    fontFamily: theme.typographyContentFontFamily,
    lineHeight: '142%',
    color: theme.inputFieldsIdleTextColor,
    fontWeight: theme.inputFieldsIdleTextWeight,
    fontSize: theme.inputFieldsIdleTextFontSize,
    padding: `${theme.inputFieldsIdleVerticalPadding || 0}px ${theme.inputFieldsIdleHorizontalPadding || 0}px`
  }), [theme.inputFieldsIdleHorizontalPadding, theme.inputFieldsIdleTextColor, theme.inputFieldsIdleTextFontSize, theme.inputFieldsIdleTextWeight, theme.inputFieldsIdleVerticalPadding, theme.typographyContentFontFamily]);

  const optionStyle = useMemo(() => css({
    color: '#000'
  }), []);

  const selectedOption = useMemo(() => {
    return field.options.find((option) => option._id === value || option.ref === value) || { value: '', ref: null, _id: null };
  }, [field.options, value]);

  const handleChange = useCallback((e) => {
    onChange({ [field._id]: e.target.value });
    if (onAfterChange) onAfterChange({ [field._id]: e.target.value });
    onPartialResponse(field._id);

    if (field.section === 'root' || !field.section) onNextClick(field);
  }, [field, onChange, onAfterChange, onNextClick, onPartialResponse]);

  const optionsList = useMemo(() => field.options.map((option) => ({
    label: field.differentValues ? option.label : option.value,
    value: option.ref || option._id
  })).map((option) => {
    if (option.label === '') return <></>;

    return <option css={optionStyle} key={option.value} value={option.value}>{option.label}</option>
  }), [field.options, field.differentValues, optionStyle]);

  return <>
    <div css={mainStyle}>
      <div css={contentStyle} onClick={() => onClick(field._id)}>
        <LabelAtom required={field.required} error={field.error} theme={theme}>{getFieldLabel(field.label, values, 'jsx', fieldsOptions, variables, urlParams)}</LabelAtom>

        <div css={containerStyle}>
          <div css={previewStyle}>
            {selectedOption._id === null && <span className="placeholder">{field.placeholder || translate.text('dropdownSelect')}</span>}
            {selectedOption._id !== null && <span className="value">{(field.differentValues ? selectedOption.label : selectedOption.value) || '\u00a0'}</span>}

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <g>
                <path d="M12 16a1 1 0 0 1-.64-.23l-6-5a1 1 0 1 1 1.28-1.54L12 13.71l5.36-4.32a1 1 0 0 1 1.41.15 1 1 0 0 1-.14 1.46l-6 4.83A1 1 0 0 1 12 16z" />
              </g>
            </svg>
          </div>

          <select css={inputStyle} onChange={handleChange} defaultValue={value || ''}>
            {!value && <option css={optionStyle} disabled value="">{translate.text('dropdownSelect')}</option>}

            {optionsList}
          </select>
        </div>

        <DescriptionAtom theme={theme}>{getFieldLabel(field.description, values, 'jsx', fieldsOptions, variables, urlParams)}</DescriptionAtom>
      </div>
    </div>
  </>;
};

export default Dropdown;
