/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import scrollIntoView from 'scroll-into-view';
import React, { useState, useMemo } from 'react';
import getFieldLabel from 'helpers/getFieldLabel.js';
import Translate from '../messages/Translate.js';
import getRootSectionValueObj from 'helpers/getRootSectionValueObj.js';

import { ReactComponent as LinkArrowIcon } from 'assets/images/link-arrow.svg';

const RequiredHelper = ({ inIframe, isTouchDevice, messages, fields, highlightField, formType, fullpage, values, fieldsOptions, urlParams, variables }) => {
  const translate = useMemo(() => new Translate(messages), [messages]);

  const [fieldsLength, setFieldsLength] = useState(0);
  const [animation, setAnimation] = useState(false);

  if (fieldsLength !== fields.length) {
    if (fieldsLength !== 0) {
      setAnimation(true);

      setTimeout(() => setAnimation(false), 300);
    }

    setFieldsLength(fields.length);
  }

  const universalErroMessage = translate.jsx('errorEmpty');

  const errorMessages = {
    shortTextphone: () => translate.jsx('invalidNumber'),
    shortTextemail: () => translate.jsx('invalidEmail'),
    datetime: () => translate.jsx('invalidDate'),
    matrix: () => translate.jsx('matrixSelectionRequired'),
    checkbox: (field) => {
      if (field.selectionLimits && field.selectionLimitsMin === field.selectionLimitsMax) {
        return translate.text('checkboxSelectionExactly', { minimumOptions: { value: field.selectionLimitsMin } });
      } else if (field.selectionLimits && field.selectionLimitsMin !== field.selectionLimitsMax) {
        return translate.text('checkboxSelectionAtLeast', { minimumOptions: { value: field.selectionLimitsMin }, maximumOptions: { value: field.selectionLimitsMax } });
      } else {
        return translate.text('checkboxDefault');
      }
    },
    imageChoice: (field) => {
      if (field.selectionLimits && field.selectionLimitsMin === field.selectionLimitsMax) {
        return translate.text('checkboxSelectionExactly', { minimumOptions: { value: field.selectionLimitsMin } });
      } else if (field.selectionLimits && field.selectionLimitsMin !== field.selectionLimitsMax) {
        return translate.text('checkboxSelectionAtLeast', { minimumOptions: { value: field.selectionLimitsMin }, maximumOptions: { value: field.selectionLimitsMax } });
      } else {
        return translate.text('checkboxDefault');
      }
    }
  };  

  const styles = {
    main: !isTouchDevice ? css({
      backgroundColor: '#FFFFFF',
      border: '0.5px solid #DBDBDB',
      borderRadius: '8px',
      boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.1)',
      maxWidth: '350px',
      width: '100%',
      position: !inIframe ? 'fixed' : 'absolute',
      bottom: !inIframe ? '60px' : '10px',
      right: '10px',
      zIndex: 128,
      overflow: 'hidden'
    }) : css({
      backgroundColor: '#FFFFFF',
      border: '0.5px solid #DBDBDB',
      borderRadius: '8px',
      boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.1)',
      position: 'fixed',
      bottom: '55px',
      zIndex: 128,
      width: 'calc(100% - 40px)',
      left: '20px'
    }),
    head: css({
      borderBottom: '1px solid #E7E7E7',
      lineHeight: '142%',
      color: '#646464',
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '142%',
      padding: '10px 15px 10px 15px'
    }),
    ul: css({
      listStyle: 'none',
      margin: 0,
      padding: 0,
      maxHeight: '250px',
      overflowY: 'auto'
    }),
    li: css({
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '10px 15px',
      cursor: 'pointer',
      boxSizing: 'border-box',
      '&:not(:last-child)': {
        borderBottom: '1px solid #E7E7E7'
      },
      '&:hover': {
        background: '#FAFAFA'
      }
    }),
    label: css({
      lineHeight: '142%',
      fontFamily: 'Roboto',
      color: '#646464',
      fontSize: '14px',
      fontWeight: '400'
    }),
    message: css({
      lineHeight: '142%',
      fontFamily: 'Roboto',
      color: '#D43D51',
      fontSize: '12px',
      fontWeight: '500',
      margin: '5px 0 0 0'
    }),
    arrow: css({
      backgroundColor: '#FAFAFA',
      borderRadius: '5px',
      margin: '0 0 0 10px',
      minWidth: '40px',
      height: '28px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      'svg *': {
        fill: '#000000'
      }
    }),
    activeField: css({
      padding: '10px 15px',
      boxSizing: 'border-box',
      transition: 'opacity 0.3s ease-in-out',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      '&.animation': {
        opacity: 0
      }
    })
  };

  const handleClick = (id) => {
    let node, inputs;

    if (formType === 'conversational') {
      fullpage.moveTo(getRootSectionValueObj(id, values)._id);
    } else if (formType === 'classic') {
      node = document.getElementById(id);

      highlightField(id, 1000);
      scrollIntoView(node, {
        align: {
          top: isTouchDevice ? 1 : 0.5
        }, time: 300
      });

      inputs = node.querySelectorAll('input, textarea');

      if (inputs[0]) {
        setTimeout(() => inputs[0].focus(), isTouchDevice ? 300 : 0);
      }
    }
  };

  return <>
    {fields.length > 0 && <div css={styles.main}>
      <div css={styles.head}>{translate.text('errorTitleMultiple', { requiredNumber: { value: fields.length } })}</div>

      {!isTouchDevice && <ul css={styles.ul}>
        {fields.map((field) => <li key={field._id} css={styles.li} onClick={() => handleClick(field._id)}>
          <div>
            <div css={styles.label}>{getFieldLabel(field.label || field.placeholer || field.type, values, "text", fieldsOptions, variables, urlParams)}</div>
            <div css={styles.message}>
              {errorMessages[`${field.type}${field.format ? field.format : ''}`] ? errorMessages[`${field.type}${field.format ? field.format : ''}`](field) : universalErroMessage}
            </div>
          </div>

          <div css={styles.arrow}>
            <LinkArrowIcon />
          </div>
        </li>)}
      </ul>}

      {isTouchDevice && <>
        <div css={styles.activeField} onClick={() => handleClick(fields[0]._id)} className={animation ? 'animation' : ''}>
          <div>
            <div css={styles.label}>{getFieldLabel(fields[0].label || fields[0].placeholer || fields[0].type, values, "text", fieldsOptions, variables, urlParams)}</div>
            <div css={styles.message}>
              {errorMessages[`${fields[0].type}${fields[0].format ? fields[0].format : ''}`] ? errorMessages[`${fields[0].type}${fields[0].format ? fields[0].format : ''}`](fields[0]) : universalErroMessage}
            </div>
          </div>

          <div css={styles.arrow}>
            <LinkArrowIcon />
          </div>
        </div>
      </>}
    </div>}
  </>;
};

export default RequiredHelper;
