/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import React, { useState, useMemo, useRef, useCallback } from 'react';
import isJsonString from 'helpers/isJsonString.js';
import isMongoId from 'validator/lib/isMongoId';

const ALPHABET_LETTERS = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');

/* -------------------------------------------------------------------------- */
/*                                 CSS MAKERS                                 */
/* -------------------------------------------------------------------------- */

/* -------------------------- DEFAULT OPTION STYLE -------------------------- */

const createOptionStyle = ({ isMultipleChoice, expandOther, theme }) => {
  return css({
    // Main option style
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    boxSizing: 'border-box',
    width: '100%',
    cursor: 'pointer',
    userSelect: 'none',
    background: theme.singleMultipleChoiceIdleBackground,
    padding: `${theme.singleMultipleChoiceIdleVerticalPadding}px ${theme.singleMultipleChoiceIdleHorizontalPadding}px`,
    margin: `${theme.singleMultipleChoiceIdleVerticalMargin}px 0`,
    fontFamily: theme.typographyContentFontFamily,
    lineHeight: '142%',
    color: theme.singleMultipleChoiceIdleTextColor,
    fontWeight: theme.singleMultipleChoiceIdleTextWeight,
    fontSize: theme.singleMultipleChoiceIdleTextFontSize,
    borderRadius: `${theme.singleMultipleChoiceIdleRoundness}px`,
    border: `${theme.singleMultipleChoiceIdleBorderSize}px solid ${theme.singleMultipleChoiceIdleBorderColor}`,
    boxShadow: theme.singleMultipleChoiceIdleShadow
      ? `${theme.singleMultipleChoiceIdleShadowOffsetX}px ${theme.singleMultipleChoiceIdleShadowOffsetY}px ${theme.singleMultipleChoiceIdleShadowBlur}px ${theme.singleMultipleChoiceIdleShadowSpread}px ${theme.singleMultipleChoiceIdleShadowColor}`
      : 'none',

    // Removing margin on first and last element
    '&:first-of-type': {
      margin: `0 0 ${theme.singleMultipleChoiceIdleVerticalMargin}px 0`
    },
    '&:last-of-type': {
      margin: !expandOther
        ? `${theme.singleMultipleChoiceIdleVerticalMargin}px 0 0 0`
        : `${theme.singleMultipleChoiceIdleVerticalMargin}px 0`
    },

    // Alphabet letter and text styling
    '& .alphabet': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      lineHeight: '142%',
      boxSizing: 'border-box',
      fontSize: theme.singleMultipleChoiceIdleTextFontSize,
      fontWeight: '600',
      border: `${theme.singleMultipleChoiceIdleBadgeBorderSize}px solid ${theme.singleMultipleChoiceIdleBadgeBorderColor}`,
      background: theme.singleMultipleChoiceIdleBadgeBackground,
      color: theme.singleMultipleChoiceIdleBadgeTextColor,
      borderRadius: '2px',
      margin: '0 7px 0 0',
      textAlign: 'center',
      minWidth: `calc(1em + ${theme.singleMultipleChoiceIdleVerticalPadding}px)`
    },
    '& .value': {
      lineHeight: '142%',
      boxSizing: 'border-box'
    },

    // Hover state
    '&:hover, &.hover': (() => {
      const obj = {};

      if (theme.singleMultipleChoiceHoverEnable) {
        obj.background = theme.singleMultipleChoiceHoverBackground;
        obj.borderColor = theme.singleMultipleChoiceHoverBorderColor;
        obj.color = theme.singleMultipleChoiceHoverTextColor;
      }

      if (theme.singleMultipleChoiceHoverShadow && theme.singleMultipleChoiceHoverEnable) {
        obj.boxShadow = `${theme.singleMultipleChoiceHoverShadowOffsetX}px ${theme.singleMultipleChoiceHoverShadowOffsetY}px ${theme.singleMultipleChoiceHoverShadowBlur}px ${theme.singleMultipleChoiceHoverShadowSpread}px ${theme.singleMultipleChoiceHoverShadowColor}`;
      } else {
        obj.boxShadow = 'none';
      }

      obj['& .alphabet'] = {
        background: theme.singleMultipleChoiceHoverBadgeBackground,
        borderColor: theme.singleMultipleChoiceHoverBadgeBorderColor,
        color: theme.singleMultipleChoiceHoverBadgeTextColor
      };

      return obj;
    })(),

    // Active state
    '&:active, &.isActive': (() => {
      const obj = {};

      if (theme.singleMultipleChoiceActiveEnable) {
        obj.background = theme.singleMultipleChoiceActiveBackground;
        obj.borderColor = theme.singleMultipleChoiceActiveBorderColor;
        obj.color = theme.singleMultipleChoiceActiveTextColor;
        obj.cursor = isMultipleChoice ? 'cursor' : 'default';
      }

      if (theme.singleMultipleChoiceActiveShadow && theme.singleMultipleChoiceActiveEnable) {
        obj.boxShadow = `${theme.singleMultipleChoiceActiveShadowOffsetX}px ${theme.singleMultipleChoiceActiveShadowOffsetY}px ${theme.singleMultipleChoiceActiveShadowBlur}px ${theme.singleMultipleChoiceActiveShadowSpread}px ${theme.singleMultipleChoiceActiveShadowColor}`;
      } else {
        obj.boxShadow = 'none';
      }

      obj['& .alphabet'] = {
        background: theme.singleMultipleChoiceActiveBadgeBackground,
        borderColor: theme.singleMultipleChoiceActiveBadgeBorderColor,
        color: theme.singleMultipleChoiceActiveBadgeTextColor
      };

      return obj;
    })()
  });
};

/* ------------------------ NATIVE LIKE OPTION STYLE ------------------------ */

const createNativeLikeOptionStyle = ({ isMultipleChoice, expandOther, theme }) => {
  return css({
    // Main option style
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    boxSizing: 'border-box',
    width: '100%',
    cursor: 'pointer',
    userSelect: 'none',
    margin: `${theme.singleMultipleChoiceIdleVerticalMargin}px 0`,
    fontFamily: theme.typographyContentFontFamily,
    lineHeight: '142%',
    color: theme.singleMultipleChoiceIdleTextColor,
    fontWeight: theme.singleMultipleChoiceIdleTextWeight,
    fontSize: theme.singleMultipleChoiceIdleTextFontSize || 16,

    // Removing margin on first and last element
    '&:first-of-type': {
      margin: `0 0 ${theme.singleMultipleChoiceIdleVerticalMargin}px 0`
    },
    '&:last-of-type': {
      margin: !expandOther
        ? `${theme.singleMultipleChoiceIdleVerticalMargin}px 0 0 0`
        : `${theme.singleMultipleChoiceIdleVerticalMargin}px 0`
    },

    // Label
    '& .value': {
      lineHeight: '142%',
      boxSizing: 'border-box'
    },

    // Checkbox box and radio circle styling
    '& .checkbox, & .radio': {
      flexShrink: 0,
      transform: 'translateY(0.105em)', // (lineHeight - 100%)/4
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      height: `1.1em`,
      width: `1.1em`,
      marginRight: `0.6em`,

      background: theme.singleMultipleChoiceIdleBackground,

      border: `${theme.singleMultipleChoiceIdleBorderSize}px solid ${theme.singleMultipleChoiceIdleBorderColor}`
    },
    '& .checkbox': {
      borderRadius: '2px'
    },
    '& .radio': {
      borderRadius: '100%'
    },

    // Check and dot styles
    '& .dot, & .check': {
      opacity: 0
    },
    '& .dot': {
      width: '100%',
      height: '100%',

      background: theme.singleMultipleChoiceIdleTextColor,

      borderRadius: '100%',
      transform: 'scale(0.5)'
    },
    '& .check-container': {
      boxSizing: 'border-box',
      width: '60%',
      height: '30%',
      transform: 'translateY(-15%)'
    },
    '& .check': {
      boxSizing: 'border-box',
      width: '100%',
      height: '100%',

      borderStyle: 'solid',
      borderWidth: '2px 2px 0 0',
      borderColor: theme.singleMultipleChoiceIdleTextColor,

      transform: 'rotate(135deg)'
    },

    // Hover state
    '&:hover, &.hover': (() => {
      const obj = {};

      obj['& .checkbox, & .radio'] = {
        background: theme.singleMultipleChoiceHoverBackground,
        borderColor: theme.singleMultipleChoiceHoverBorderColor
      };
      obj['& .check'] = {
        borderColor: theme.singleMultipleChoiceHoverTextColor
      };

      return obj;
    })(),

    // Active state
    '&:active, &.isActive': (() => {
      const obj = {};

      if (theme.singleMultipleChoiceActiveEnable) {
        obj.cursor = isMultipleChoice ? 'cursor' : 'default';
      }

      obj['& .checkbox'] = {
        background: theme.singleMultipleChoiceActiveBackground,
        borderColor: theme.singleMultipleChoiceActiveBorderColor
      };

      obj['& .radio'] = {
        background: theme.singleMultipleChoiceActiveBackground,
        borderColor: theme.singleMultipleChoiceActiveBorderColor
      };

      obj['& .dot'] = {
        background: theme.singleMultipleChoiceActiveTextColor,
        opacity: 1
      };
      obj['& .check'] = {
        borderColor: theme.singleMultipleChoiceActiveTextColor,
        opacity: 1
      };

      return obj;
    })()
  });
};

/* -------------------------------------------------------------------------- */
/*                               MAIN COMPONENT                               */
/* -------------------------------------------------------------------------- */

const SingleMultipleChoiceOptions = ({
  isMultipleChoice = false,

  options,

  value, // Value or array of values as JSON
  onOptionClick, // Called with ref or option id
  expandOther,
  onToggleExpandOther,

  field,
  form,
  translate,
  theme
}) => {
  /* ---------------------------------- PROPS --------------------------------- */

  const formType = form.type;

  const showOther = field.otherOption === true;
  const customLabelsAreEnabled = field.differentValues;
  const highlightedOptionIndex = field.highlighted;

  // Prioritize field settings, fallback to theme settings
  const stylingVariant = field.stylingVariant || theme.singleMultipleChoiceDefaultStylingVariant;

  const showAlphabetLetters = formType === 'conversational';
  const nativeLikeStyling = stylingVariant === 'classic';

  /* --------------------------------- HELPERS -------------------------------- */

  const isOptionActive = useCallback(
    (option) => {
      if (!value) return false;

      // Values passed as object with array
      if (isMultipleChoice) {
        let isV2MongoId = true;

        const fieldValue = value ? JSON.parse(isJsonString(value) ? value : '{"values":[],"other":null}').values : [];
        if (fieldValue && Array.isArray(fieldValue) && fieldValue.length > 0) {
          isV2MongoId = isMongoId(fieldValue[0] || '');
        }
        return fieldValue.indexOf(String(isV2MongoId ? option._id : option.ref || null)) !== -1;
      }

      // Values passed as object with value
      else {
        const fieldValue = value ? JSON.parse(isJsonString(value) ? value : '{"value":null,"other":null}').value : null;

        return String(isMongoId(fieldValue || '') ? option._id : option.ref || null) === fieldValue;
      }
    },
    [value, isMultipleChoice]
  );

  /* --------------------------------- STYLES --------------------------------- */

  const optionsContainerStyle = useMemo(
    () =>
      css({
        listStyle: 'none',
        padding: 0,
        margin: 0
      }),
    []
  );

  const optionsStyle = useMemo(() => {
    if (!nativeLikeStyling) {
      return createOptionStyle({ isMultipleChoice, expandOther, theme });
    } else {
      return createNativeLikeOptionStyle({ isMultipleChoice, expandOther, theme });
    }
  }, [isMultipleChoice, nativeLikeStyling, expandOther, theme]);

  /* ---------------------------- OPTION RENDERING ---------------------------- */

  const renderOption = useCallback(
    ({ key, index = 0, text = '', isActive = false, isHighlighted = false, onClick }) => {
      // Don't render empty options
      if (!text) return null;

      let prefix = null;

      if (nativeLikeStyling) {
        if (isMultipleChoice) {
          prefix = (
            <div className="checkbox">
              <div className="check-container">
                <div className="check" />
              </div>
            </div>
          );
        } else {
          prefix = (
            <div className="radio">
              <div className="dot" />
            </div>
          );
        }
      } else if (showAlphabetLetters) {
        prefix = <div className="alphabet">{ALPHABET_LETTERS[index] || ''}</div>;
      }

      return (
        <li key={key} className={[isActive ? 'isActive' : '', isHighlighted ? 'hover' : ''].join(' ')} css={optionsStyle} onClick={onClick}>
          {prefix}
          <div className="value">{text}</div>
        </li>
      );
    },
    [optionsStyle, showAlphabetLetters, nativeLikeStyling, isMultipleChoice]
  );

  /* ----------------------------------- JSX ---------------------------------- */

  const optionsListJsx = useMemo(
    () =>
      options.map((option, index) => {
        const optionIdentifier = option.ref || option._id;

        return renderOption({
          key: optionIdentifier,
          index: index,
          text: customLabelsAreEnabled ? option.label : option.value,
          isActive: isOptionActive(option),
          isHighlighted: highlightedOptionIndex === index,
          onClick: () => onOptionClick(optionIdentifier)
        });
      }),
    [options, renderOption, isOptionActive, highlightedOptionIndex, customLabelsAreEnabled, onOptionClick]
  );

  return (
    <ul css={optionsContainerStyle}>
      {optionsListJsx}

      {showOther &&
        renderOption({
          index: options.length,
          isActive: expandOther,
          text: translate.text('otherOption'),
          onClick: onToggleExpandOther
        })}
    </ul>
  );
};

export default SingleMultipleChoiceOptions;
