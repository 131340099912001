/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import React, { useMemo, useCallback } from 'react';

const alignOptions = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

const Image = ({ form, field, theme, fieldsOptions, variables, urlParams, onClick }) => {  
  if (!onClick) onClick = () => { };

  const mainStyle = useMemo(() => css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: alignOptions[theme.imagesAlignment],
    margin: `${theme.imagesTopMargin || 0}px 0 ${theme.imagesBottomMargin || 0}px 0`
  }), [theme.imagesAlignment, theme.imagesBottomMargin, theme.imagesTopMargin]);

  const contentStyle = useMemo(() => css({
    width: '100%',
    maxWidth: `${theme.imagesWidth}${theme.imagesWidthType}`,
    textAlign: field.imageAlign
  }), [field.imageAlign, theme.imagesWidth, theme.imagesWidthType]);

  let onError = useCallback((e) => {
    e.target.src = e.target.src.replace(/hash=\d*/, `hash=${Date.now()}`);
  }, []);

  return <>
    <div css={mainStyle}>
      <div css={contentStyle} onClick={() => onClick(field._id)}>
        <img src={field.value} onError={(e) => {
          onError(e);
          onError = () => { };
        }} alt="" style={{ width: `${field.imageScale}%` }} />
      </div>
    </div>
  </>;
};

export default Image;
