import executeModifier from 'helpers/executeModifier.js';
import orderFields from 'helpers/orderFields.js';
import Big from 'big.js';

const actions = {
  '+': (a, b) => Number(new Big(b).plus(a)),
  '-': (a, b) => Number(new Big(b).minus(a)),
  '*': (a, b) => Number(new Big(b).times(a)),
  '/': (a, b) => Number(new Big(b).div(a))
};

const getFieldValuesObject = (values, field) => {
  if (!values[field._id]) return [];

  if (field.type === 'checkbox') {
    return {
      values: (JSON.parse(values[field._id].value) || { values: [] }).values,
      visible: values[field._id].visible
    };
  }

  if (field.type === 'dropdown') {
    return values[field._id];
  }

  if (field.type === 'radio') {
    return {
      value: (JSON.parse(values[field._id].value) || { value: [] }).value,
      visible: values[field._id].visible
    };
  }

  if (field.type === 'imageChoice') {
    return {
      values: (JSON.parse(values[field._id].value) || { values: [] }).values,
      visible: values[field._id].visible
    };
  }

  if (field.type === 'scale') {
    return values[field._id];
  }

  if (field.type === 'shortText' && field.format === 'number') {
    return values[field._id];
  }

  return [];
};

const executeCalculation = (variables, calculation, value, type) => {
  let variablesCopy = [ ...variables ];

  if (type === 'single' && String(calculation.option) !== String(value)) return variables;
  if (type === 'multiple' && value.indexOf(String(calculation.option)) === -1) return variables;

  let targetIndex = variablesCopy.findIndex((v) => String(v._id) === String(calculation.target));

  if (targetIndex === -1) return variables;

  try {
    variablesCopy[targetIndex].value = actions[calculation.action](calculation.value, variablesCopy[targetIndex].value);
  } catch (e) {
    variablesCopy[targetIndex].value = variablesCopy[targetIndex].value;
  }

  return variablesCopy;
};

const executeNumberCalculation = (variables, calculation, value) => {
  let variablesCopy = [ ...variables ];

  let targetIndex = variablesCopy.findIndex((v) => String(v._id) === String(calculation.target));

  if (targetIndex === -1) return variables;

  try {
    variablesCopy[targetIndex].value = actions[calculation.action](value, variablesCopy[targetIndex].value);
  } catch (e) {
    variablesCopy[targetIndex].value = variablesCopy[targetIndex].value;
  }

  return variablesCopy;
};

export default function getVariables(calculationVariables = [], urlParams = {},  fields, values) {
  let allowed = false;
  let variables = [ ...calculationVariables ];
  let valueObj, isNumberField, isScaleField;
  const newFields = orderFields(fields);

  variables = variables.map((variable) => ({ ...variable, value: variable.initialValue }));

  for (let field of newFields) {
    allowed = ['radio', 'checkbox', 'dropdown', 'imageChoice', 'scale', 'shortText'].indexOf(field.type) !== -1;
    if (field.type === 'shortText' && field.format !== 'number') allowed = false;

    isNumberField = field.type === 'shortText' && field.format === 'number';
    isScaleField = field.type === 'scale';

    if (!allowed || !field.calculations) continue;
  
    for (let calculation of field.calculations) {
      valueObj = getFieldValuesObject(values, field);

      if (!valueObj.visible) continue;

      if ((isNumberField || (isScaleField && calculation.type === 'use-value')) && typeof valueObj.value !== 'undefined' && valueObj.value !== null) {
        variables = executeNumberCalculation(variables, calculation, parseFloat(valueObj.value));
      } else if (typeof valueObj.value !== 'undefined' && valueObj.value !== null) {
        variables = executeCalculation(variables, calculation, valueObj.value, 'single');
      } else if (Array.isArray(valueObj.values) && valueObj.values.length !== 0) {
        variables = executeCalculation(variables, calculation, valueObj.values, 'multiple');
      }
    }
  }

  variables = executeModifier(variables, urlParams);

  return variables || [];
};
