/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import React, { useMemo } from 'react';
import getFieldLabel from 'helpers/getFieldLabel.js';

const alignOptions = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

const Title = ({ form, field, theme, values, fieldsOptions, variables, urlParams, onClick }) => {  
  if (!onClick) onClick = () => { };

  const mainStyle = useMemo(() => css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: alignOptions[theme.titlesAlignment],
    margin: `${theme.titlesTopMargin || 0}px 0 ${theme.titlesBottomMargin || 0}px 0`
  }), [theme.titlesAlignment, theme.titlesBottomMargin, theme.titlesTopMargin]);

  const contentStyle = useMemo(() => css({
    width: '100%',
    maxWidth: `${theme.titlesWidth}${theme.titlesWidthType}`,
  }), [theme.titlesWidth, theme.titlesWidthType]);

  const titleStyle = useMemo(() => ({
    textAlign: theme.titlesTextAlignment,
    fontSize: `${theme.titlesMainFontSize}px`,
    lineHeight: '142%',
    fontWeight: theme.titlesMainWeight,
    color: theme.titlesMainColor,
    fontFamily: theme.typographyTitlesFontFamily,
    boxSizing: 'border-box',
    wordBreak: 'break-word',
    'p:first-of-type': {
      marginTop: 0
    },
    'p:last-of-type': {
      marginBottom: 0
    }
  }), [theme.titlesMainColor, theme.titlesMainFontSize, theme.titlesMainWeight, theme.titlesTextAlignment, theme.typographyTitlesFontFamily])

  const descriptionStyle = useMemo(() => ({
    textAlign: theme.titlesTextAlignment,
    fontSize: `${theme.titlesDescriptionFontSize}px`,
    lineHeight: '142%',
    fontWeight: theme.titlesDescriptionWeight,
    color: theme.titlesDescriptionColor,
    fontFamily: theme.typographySupportingFontFamily,
    boxSizing: 'border-box',
    margin: '15px 0 0 0',
    wordBreak: 'break-word',
    'p:first-of-type': {
      marginTop: 0
    },
    'p:last-of-type': {
      marginBottom: 0
    }
  }), [theme.titlesDescriptionColor, theme.titlesDescriptionFontSize, theme.titlesDescriptionWeight, theme.titlesTextAlignment, theme.typographySupportingFontFamily]);

  return <>
    <div css={mainStyle}>
      <div css={contentStyle} onClick={() => onClick(field._id)}>
        <div css={titleStyle}>{getFieldLabel(field.label, values, 'jsx', fieldsOptions, variables, urlParams)}</div>
        {field.description && <div css={descriptionStyle}>{getFieldLabel(field.description, values, 'jsx', fieldsOptions, variables, urlParams)}</div>}
      </div>
    </div>
  </>;
};

export default Title;
