/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import React, { useEffect, useMemo } from 'react';

import { ReactComponent as LinkArrowIcon } from 'assets/images/link-arrow.svg';
import { ReactComponent as PausedIcon } from 'assets/images/paused.svg';

const FormFallback = React.memo(
  ({
    className,
    title = 'Submissions Paused.',
    description = 'Unfortunately this form is not available at the moment.',
    hideBranding = false
  }) => {
    const styles = {
      container: css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
        background: '#F6F5F4'
      }),
      fallback: css({
        margin: '30px',
        minWidth: '0px',
        maxWidth: '410px',
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
      }),
      fallbackMessage: css({
        backgroundColor: '#FFFFFF',
        borderRadius: '16px',
        boxShadow: '0 25px 50px 0 rgba(0, 0, 0, 0.1)',
        width: '100%',
        padding: '30px 20px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        div: {
          color: '#202020',
          fontFamily: 'Roboto',
          fontSize: '24px',
          fontWeight: '700',
          lineHeight: '29px',
          textAlign: 'center',
          margin: '15px 0 0 0'
        },
        span: {
          color: '#202020',
          fontFamily: 'Roboto',
          fontSize: '15px',
          fontWeight: '400',
          lineHeight: '18px',
          textAlign: 'center',
          display: 'block',
          marginTop: '25px',
          width: '100%'
        }
      }),
      submittedLink: css({
        backgroundColor: '#FFFFFF',
        borderRadius: '16px',
        boxShadow: '0 25px 50px 0 rgba(0, 0, 0, 0.1)',
        width: '100%',
        padding: '15px 20px',
        boxSizing: 'border-box',
        marginTop: '20px',
        span: {
          color: '#949494',
          fontFamily: 'Roboto',
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '17px',
          textAlign: 'center',
          display: 'block'
        },
        a: {
          display: 'block',
          color: '#0064F6',
          fontFamily: 'Roboto',
          fontSize: '13px',
          fontWeight: '500',
          lineHeight: '16px',
          textAlign: 'center',
          marginTop: '5px',
          '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',
          '-webkit-tap-highlight-color': 'transparent'
        },
        'a svg': {
          marginLeft: '2px'
        }
      })
    };

    // Hide loader
    useEffect(() => {
      if (typeof window.document === 'undefined') return;

      window.document.getElementById('spinnerServerContainer').remove();
      window.document.body.classList.remove('unscrollable');

      setTimeout(() => {
        const preloads = window.document.getElementById('preloads');
        if (preloads) preloads.remove();
      }, 1000);
    }, []);

    return (
      <div css={styles.container}>
        <section className={className} css={styles.fallback}>
          <div css={styles.fallbackMessage}>
            <PausedIcon />
            <div>{title}</div>
            <span>{description}</span>
          </div>

          {!hideBranding && (
            <div css={styles.submittedLink}>
              <span>This form was built using QuestionScout</span>
              <a href="https://questionscout.com?ref=questionscout-form" target="_blank" rel="noopener noreferrer">
                Start building your own form now <LinkArrowIcon />
              </a>
            </div>
          )}
        </section>
      </div>
    );
  }
);

export default FormFallback;
