import isJsonString from 'helpers/isJsonString.js';
import { Parser } from 'expr-eval';

const deserialize = (string) => {
  return [
    {
      children: [{ text: string }]
    }
  ];
};

const serialize = (node, variables, urlParams) => {
  if (node.text) return node.text;

  const children = (node.children || []).map((n) => serialize(n, variables, urlParams));

  switch (node.type) {
    case 'mention':
      const value = (
        variables.find((variable) => variable._id === node._id) ||
        urlParams.find((urlParam) => urlParam.key === node.key) || { value: 0 }
      ).value;

      return !isNaN(value) ? value : 0;
    default:
      return children.join('');
  }
};

export default function executeModifier(variables, urlParams = {}) {
  const variablesCopy = [...variables];
  let nodes, result;

  const urlParamsArray = Object.keys(urlParams).map((key) => ({
    key,
    value: !isNaN(urlParams[key]) ? parseFloat(urlParams[key]) : 0
  }));

  for (let variable of variablesCopy) {
    try {
      if (typeof variable.modifier === 'string') variable.modifier = variable.modifier.trim();

      if (variable.modifier.indexOf(variable._id) === -1) continue;

      nodes = isJsonString(variable.modifier) ? JSON.parse(variable.modifier) : deserialize(variable.modifier || '');

      if (!nodes || !Array.isArray(nodes) || typeof nodes === 'string' || nodes.length === 0) continue;

      result = Parser.evaluate(nodes.map((n) => serialize(n, variables, urlParamsArray || [])).join(''));
    } catch (e) {
      result = variable.value;
    }

    variable.value = !isNaN(result) ? result : variable.value;
  }

  return variablesCopy;
}
