/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import Color from 'color';
import React, { useMemo } from 'react';

const alignOptions = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

const Divider = ({ form, field, theme, fieldsOptions, variables, urlParams, onClick }) => {
  if (!onClick) onClick = () => { };

  const colorArr = Color(theme.dividerColor).color;

  const styleOptions = useMemo(() => ({
    solid: {
      borderBottom: `${theme.dividerHeight}px solid ${theme.dividerColor}`
    },
    dotted: {
      borderBottom: `${theme.dividerHeight}px dotted ${theme.dividerColor}`
    },
    dashed: {
      borderBottom: `${theme.dividerHeight}px dashed ${theme.dividerColor}`
    },
    double: {
      borderBottom: `${theme.dividerHeight}px double ${theme.dividerColor}`
    },
    shadow: {
      height: theme.dividerHeight,
      backgroundImage: `linear-gradient(to right, rgba(${colorArr[0]}, ${colorArr[1]}, ${colorArr[2]}, 0), rgba(${colorArr[0]}, ${colorArr[1]}, ${colorArr[2]}, 0.75), rgba(${colorArr[0]}, ${colorArr[1]}, ${colorArr[2]}, 0))`
    }
  }), [colorArr, theme.dividerColor, theme.dividerHeight]);

  const mainStyle = useMemo(() => css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: alignOptions[theme.dividerAlignment],
    margin: `${theme.dividerTopMargin || 0}px 0 ${theme.dividerBottomMargin || 0}px 0`
  }), [theme.dividerAlignment, theme.dividerBottomMargin, theme.dividerTopMargin]);

  const hrStyle = useMemo(() => ({
    width: '100%',
    maxWidth: `${theme.dividerWidth}${theme.dividerWidthType}`,
    boxSize: 'border-box',
    ...styleOptions[theme.dividerStyle]
  }), [styleOptions, theme.dividerStyle, theme.dividerWidth, theme.dividerWidthType]);

  return <>
    <div css={mainStyle} onClick={() => onClick(field._id)}>
      <div css={hrStyle} />
    </div>
  </>;
};

export default Divider;
