/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import { useMemo } from 'react';
import Translate from '../messages/Translate.js';

import { ReactComponent as WarningIcon } from 'assets/images/warning.svg';

const FALLBACK_ERROR = 'An error occurred, please try again later.';

const SubmitError = ({ children: submitError, messages, onClick }) => {
  const translate = useMemo(() => new Translate(messages), [messages]);

  const styles = {
    main: css({
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 2048,
      position: 'fixed',
      backgroundColor: 'rgba(0, 0, 0, 0.7)'
    }),
    message: css({
      width: '420px',
      backgroundColor: '#FFFFFF',
      borderRadius: '16px',
      boxShadow: '0 25px 50px 0 rgba(0, 0, 0, 0.1)',
      padding: '30px 20px',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#202020',
      fontFamily: 'Roboto',
      fontSize: '15px',
      fontWeight: '400',
      lineHeight: '18px',
      textAlign: 'center',
      display: 'block',
      marginTop: '25px'
    })
  };

  const message = useMemo(() => {
    try {
      const { messageId, error } = JSON.parse(submitError);
      
      if (messageId) {
        return translate.text(messageId);
      } else if (error) {
        return error;
      } else {
        return null;
      }
    } catch (e) {
      console.error(e);

      if (typeof submitError === 'string') {
        return submitError;
      } else {
        return null;
      }
    }
  }, [submitError]);

  return (
    <section css={styles.main} className="qs_error" onClick={onClick}>
      <div css={styles.message}>
        <WarningIcon />
        <p>{message || FALLBACK_ERROR}</p>
      </div>
    </section>
  );
};

export default SubmitError;
