import 'polyfills.js';

import React from 'react';
import Form from './components/Form.js';
import { useDispatch } from 'react-redux';
import getAllUrlParams from 'helpers/getAllUrlParams.js';

import { populateForm, setSeed, setGhostMode, disableWelcomePage, populateSubmission, setUrlParams } from 'store/ducks/form.js';

import FormFallback from './FormFallback';

const getParameterByName = (name, url) => {
  if (!url) url = typeof window !== 'undefined' ? window.location.href : '';

  name = name.replace(/[\[\]]/g, '\\$&');
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);

  if (!results) return null;
  if (!results[2]) return '';

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const App = ({ form, theme, submission, seed }) => {
  // If user plan has expired
  if (!form?.owner?.billing?.plan) {
    return (
      <FormFallback
        description="Unfortunately you can’t fill out this form. Reach out to the owner of this form to resolve the issue."
        hideBranding={false}
      />
    );
  }

  const dispatch = useDispatch();
  const urlParams = typeof window !== 'undefined' ? getAllUrlParams(window.location.href || '') : {};

  if (Object.keys(urlParams).length > 0) {
    dispatch(setUrlParams(urlParams));
  }

  if (getParameterByName('qs__ghost')) {
    dispatch(setGhostMode());
  }

  if (getParameterByName('qs__hidewelcome') === 'true') {
    dispatch(disableWelcomePage());
  }

  dispatch(setSeed(seed));
  dispatch(populateForm(form));

  if (submission) dispatch(populateSubmission(submission));

  return (
    <>
      <Form theme={theme} />
    </>
  );
};

export default App;
