import io from 'socket.io-client';
import createSocketIoMiddleware from 'redux-socket.io';
import config from '../config.js';

let connected = false;
let inited = false;
let socket;
let initedMiddleware;

const setupSocketIoMiddleware = store => next => action => {
  const state = store.getState();
  const formIsReady = state.form.ready;
  const fingerprint = state.form.fingerprint;

  if (!inited && formIsReady && fingerprint) {
    inited = true;

    socket = io.connect(config.socketUrl, {
      query: `fingerprint=${fingerprint}`
    });

    initedMiddleware = createSocketIoMiddleware(socket, 'form-io/')(store);

    connected = true;

    return initedMiddleware(next)(action);
  }

  if (connected) {
    return initedMiddleware(next)(action);
  }

  return next(action);
}

export default setupSocketIoMiddleware;
