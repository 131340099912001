/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import React, { useState, useRef, useEffect } from 'react';
import getFieldLabel from 'helpers/getFieldLabel.js';

import { LabelAtom, DescriptionAtom } from './atoms.js';

const alignOptions = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

const DateTimeNative = ({
  form,
  field,
  theme,
  value,
  values,
  onChange,
  onPartialResponse,
  fieldsOptions,
  variables,
  urlParams,
  onClick,
  onAfterChange
}) => {
  if (!onClick) onClick = () => {};

  const valueObject = value.value;

  const stringTimeToObject = (value) => {
    if (!value) return {};

    let newValue = null;

    if (field.dateTimeHourMode === '12') {
      const [sHours, minutes] = value.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
      const period = +sHours < 12 ? 'am' : 'pm';
      const hours = +sHours % 12 || 12;

      newValue = { hour: hours, minute: +minutes, ampm: period };
    } else if (field.dateTimeHourMode === '24') {
      newValue = { hour: value.split[':'][0], minute: value.split[':'][1] };
    }

    return newValue;
  };

  const stringDateToObject = (value) => {
    if (!value) return {};

    const day = value.split('-')[2];
    const month = value.split('-')[1];
    const year = value.split('-')[0];

    return { day, month, year };
  };

  const inputContainerStyleTypes = {
    style1: {
      borderBottom: `${theme.inputFieldsIdleBorderSize}px solid ${theme.inputFieldsIdleBorderColor}`
    },
    style2: {
      border: 'none'
    },
    style3: {
      border: `${theme.inputFieldsIdleBorderSize}px solid ${theme.inputFieldsIdleBorderColor}`
    }
  };

  const mainStyle = css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: alignOptions[theme.inputFieldsDropdownsAlignment],
    margin: `${theme.inputFieldsDropdownsTopMargin || 0}px 0 ${theme.inputFieldsDropdownsBottomMargin || 0}px 0`
  });

  const contentStyle = css({
    width: '100%',
    maxWidth: `${theme.inputFieldsDropdownsWidth}${theme.inputFieldsDropdownsWidthType}`
  });

  const inputContainerStyle = css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'text',
    fontFamily: theme.typographyContentFontFamily,
    background: theme.inputFieldsIdleBackground,
    padding: `${theme.inputFieldsIdleVerticalPadding || 0}px ${theme.inputFieldsIdleHorizontalPadding || 0}px`,
    color: theme.inputFieldsIdleTextColor,
    fontWeight: theme.inputFieldsIdleTextWeight,
    fontSize: theme.inputFieldsIdleTextFontSize,
    borderRadius: `${theme.inputFieldsIdleRoundness}px`,
    boxShadow: theme.inputFieldsIdleShadow
      ? `${theme.inputFieldsIdleShadowOffsetX}px ${theme.inputFieldsIdleShadowOffsetY}px ${theme.inputFieldsIdleShadowBlur}px ${theme.inputFieldsIdleShadowSpread}px ${theme.inputFieldsIdleShadowColor}`
      : 'none',
    ...inputContainerStyleTypes[theme.inputFieldsDropdownsType],
    '& input': {
      padding: '0px',
      margin: '0px',
      width: '100%',
      color: theme.inputFieldsIdleTextColor,
      fontWeight: theme.inputFieldsIdleTextWeight,
      fontSize: theme.inputFieldsIdleTextFontSize,
      lineHeight: '142%'
    },
    '& input::-webkit-calendar-picker-indicator': {
      background: 'transparent',
      bottom: 0,
      color: 'transparent',
      cursor: 'pointer',
      height: 'auto',
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      width: 'auto'
    },
    '&:hover': (() => {
      if (!theme.inputFieldsHoverEnable) return {};

      const obj = {
        background: theme.inputFieldsHoverBackground,
        borderColor: theme.inputFieldsHoverBorderColor,
        color: theme.inputFieldsHoverTextColor,
        '& input': {
          color: theme.inputFieldsHoverTextColor
        },
        '& input::placeholder': {
          color: theme.inputFieldsHoverPlaceholderColor
        }
      };

      if (theme.inputFieldsHoverShadow) {
        obj.boxShadow = `${theme.inputFieldsHoverShadowOffsetX}px ${theme.inputFieldsHoverShadowOffsetY}px ${theme.inputFieldsHoverShadowBlur}px ${theme.inputFieldsHoverShadowSpread}px ${theme.inputFieldsHoverShadowColor}`;
      } else {
        obj.boxShadow = 'none';
      }

      return obj;
    })(),
    '&.isActive': (() => {
      if (!theme.inputFieldsActiveEnable) return {};

      const obj = {
        background: theme.inputFieldsActiveBackground,
        borderColor: theme.inputFieldsActiveBorderColor,
        color: theme.inputFieldsActiveTextColor,
        '& input': {
          color: theme.inputFieldsActiveTextColor
        },
        '& input::placeholder': {
          color: theme.inputFieldsActivePlaceholderColor
        }
      };

      if (theme.inputFieldsActiveShadow) {
        obj.boxShadow = `${theme.inputFieldsActiveShadowOffsetX}px ${theme.inputFieldsActiveShadowOffsetY}px ${theme.inputFieldsActiveShadowBlur}px ${theme.inputFieldsActiveShadowSpread}px ${theme.inputFieldsActiveShadowColor}`;
      } else {
        obj.boxShadow = 'none';
      }

      return obj;
    })()
  });

  const [active, setActive] = useState(false);

  // Time ->
  const handleTimeChange = (e) => {
    const newValue = stringTimeToObject(e.target.value);

    onChange({ [field._id]: JSON.stringify(newValue) }, true);
    if (onAfterChange) onAfterChange({ [field._id]: JSON.stringify(newValue) }, true);
  };

  const parseTimeValue = (value) => {
    if (!value) return '';

    let newValue = '';

    value = JSON.parse(value);

    if (field.dateTimeHourMode === '12') {
      let hour = String(value.hour);
      let minute = String(value.minute);

      if (value.hour === '12' || value.hour === '24') {
        hour = '00';
      }

      if (value.ampm === 'pm') {
        hour = String(parseInt(hour) + 12);
      }

      if (hour.length === 1) {
        hour = `0${hour}`;
      }

      if (minute.length === 1) {
        minute = `0${minute}`;
      }

      newValue = `${hour}:${minute}`;
    } else if (field.dateTimeHourMode === '24') {
      newValue = `${value.hour}:${value.minute}`;
    }

    return newValue;
  };
  // <- Time

  // Date ->
  const handleDateChange = (e) => {
    const newValue = stringDateToObject(e.target.value);

    onChange({ [field._id]: JSON.stringify(newValue) }, true);
    if (onAfterChange) onAfterChange({ [field._id]: JSON.stringify(newValue) }, true);
  };

  const parseDateValue = (value) => {
    if (!value) return '';

    value = JSON.parse(value);

    return `${value.year}-${value.month}-${value.day}`;
  };
  // <- Date

  // Date time ->
  const handleDateTimeChange = (e) => {
    const time = e.target.value.split('T')[1];
    const date = e.target.value.split('T')[0];

    const timeObject = stringTimeToObject(time);
    const dateObject = stringDateToObject(date);

    const newValue = { ...timeObject, ...dateObject };

    onChange({ [field._id]: JSON.stringify(newValue) }, true);
    if (onAfterChange) onAfterChange({ [field._id]: JSON.stringify(newValue) }, true);
  };

  const parseDateTimeValue = (value) => {
    if (!value) return '';

    return `${parseDateValue(value)}T${parseTimeValue(value)}`;
  };
  // <- Date time

  const blurFocusProps = {
    onFocus: () => {
      setActive(true);
    },
    onBlur: () => {
      setActive(false);
      onPartialResponse(field._id);
    }
  };

  return (
    <>
      <div css={mainStyle} onClick={() => onClick(field._id)}>
        <div css={contentStyle}>
          <LabelAtom required={field.required} error={field.error} theme={theme}>
            {getFieldLabel(field.label, values, 'jsx', fieldsOptions, variables, urlParams)}
          </LabelAtom>

          <div css={inputContainerStyle} className={active ? 'isActive' : ''}>
            {field.dateTimeMode === 'datetime' && (
              <input type="datetime-local" value={parseDateTimeValue(valueObject)} onChange={handleDateTimeChange} {...blurFocusProps} />
            )}
            {field.dateTimeMode === 'date' && (
              <input type="date" value={parseDateValue(valueObject)} onChange={handleDateChange} {...blurFocusProps} />
            )}
            {field.dateTimeMode === 'time' && (
              <input type="time" value={parseTimeValue(valueObject)} onChange={handleTimeChange} {...blurFocusProps} />
            )}
          </div>

          <DescriptionAtom theme={theme}>
            {getFieldLabel(field.description, values, 'jsx', fieldsOptions, variables, urlParams)}
          </DescriptionAtom>
        </div>
      </div>
    </>
  );
};

export default DateTimeNative;
