/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import React, { useMemo } from 'react';
import Translate from '../messages/Translate.js';

import { ReactComponent as KeyboardArrowTopIcon } from 'assets/images/keyboard-arrow-top.svg';

const ConversationalTopHelper = ({ theme, onPrevClick, messages, fieldIndex, isTouchDevice }) => {
  const translate = useMemo(() => new Translate(messages), [messages]);

  const styles = {
    main: css({}),
    backHint: css({
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      fontSize: '14px',
      maxHeight: '20px',
      color: theme.generalFieldsSettingsTextIdleSupportingColor,
      lineHeight: '1em',
      fontFamily: theme.typographySupportingFontFamily,
      margin: '0 0 20px 0',
      'div': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
      },
      'svg': {
        margin: '0 5px 0 0'
      },
      'svg path': {
        fill: theme.generalFieldsSettingsTextIdleSupportingColor
      },
      'svg rect': {
        stroke: theme.generalFieldsSettingsTextIdleSupportingColor
      },
    }),
  };

  return <>
    {fieldIndex !== 0 && <div css={styles.backHint} onClick={onPrevClick}>
      {translate.jsx('prevHint', {
        arrowTop: { value: <KeyboardArrowTopIcon /> }
      })}
    </div>}
  </>;
};

export default ConversationalTopHelper;
