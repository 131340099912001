import axios from 'axios';
import config from '../../config.js';
import { v4 as uuid } from 'uuid';
import Chance from 'chance';
import { addYears } from 'date-fns';
import cookie from './../../cookies.js';
import {isString as _isString} from 'lodash';

import isFieldEmpty from 'helpers/isFieldEmpty.js';
import checkFieldLogic from 'helpers/checkFieldLogic.js';
import checkRequired from 'helpers/checkRequired.js';
import isJsonString from 'helpers/isJsonString.js';
import getVariables from 'helpers/getVariables.js';
import getFieldLabel from 'helpers/getFieldLabel.js';

const redirectTo = (url, inIframe) => {
  if (!inIframe) window.location.href = url;
  if (inIframe) window.top.location.href = url;
};

// Constants
const IO_ANALYTICS_INIT = 'form-io/ANALYTICS_INIT';
const IO_ANALYTICS_INIT_SUCCESS = 'form-io/ANALYTICS_INIT_SUCCESS';
const IO_ANALYTICS_UPDATE = 'form-io/ANALYTICS_UPDATE';
const ANALYTICS_UPDATE = 'form/ANALYTICS_UPDATE';

const IO_SUBMIT_FORM = 'form-io/SUBMIT_FORM';

const IO_PARTIAL_RESPONSES_SEND = 'form-io/IO_PARTIAL_RESPONSES_SEND';
const IO_PARTIAL_RESPONSES_SEND_SUCCESS = 'form-io/IO_PARTIAL_RESPONSES_SEND_SUCCESS';

const UPDATE_PREVIOUS_LOGIC = 'form/UPDATE_PREVIOUS_LOGIC';

const UPDATE_SUBMITTED = 'form/UPDATE_SUBMITTED';
const UPDATE_SUBMISSIONS_LIMIT = 'form/UPDATE_SUBMISSIONS_LIMIT';

const UPDATE_SUBMISSION_ID = 'form/UPDATE_SUBMISSION_ID';

const UPDATE_BACKUP_EXIST = 'form/UPDATE_BACKUP_EXIST';

const UPDATE_PAGE = 'form/UPDATE_PAGE';
const POPULATE_FORM = 'form/POPULATE_FORM';
const POPULATE_SUBMISSION = 'form/POPULATE_SUBMISSION';

const UPDATE_VALUE = 'form/UPDATE_VALUE';
const SET_GHOST_MODE = 'form/SET_GHOST_MODE';
const SET_FINGERPRINT = 'form/SET_FINGERPRINT';

const UPDATE_SHOW_WELCOME_PAGE = 'form/UPDATE_SHOW_WELCOME_PAGE';
const DISABLE_WELCOME_PAGE = 'form/DISABLE_WELCOME_PAGE';

const SET_REQUIRED_ERROR = 'form/SET_REQUIRED_ERROR';
const REMOVE_REQUIRED_ERROR = 'form/REMOVE_REQUIRED_ERROR';

const GET_VISITOR_COUNTRY_REQUEST = 'form/GET_VISITOR_COUNTRY_REQUEST';
const GET_VISITOR_COUNTRY_SUCCESS = 'form/GET_VISITOR_COUNTRY_SUCCESS';
const GET_VISITOR_COUNTRY_FAILURE = 'form/GET_VISITOR_COUNTRY_FAILURE';

const GET_FORM_REQUEST = 'form/GET_FORM_FIELDS_REQUEST';
const GET_FORM_SUCCESS = 'form/GET_FORM_FIELDS_SUCCESS';
const GET_FORM_FAILURE = 'form/GET_FORM_FIELDS_FAILURE';

const SUBMIT_FORM_REQUEST = 'form/SUBMIT_FORM_REQUEST';
const SUBMIT_FORM_SUCCESS = 'form/SUBMIT_FORM_SUCCESS';
const SUBMIT_FORM_FAILURE = 'form/SUBMIT_FORM_FAILURE';

const FILES_UPLOAD_REQUEST = 'form/FILES_UPLOAD_REQUEST';
const FILES_UPLOAD_SUCCESS = 'form/FILES_UPLOAD_SUCCESS';
const FILES_UPLOAD_PROGRESS = 'form/FILES_UPLOAD_PROGRESS';
const FILES_UPLOAD_FAILURE = 'form/FILES_UPLOAD_FAILURE';
const FILES_UPLOAD_ADD_CANCEL = 'form/FILES_UPLOAD_ADD_CANCEL';
const PUSH_FILE = 'form/PUSH_FILE';

const DELETE_FILE_REQUEST = 'form/DELETE_FILE_REQUEST';
const DELETE_FILE_SUCCESS = 'form/DELETE_FILE_SUCCESS';
const DELETE_FILE_FAILURE = 'form/DELETE_FILE_FAILURE';

const CANCEL_FILE_UPLOAD = 'form/CANCEL_FILE_UPLOAD';

const SET_URL_PARAMS = 'form/SET_URL_PARAMS';
const SET_SEED = 'form/SET_SEED';

const SET_SCROLL_TO_FIELD = 'form/SET_SCROLL_TO_FIELD';

const TOGGLE_FILE_UPLOAD_HELP = 'form/TOGGLE_FILE_UPLOAD_HELP';
const UPDATE_FIELD_HIGHLIGHTED = 'form/UPDATE_FIELD_HIGHLIGHTED';

const HIGHLIGHT_FIELD = 'form/HIGHLIGHT_FIELD';
const UNHIGHLIGHT_FIELDS = 'form/UNHIGHLIGHT_FIELDS';

const CLEAR_SUBMIT_ERROR = 'form/CLEAR_SUBMIT_ERROR';

const storageReplacement = {
  getItem: () => null,
  setItem: () => { },
  removeItem: () => { }
};

let storage;

try {
  storage = typeof window !== 'undefined' && window.localStorage ? window.localStorage : storageReplacement;
} catch (e) {
  storage = storageReplacement;
}

const sortFields = (arr) => {
  return arr.sort((a, b) => {
    if (a.position < b.position) {
      return -1;
    }

    if (a.position > b.position) {
      return 1;
    }

    return 0;
  });
};

const parsePreFilledValue = (value, urlParams = []) => {
  const isString = _isString(value);
  const isSlateLikeString = isString && value.trim().indexOf('[{') === 0;

  if (isSlateLikeString) {
    const result = getFieldLabel(value, undefined, 'text', undefined, undefined, urlParams);
    return typeof result === 'string' ? result : value;
  } else {
    return value;
  }
};


// Initial State
let initialState = {
  ready: false,
  fingerprint: null,
  backupExist: false,
  waitingForSubmissionId: false,

  analyticsId: null,
  analytics: {
    completionPercentage: 0,
    submitted: false,
    bottleneck: null,
    submission: null
  },

  seed: null,

  urlParams: {},

  formId: null,
  error: false,

  disableSave: false,

  ghost: false, // do not save to DB

  name: null,
  submitText: null,
  submittedText: null,
  submitted: false,
  submissionsLimit: false,
  sending: false,
  submissionId: null,

  showWelcomePage: false,
  welcomePageDisabled: false,

  respondentLimits: null,
  respondentLimitsNumber: null,

  scrollToField: null,
  lastActiveField: null,

  calculationVariables: [],

  page: 1,

  fields: [],

  values: {},
  variables: [],

  files: [],

  previousLogic: [],

  country: {
    code: null,
    name: null
  },

  paymentsProvider: null,

  submitError: null,

  data: {
    startTime: Math.floor(Date.now() / 1000)
  }
};

if (typeof window !== 'undefined') {
  initialState.inIframe = window.self !== window.top;
  initialState.data.referrer = window.document.referrer;
  initialState.data.userAgent = window.navigator.userAgent;
} else {
  initialState.inIframe = false;
  initialState.data.referrer = '';
  initialState.data.userAgent = '';
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  if (action.type === UPDATE_PAGE) {
    state.page = action.payload.page;

    return { ...state };
  }

  if (action.type === SET_FINGERPRINT) {
    state.fingerprint = action.payload.value;

    return { ...state };
  }

  if (action.type === SET_GHOST_MODE) {
    state.ghost = true;

    return { ...state };
  }

  if (action.type === UPDATE_BACKUP_EXIST) {
    state.backupExist = action.payload.value;

    return { ...state };
  }

  if (action.type === GET_FORM_REQUEST) {
    return { ...state };
  }

  if (action.type === POPULATE_SUBMISSION) {
    state.calculationVariables = [ ...action.payload.calculationVariables ];
    state.values = {};

    state.fields.map((field) => {
      const valueObj = action.payload.values.find((value) => String(value.field) === String(field._id));

      field.value = valueObj ? valueObj.value || null : null;

      state.values[field._id] = {
        value: field.value || null,
        type: field.type
      };

      return field;
    });

    state.submitted = true;
    state.sending = false;
    state.variables = getVariables(state.calculationVariables, state.urlParams, state.fields, state.values);

    Object.keys(state.values).map((key) => {
      const selectedField = state.fields.find((f) => f._id === key) || {};

      state.values[key].position = selectedField.position;
      state.values[key].hidden = selectedField.hidden;
      state.values[key].section = selectedField.section;
      state.values[key].isLast = false;
      state.values[key].isFirst = false;

      if (selectedField.type === 'pageBreak') {
        state.values[key].visible = true;
      } else {
        state.values[key].visible = checkFieldLogic(selectedField, state.values, state.fields, state.variables);
      }
    });

    for (const [key, obj] of Object.entries(state.values).sort((a, b) => {
      if (a[1].position > b[1].position) return -1;
      if (a[1].position < b[1].position) return 1;

      return 0;
    })) {
      if (obj.visible && !obj.hidden && (obj.section === 'root' || !obj.section)) {
        state.values[key].isLast = true;

        break;
      }
    }

    for (const [key, obj] of Object.entries(state.values).sort((a, b) => {
      if (a[1].position < b[1].position) return -1;
      if (a[1].position > b[1].position) return 1;

      return 0;
    })) {
      if (obj.visible && !obj.hidden && (obj.section === 'root' || !obj.section)) {
        state.values[key].isFirst = true;

        break;
      }
    }

    state.variables = getVariables(state.calculationVariables, state.urlParams, state.fields, state.values);

    state.values = { ...state.values };
    state.fields = [ ...state.fields ];
    state.variables = [ ...state.variables ];

    return { ...state };
  }

  if (action.type === GET_FORM_SUCCESS || action.type === POPULATE_FORM) {
    state.formId = action.payload._id;
    state.name = action.payload.name;
    state.submittedText = action.payload.submittedText;
    state.pages = action.payload.type === 'classic' ? action.payload.pages : 1;
    state.status = action.payload.status;
    state.reCaptcha = action.payload.reCaptcha;
    state.align = action.payload.align;
    state.owner = action.payload.owner;
    state.type = action.payload.type;
    state.welcomePage = action.payload.welcomePage;
    state.progressBar = action.payload.progressBar;
    state.messages = action.payload.messages || {};
    state.partialResponses = action.payload.partialResponses || false;
    state.submitted = state.ghost ? false : (action.payload.submitted || false);
    state.submissionsLimit = action.payload.submitted || false;
    state.respondentLimits = action.payload.respondentLimits;
    state.thankYouPages = action.payload.thankYouPages;
    state.disableSave = action.payload.disableSave || false;
    state.resumeOnReturn = action.payload.resumeOnReturn || false;
    state.defaultThankYouPage = action.payload.defaultThankYouPage;
    state.paymentsProvider = (() => { 
      if (!action.payload.payments || !action.payload.payments.enabled) return null;

      if (action.payload.integrations.paypal && action.payload.integrations.paypal.enabled) return 'paypal';
      if (action.payload.integrations.stripe && action.payload.integrations.stripe.enabled) return 'stripe';
      if (action.payload.integrations.mollie && action.payload.integrations.mollie.enabled) return 'mollie';
      if (action.payload.integrations.square && action.payload.integrations.square.enabled) return 'square';

      return null;
    })();
    state.calculationVariables = action.payload.calculationVariables;
    state.respondentLimitsNumber = action.payload.respondentLimitsNumber === null ? 1 : action.payload.respondentLimitsNumber;
    state.ready = true;

    if (action.payload.welcomePage && action.payload.welcomePage.enabled && !state.welcomePageDisabled) {
      state.showWelcomePage = true;
    }

    if (action.payload.type === 'conversational') {
      state.fields = sortFields(action.payload.fields.filter((field) => ['divider', 'pageBreak'].indexOf(field.type) === -1));
    } else {
      state.fields = sortFields(action.payload.fields);
    }

    state.fields.map((field) => {
      let chance;

      state.values[field._id] = {
        value: parsePreFilledValue(field.value, state.urlParams) ?? null,
        type: field.type
      };

      if ((field.type === 'checkbox' || field.type === 'imageChoice') && field.selectionLimits && field.selectionLimitsMin > field.selectionLimitsMax) {
        field.selectionLimits = false;
      }

      if (field.randomizeOptions && state.seed) {
        chance = new Chance(`${state.seed}${field._id}`);
        field.options = chance.shuffle(field.options);
      }

      return field;
    });

    state.variables = getVariables(state.calculationVariables, state.urlParams, state.fields, state.values);

    Object.keys(state.values).map((key) => {
      const selectedField = state.fields.find((f) => f._id === key) || {};

      state.values[key].position = selectedField.position;
      state.values[key].hidden = selectedField.hidden;
      state.values[key].section = selectedField.section;
      state.values[key].isLast = false;
      state.values[key].isFirst = false;

      if (selectedField.type === 'pageBreak') {
        state.values[key].visible = true;
      } else {
        state.values[key].visible = checkFieldLogic(selectedField, state.values, state.fields, state.variables);
      }
    });

    for (const [key, obj] of Object.entries(state.values).sort((a, b) => {
      if (a[1].position > b[1].position) return -1;
      if (a[1].position < b[1].position) return 1;

      return 0;
    })) {
      if (obj.visible && !obj.hidden && (obj.section === 'root' || !obj.section)) {
        state.values[key].isLast = true;

        break;
      }
    }

    for (const [key, obj] of Object.entries(state.values).sort((a, b) => {
      if (a[1].position < b[1].position) return -1;
      if (a[1].position > b[1].position) return 1;

      return 0;
    })) {
      if (obj.visible && !obj.hidden && (obj.section === 'root' || !obj.section)) {
        state.values[key].isFirst = true;

        break;
      }
    }

    state.variables = getVariables(state.calculationVariables, state.urlParams, state.fields, state.values);

    return { ...state };
  }

  if (action.type === GET_FORM_FAILURE) {
    state.error = true;
    state.fields = [];

    return { ...state };
  }

  if (action.type === UPDATE_VALUE) {
    const valuesIndex = Object.keys(action.payload.valueObject)[0];
    const fieldIndex = state.fields.findIndex((field) => field._id === valuesIndex);

    if (fieldIndex === -1) return { ...state };

    state.lastActiveField = state.fields[fieldIndex]._id;

    state.values[valuesIndex].value = action.payload.valueObject[valuesIndex];

    if (state.fields[fieldIndex].error === true && checkRequired(state.page, state.fields, state.values, {
      ignorePages: state.type === 'conversational'
    }).toRemove.indexOf(state.fields[fieldIndex]._id) !== -1) {
      state.fields[fieldIndex].error = false;
    }

    if (typeof action.payload.valid === 'boolean') {
      state.values[valuesIndex].valid = action.payload.valid;
    }

    state.variables = getVariables(state.calculationVariables, state.urlParams, state.fields, state.values);

    Object.keys(state.values).map((key) => {
      const selectedField = state.fields.find((f) => f._id === key) || {};

      state.values[key].position = selectedField.position;
      state.values[key].hidden = selectedField.hidden;
      state.values[key].section = selectedField.section;
      state.values[key].isLast = false;
      state.values[key].isFirst = false;

      if (selectedField.type === 'pageBreak') {
        state.values[key].visible = true;
      } else {
        state.values[key].visible = checkFieldLogic(selectedField, state.values, state.fields, state.variables);
      }
    });

    for (const [key, obj] of Object.entries(state.values).sort((a, b) => {
      if (a[1].position > b[1].position) return -1;
      if (a[1].position < b[1].position) return 1;

      return 0;
    })) {
      if (obj.visible && !obj.hidden && (obj.section === 'root' || !obj.section)) {
        state.values[key].isLast = true;

        break;
      }
    }

    for (const [key, obj] of Object.entries(state.values).sort((a, b) => {
      if (a[1].position < b[1].position) return -1;
      if (a[1].position > b[1].position) return 1;

      return 0;
    })) {
      if (obj.visible && !obj.hidden && (obj.section === 'root' || !obj.section)) {
        state.values[key].isFirst = true;

        break;
      }
    }

    state.variables = getVariables(state.calculationVariables, state.urlParams, state.fields, state.values);

    state.values = { ...state.values };
    state.fields = [...state.fields];
    state.variables = [...state.variables];

    return { ...state };
  }

  if (action.type === SUBMIT_FORM_REQUEST) {
    state.submitted = false;
    state.sending = true;
    state.submitError = null;

    return { ...state };
  }

  if (action.type === SUBMIT_FORM_SUCCESS) {
    state.submissionId = action.payload.id;

    if (action.payload.provider === 'paypal' && action.payload.redirect) {
      redirectTo(action.payload.redirect, state.inIframe);
    } else if (action.payload.provider === 'stripe' && action.payload.key && action.payload.session) {
      Stripe(action.payload.key).redirectToCheckout({ sessionId: action.payload.session });
    } else if (action.payload.provider === 'mollie' && action.payload.redirect) {
      redirectTo(action.payload.redirect, state.inIframe);
    } else if (action.payload.provider === 'square' && action.payload.redirect) {
      redirectTo(action.payload.redirect, state.inIframe);
    } else {
      state.submitted = true;
      state.sending = false;
    }
    
    return { ...state };
  }

  if (action.type === CLEAR_SUBMIT_ERROR) {
    state.submitError = null;

    return { ...state };
  }

  if (action.type === SUBMIT_FORM_FAILURE) {
    state.submitted = false;
    state.sending = false;
    state.submitError = action.payload.message;

    return { ...state };
  }

  if (action.type === SET_REQUIRED_ERROR) {
    state.fields.find((field) => field._id === action.payload.id).error = true;

    state.fields = [...state.fields];

    return { ...state };
  }

  if (action.type === REMOVE_REQUIRED_ERROR) {
    state.fields.find((field) => field._id === action.payload.id).error = false;

    state.fields = [...state.fields];

    return { ...state };
  }

  if (action.type === DELETE_FILE_REQUEST) {
    state.files.splice(state.files.findIndex((file) => file._id === action.payload.fileId), 1);

    state.files = [...state.files];

    return { ...state };
  }

  if (action.type === PUSH_FILE) {
    state.files = [...state.files, action.payload.file];

    return { ...state };
  }

  if (action.type === FILES_UPLOAD_REQUEST) {
    state.files = [...state.files, action.payload.file];

    return { ...state };
  }

  if (action.type === FILES_UPLOAD_PROGRESS) {
    state.files.find((file) => file.ref === action.payload.ref).loaded = action.payload.percentage;

    state.files = [...state.files];

    return { ...state };
  }

  if (action.type === FILES_UPLOAD_SUCCESS) {
    state.files.find((file) => file.ref === action.payload.ref).loaded = true;
    state.files.find((file) => file.ref === action.payload.ref)._id = action.payload.data.fileId;
    state.files.find((file) => file.ref === action.payload.ref).cancel = undefined;

    state.files = [...state.files];

    return { ...state };
  }

  if (action.type === FILES_UPLOAD_FAILURE) {
    state.files.find((file) => file.ref === action.payload.ref).cancel = undefined;

    state.files = [...state.files];

    return { ...state };
  }

  if (action.type === FILES_UPLOAD_ADD_CANCEL) {
    state.files.find((file) => file.ref === action.payload.ref).cancel = action.payload.cancel;

    state.files = [...state.files];

    return { ...state };
  }

  if (action.type === CANCEL_FILE_UPLOAD) {
    state.files.find((file) => file.ref === action.payload.ref).cancel();
    state.files.splice(state.files.findIndex((file) => file.ref === action.payload.ref), 1);

    state.files = [...state.files];

    return { ...state };
  }

  if (action.type === TOGGLE_FILE_UPLOAD_HELP) {
    state.fields.find((field) => field._id === action.payload.id).showHelp = action.payload.value;

    state.fields = [...state.fields];

    return { ...state };
  }

  if (action.type === IO_ANALYTICS_INIT_SUCCESS) {
    state.analyticsId = action.payload.id;

    return { ...state };
  }

  if (action.type === ANALYTICS_UPDATE) {
    state.analytics = { ...action.payload };

    return { ...state };
  }

  if (action.type === SET_URL_PARAMS) {
    state.urlParams = action.payload.params;

    return { ...state };
  }

  if (action.type === UPDATE_SHOW_WELCOME_PAGE) {
    state.showWelcomePage = state.welcomePageDisabled ? false : action.payload.value;

    return { ...state };
  }

  if (action.type === DISABLE_WELCOME_PAGE) {
    state.welcomePageDisabled = true;

    return { ...state };
  }

  if (action.type === SET_SEED) {
    state.seed = action.payload.seed;

    return { ...state };
  }

  if (action.type === UPDATE_SUBMITTED) {
    state.submitted = action.payload.value;

    return { ...state };
  }

  if (action.type === UPDATE_SUBMISSION_ID) {
    state.submissionId = action.payload.value;

    return { ...state };
  }

  if (action.type === UPDATE_SUBMISSIONS_LIMIT) {
    state.submissionsLimit = action.payload.value;

    return { ...state };
  }

  if (action.type === HIGHLIGHT_FIELD) {
    state.fields.filter((field) => field._id !== action.payload.id).map((field) => {
      field.opacity = 0.2;
    });

    state.fields = [...state.fields];

    return { ...state };
  }

  if (action.type === UNHIGHLIGHT_FIELDS) {
    state.fields.map((field) => {
      field.opacity = 1;
    });

    state.fields = [...state.fields];

    return { ...state };
  }

  if (action.type === UPDATE_FIELD_HIGHLIGHTED) {
    state.fields.find((field) => field._id === action.payload.id).highlighted = action.payload.index;

    state.fields = [...state.fields];

    return { ...state };
  }

  if (action.type === IO_PARTIAL_RESPONSES_SEND) {
    if (action.payload.first) state.waitingForSubmissionId = true;

    return { ...state };
  }

  if (action.type === IO_PARTIAL_RESPONSES_SEND_SUCCESS) {
    state.waitingForSubmissionId = false;

    if (action.payload.id && state.submissionId !== action.payload.id) {
      state.submissionId = action.payload.id;

      storage.setItem(`qs-form-submissionId-${state.formId}`, state.submissionId);
    }

    return { ...state };
  }

  if (action.type === SET_SCROLL_TO_FIELD) {
    state.scrollToField = action.payload.value;

    return { ...state };
  }

  if (action.type === UPDATE_PREVIOUS_LOGIC) {
    state.previousLogic = [...action.payload];

    return { ...state };
  }

  if (action.type === GET_VISITOR_COUNTRY_SUCCESS) {
    state.country = {
      code: action.payload.country_code,
      name: action.payload.country_name
    };

    return { ...state };
  }

  return state;
}

// Action Creators
export function highlightField(id, time) {
  return (dispatch) => {
    dispatch({ type: HIGHLIGHT_FIELD, payload: { id } });

    setTimeout(() => dispatch({ type: UNHIGHLIGHT_FIELDS }), time);
  };
}

export function clearSubmitError() {
  return (dispatch) => {
    dispatch({ type: CLEAR_SUBMIT_ERROR });
  };
}

export function updatePage(page) {
  return (dispatch) => {
    dispatch({ type: UPDATE_PAGE, payload: { page } });
  };
}

export function updatePreviousLogic(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_PREVIOUS_LOGIC, payload: value });
  };
}

export function updateSubmitted(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_SUBMITTED, payload: { value } });
  };
}

export function updateSubmissionId(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_SUBMISSION_ID, payload: { value } });
  };
}

export function updateSubmissionsLimit(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_SUBMISSIONS_LIMIT, payload: { value } });
  };
}

export function setScrollToField(value) {
  return (dispatch) => {
    dispatch({ type: SET_SCROLL_TO_FIELD, payload: { value } });
  };
}

export function populateForm(data) {
  return (dispatch) => {
    dispatch({ type: POPULATE_FORM, payload: data });
  };
}

export function populateSubmission(data) {
  return (dispatch) => {
    dispatch({ type: POPULATE_SUBMISSION, payload: data });
  };
}

export function setUrlParams(params) {
  return (dispatch) => {
    dispatch({ type: SET_URL_PARAMS, payload: { params } });
  };
}

export function setRequiredError(id) {
  return (dispatch) => {
    dispatch({ type: SET_REQUIRED_ERROR, payload: { id } });
  };
}

export function setSeed(seed) {
  return (dispatch) => {
    dispatch({ type: SET_SEED, payload: { seed } });
  };
}

export function pushFile(file) {
  return (dispatch) => {
    dispatch({ type: PUSH_FILE, payload: { file } });
  };
}

export function removeRequiredError(id) {
  return (dispatch) => {
    dispatch({ type: REMOVE_REQUIRED_ERROR, payload: { id } });
  };
}

export function updateValue(valueObject, valid) {
  return (dispatch) => {
    dispatch({ type: UPDATE_VALUE, payload: { valueObject, valid } });

    return Promise.resolve();
  };
}

export function updateShowWelcomePage(value) {
  return (dispatch) => {
    return dispatch({ type: UPDATE_SHOW_WELCOME_PAGE, payload: { value } });
  };
}

export function disableWelcomePage() {
  return (dispatch) => {
    dispatch({ type: DISABLE_WELCOME_PAGE });
  };
}

export function setGhostMode() {
  return (dispatch) => {
    dispatch({ type: SET_GHOST_MODE });
  };
}

export function cancelUpload(ref) {
  return (dispatch) => {
    dispatch({ type: CANCEL_FILE_UPLOAD, payload: { ref } });
  };
}

export function toggleFileUploadHelp(id, value) {
  return (dispatch) => {
    dispatch({ type: TOGGLE_FILE_UPLOAD_HELP, payload: { id, value } });
  };
}

export function updateFieldHighlighted(id, index) {
  return (dispatch) => {
    dispatch({ type: UPDATE_FIELD_HIGHLIGHTED, payload: { id, index } });
  };
}

export function setFingerprint(value) {
  return (dispatch) => {
    dispatch({ type: SET_FINGERPRINT, payload: { value } });
  };
}

export function analyticsInit() {
  return (dispatch, getState) => {
    const state = getState();

    if (state.form.ghost || state.form.submissionsLimit) return;

    dispatch({
      type: IO_ANALYTICS_INIT, payload: {
        formId: state.form.formId
      }
    });
  };
}

export function analyticsUpdate() {
  return (dispatch, getState) => {
    const state = getState();

    if (state.form.ghost) return;

    const fieldsNumber = Object.entries(state.form.values)
      .map((arr) => arr[1])
      .filter((field) => field.visible).length;

    let filledFieldsNumber = 0;
    let field;
    let bottleneck = null;

    for (let [id, object] of Object.entries(state.form.values)) {
      field = state.form.fields.find((field) => field._id === id);

      if (typeof field === 'undefined') continue;
      if (['divider', 'title', 'description', 'image', 'pageBreak'].indexOf(field.type) !== -1) continue;

      if (!isFieldEmpty(object.value, object.type)) {
        filledFieldsNumber += 1;

        if (object.visible) {
          bottleneck = field._id; // this is not accurate enough. We need to rewrite bottleneck detection
        }
      }
    }

    const props = {
      completionPercentage: parseInt(filledFieldsNumber * 100 / fieldsNumber, 10),
      submitted: state.form.submitted,
      bottleneck: bottleneck,
      submission: state.form.submissionId
    };

    const shouldUpdate = JSON.stringify(props) !== JSON.stringify(state.form.analytics);

    if (shouldUpdate) {
      dispatch({
        type: ANALYTICS_UPDATE, payload: props
      });

      dispatch({
        type: IO_ANALYTICS_UPDATE, payload: {
          id: state.form.analyticsId,
          ...props
        }
      });
    }
  };
}

export function submitForm() {
  const requestIo = () => { return { type: IO_SUBMIT_FORM } };
  const request = () => { return { type: SUBMIT_FORM_REQUEST } };
  const success = (res) => { return { type: SUBMIT_FORM_SUCCESS, payload: { ...res } } };
  const failure = (message) => { return { type: SUBMIT_FORM_FAILURE, payload: { message } } };

  return async (dispatch, getState) => {
    dispatch(request());
    dispatch(requestIo());

    let reCaptchaToken = null;

    const state = getState();
    let storageSubmissionsCount = cookie.get(`qs-form-submissions-${state.form.formId}`) || '0';
    storageSubmissionsCount = parseInt(storageSubmissionsCount, 10);

    if (state.form.ghost) return dispatch(failure());

    if (state.form.reCaptcha && window.grecaptcha) {
      try {
        reCaptchaToken = await window.grecaptcha.execute(config.recaptchaSiteKey, {
          action: 'submit'
        });
      } catch (e) {}
    }

    const response = await services.submitForm(state.form.formId, {
      values: Object.entries(state.form.values).map(([id, value]) => {
        return { field: id, ...value };
      }),
      urlParams: state.form.urlParams,
      completionTime: Math.floor(Date.now() / 1000) - state.form.data.startTime,
      referrer: state.form.data.referrer,
      userAgent: state.form.data.userAgent,
      fingerprint: state.form.fingerprint,
      form: state.form.formId,
      submissionId: state.form.submissionId,
      calculationVariables: state.form.variables,
      domain: window.location.pathname.split('/')[1],
      reCaptchaToken
    });

    if (response.success) {
      storageSubmissionsCount += 1;

      storage.removeItem(`qs-form-backup-${state.form.formId}`);
      storage.removeItem(`qs-form-submissionId-${state.form.formId}`);

      cookie.set(`qs-form-submissions-${state.form.formId}`, storageSubmissionsCount, {
        expires: addYears(new Date(), 1),
        sameSite: 'lax'
      });

      if (storageSubmissionsCount >= state.form.respondentLimitsNumber) {
        dispatch(updateSubmissionsLimit(true));
      }

      dispatch(success(response.data));
      dispatch(analyticsUpdate());

      return Promise.resolve();
    } else {
      dispatch(failure(response.data));

      return Promise.resolve();
    }
  };
}

export function checkLimits() {
  return async (dispatch, getState) => {
    const state = getState();

    if (!state.form.respondentLimits) return Promise.resolve();

    const response = await services.checkLimits(state.form.formId, state.form.fingerprint);

    if (response) {
      cookie.set(`qs-form-submissions-${state.form.formId}`, response.submissions, {
        expires: addYears(new Date(), 1),
        sameSite: 'lax'
      });

      if (response.submissions >= state.form.respondentLimitsNumber) {
        dispatch(updateSubmitted(true));
        dispatch(updateSubmissionsLimit(true));

        return Promise.reject();
      } else {
        if (state.form.submitted && state.form.submissionsLimit) { // double check
          dispatch(updateSubmitted(false));
          dispatch(updateSubmissionsLimit(false));
        }

        return Promise.resolve();
      }
    } else {
      return Promise.resolve();
    }
  };
}

export function getFields() {
  const request = () => { return { type: GET_FORM_REQUEST } };
  const success = (form) => { return { type: GET_FORM_SUCCESS, payload: form } };
  const failure = () => { return { type: GET_FORM_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.getForm(state.form.formId, state.form.ghost);

    if (response) {
      dispatch(success(response));
    } else {
      dispatch(failure());
    }
  };
}

export function getVisitorCountry(ip) {
  const request = () => { return { type: GET_VISITOR_COUNTRY_REQUEST } };
  const success = (data) => { return { type: GET_VISITOR_COUNTRY_SUCCESS, payload: data } };
  const failure = () => { return { type: GET_VISITOR_COUNTRY_FAILURE } };

  return async (dispatch) => {
    dispatch(request());

    const response = await services.getVisitorCountry(ip);

    if (response) {
      dispatch(success(response));
    } else {
      dispatch(failure());
    }
  };
}

export function uploadFiles(files, fieldId) {
  const request = (file) => { return { type: FILES_UPLOAD_REQUEST, payload: { file } } };
  const success = (ref, data) => { return { type: FILES_UPLOAD_SUCCESS, payload: { ref, data } } };
  const progress = (ref, percentage) => { return { type: FILES_UPLOAD_PROGRESS, payload: { ref, percentage } } };
  const addCancel = (ref, cancel) => { return { type: FILES_UPLOAD_ADD_CANCEL, payload: { ref, cancel } } };
  const failure = (ref) => { return { type: FILES_UPLOAD_FAILURE, payload: { ref } } };

  return async (dispatch, getState) => {
    const uploadProgress = (ref) => (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percentage = Math.floor((loaded * 100) / total);

      dispatch(progress(ref, percentage));
    }

    const uploadSuccess = (ref) => (respose) => {
      const value = JSON.parse(getState().form.values[respose.data.fieldId].value);

      dispatch(success(ref, respose.data));

      value.push(respose.data.fileId);

      dispatch(updateValue({ [respose.data.fieldId]: JSON.stringify(value) }));
      dispatch(backupResponses());
      dispatch(analyticsUpdate());
    }

    const uploadFailure = (ref) => (respose) => {
      dispatch(failure(ref, respose.data));
    }

    const state = getState();

    let newFile = {};
    let data;
    let source;

    for (let file of files) {
      data = new FormData();
      source = axios.CancelToken.source();

      newFile = {
        ref: uuid(),
        size: file.size,
        loaded: 0,
        name: file.name,
        type: file.type,
        field: fieldId
      };

      data.append(newFile.ref, file);

      dispatch(request(newFile));
      dispatch(addCancel(newFile.ref, source.cancel));

      axios({
        method: 'PUT',
        url: `${config.apiUrl}/forms/${state.form.formId}/field/${fieldId}/file`,
        data,
        cancelToken: source.token,
        onUploadProgress: uploadProgress(newFile.ref)
      }).then(uploadSuccess(newFile.ref)).catch((thrown) => {
        if (!axios.isCancel(thrown)) {
          uploadFailure(newFile.ref);
        }
      });
    }
  };
}

export function deleteFile(fileId, fieldId) {
  const request = (fileId, fieldId) => { return { type: DELETE_FILE_REQUEST, payload: { fileId, fieldId } } };
  const success = () => { return { type: DELETE_FILE_SUCCESS } };
  const failure = () => { return { type: DELETE_FILE_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request(fileId, fieldId));

    const state = getState();
    const response = await services.deleteFile(state.form.formId, fileId, fieldId);

    if (response && state.form.values[fieldId]) {
      dispatch(success());

      const values = JSON.parse(state.form.values[fieldId].value);
      const index = values.indexOf(fileId);

      if (index !== -1) {
        values.splice(index, 1);
      }

      dispatch(updateValue({ [fieldId]: JSON.stringify(values) }));
      dispatch(analyticsUpdate());
    } else {
      dispatch(failure());
    }
  };
}

export function checkBackup() {
  const exist = () => { return { type: UPDATE_BACKUP_EXIST, payload: { value: true } } };

  return async (dispatch, getState) => {
    const state = getState();

    if (state.form.ghost || !state.form.resumeOnReturn) return;

    let backup, backupValues;
    const backupCookie = storage.getItem(`qs-form-backup-${state.form.formId}`);

    if (isJsonString(backupCookie)) {
      backup = typeof backupCookie === 'string' ? JSON.parse(backupCookie) : backupCookie;
      backupValues = backup.values ? backup.values : backup;

      try {
        if (JSON.stringify(backupValues) !== JSON.stringify(state.form.values)) {
          dispatch(exist());
        }
      } catch (e) { }
    }
  }
}

export function clearBackup() {
  const notExist = () => { return { type: UPDATE_BACKUP_EXIST, payload: { value: false } } };

  return async (dispatch, getState) => {
    const state = getState();

    let backup;
    const backupCookie = storage.getItem(`qs-form-backup-${state.form.formId}`);

    if (isJsonString(backupCookie)) {
      backup = typeof backupCookie === 'string' ? JSON.parse(backupCookie) : backupCookie;

      for (let [key, obj] of Object.entries(backup.values ? backup.values : backup)) {
        if (obj.type === 'fileUpload') {
          JSON.parse(obj.value).map((file) => dispatch(deleteFile(file, key)));
        }
      }
    }

    storage.removeItem(`qs-form-backup-${state.form.formId}`);
    storage.removeItem(`qs-form-submissionId-${state.form.formId}`);

    dispatch(notExist());

    return Promise.resolve();
  }
}

export function restoreBackup() {
  const notExist = () => { return { type: UPDATE_BACKUP_EXIST, payload: { value: false } } };
  let promises = [];

  return async (dispatch, getState) => {
    const state = getState();
    const backupCookie = storage.getItem(`qs-form-backup-${state.form.formId}`);
    const submissionIdCookie = storage.getItem(`qs-form-submissionId-${state.form.formId}`);

    if (submissionIdCookie) dispatch(updateSubmissionId(submissionIdCookie));

    if (!isJsonString(backupCookie)) {
      dispatch(notExist());

      return Promise.resolve();
    }

    const backup = typeof backupCookie === 'string' ? JSON.parse(backupCookie) : backupCookie;

    for (let [key, obj] of Object.entries(backup.values ? backup.values : backup)) {
      promises.push(dispatch(updateValue({ [key]: obj.value })));

      if (obj.type === 'fileUpload') {
        JSON.parse(obj.value).map((file) => {
          services.getFile({
            formId: state.form.formId,
            fieldId: key,
            fileId: file,
            formOwner: state.form.formOwner
          }).then((fileDetails) => {
            if (!fileDetails || !fileDetails._id) return;

            dispatch(pushFile({
              field: fileDetails.field,
              loaded: true,
              name: fileDetails.name,
              ref: uuid(),
              size: fileDetails.size,
              type: fileDetails.mimetype,
              _id: fileDetails._id
            }));
          });
        });
      }
    }

    dispatch(notExist());

    return Promise.all(promises).then(() => {
      dispatch(updateShowWelcomePage(false));

      if (Number.isInteger(backup.lastActivePage) && state.form.type === 'classic') dispatch(updatePage(backup.lastActivePage));
      if (backup.lastActiveField) dispatch(setScrollToField(backup.lastActiveField));

      return Promise.resolve();
    });
  };
}

export function backupResponses() {
  return async (dispatch, getState) => {
    const state = getState();

    storage.setItem(`qs-form-backup-${state.form.formId}`, JSON.stringify({
      values: state.form.values,
      lastActiveField: state.form.lastActiveField,
      lastActivePage: state.form.page
    }));
  };
}

export function sendPartialResponses(fieldId, first) {
  return async (dispatch, getState) => {
    const state = getState();

    dispatch({
      type: IO_PARTIAL_RESPONSES_SEND, payload: {
        key: fieldId,
        data: state.form.values[fieldId],
        submissionId: state.form.submissionId,
        referrer: state.form.data.referrer,
        formId: state.form.formId,
        urlParams: state.form.urlParams,
        fingerprint: state.form.fingerprint,
        first
      }
    });
  };
}

// Side effects
const services = {
  getForm: async (formId, ghost) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/forms/${formId}?ghost=${ghost}`,
        headers: {
          'Content-Type': 'application/json'
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  submitForm: async (formId, data) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/forms/${formId}/submissions`,
        headers: {
          'Content-Type': 'application/json'
        },
        data
      });

      return {
        success: true,
        data: response.data
      };
    } catch (e) {
      return {
        success: false,
        data: e.request.response
      };
    }
  },
  getFile: async ({ formId, fieldId, fileId, formOwner }) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/forms/${formId}/field/${fieldId}/file/${fileId}`,
        headers: {
          'Content-Type': 'application/json'
        },
        params: {
          formOwner
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  deleteFile: async (formId, fileId, fieldId) => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${config.apiUrl}/forms/${formId}/field/${fieldId}/file/${fileId}`,
        headers: {
          'Content-Type': 'application/json'
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  checkLimits: async (formId, fingerprint) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/forms/${formId}/limits/${fingerprint}`,
        headers: {
          'Content-Type': 'application/json'
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  getVisitorCountry: async (ip) => {
    const params = ip ? `/${ip}` : '';

    try {
      const response = await axios({
        method: 'GET',
        url: `https://geolocation-db.com/json/697de680-a737-11ea-9820-af05f4014d91${params}`,
        headers: {
          'Content-Type': 'application/json'
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  }
};
