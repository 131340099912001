import checkLogic from './checkLogic';

export default function checkFieldLogic(field, values, fields, variables = []) {
  if (!field) return;

  let conditions = field.conditions || [];
  let calculationConditions = field.calculationConditions || [];
  let conditionsType = field.conditionsType;
  let fieldType = field.type;
  let conditionsState = field.conditionsState;

  if (conditions.length + calculationConditions.length === 0) {
    return fieldType !== 'pageBreak' ? true : false; // no logic for this field
  }

  const result = checkLogic(conditions, conditionsType, calculationConditions, values, fields, variables);
  return result === (conditionsState !== 'hide');
}
