/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import React, { useMemo, useCallback } from 'react';
import getFieldLabel from 'helpers/getFieldLabel.js';
import isJsonString from 'helpers/isJsonString.js';

import { LabelAtom, DescriptionAtom } from './atoms.js';

const alignOptions = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

const Matrix = ({
  form,
  field,
  theme,
  value,
  values,
  onChange,
  onAfterChange,
  onPartialResponse,
  onNextClick,
  fieldsOptions,
  variables,
  urlParams,
  onClick
}) => {
  if (!onClick) onClick = () => {};

  value = useMemo(() => (isJsonString(value) ? JSON.parse(value) : []), [value]);

  const optionWidth = useMemo(() => `${field.additionalOptions.length > 0 ? 60 / field.additionalOptions.length : 60}%`, [field.additionalOptions.length]);

  const mainStyle = useMemo(
    () =>
      css({
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: alignOptions[theme.matrixAlignment],
        margin: `${theme.matrixTopMargin || 0}px 0 ${theme.matrixBottomMargin || 0}px 0`
      }),
    [theme.matrixAlignment, theme.matrixBottomMargin, theme.matrixTopMargin]
  );

  const contentStyle = useMemo(
    () =>
      css({
        width: '100%',
        maxWidth: `${theme.matrixWidth}${theme.matrixWidthType}`
      }),
    [theme.matrixWidth, theme.matrixWidthType]
  );

  const matrixStyle = useMemo(
    () =>
      css({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        overflowY: 'auto',
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: `${theme.matrixIdleRoundness}px`,
        boxShadow: theme.matrixIdleShadow
          ? `${theme.matrixIdleShadowOffsetX}px ${theme.matrixIdleShadowOffsetY}px ${theme.matrixIdleShadowBlur}px ${theme.matrixIdleShadowSpread}px ${theme.matrixIdleShadowColor}`
          : 'none',
        '&:hover': (() => {
          const obj = {};

          if (theme.matrixHoverShadow && theme.matrixHoverEnable) {
            obj.boxShadow = `${theme.matrixHoverShadowOffsetX}px ${theme.matrixHoverShadowOffsetY}px ${theme.matrixHoverShadowBlur}px ${theme.matrixHoverShadowSpread}px ${theme.matrixHoverShadowColor}`;
          } else {
            obj.boxShadow = 'none';
          }

          return obj;
        })(),
        '&:active': (() => {
          const obj = {};

          if (theme.matrixActiveShadow && theme.matrixActiveEnable) {
            obj.boxShadow = `${theme.matrixActiveShadowOffsetX}px ${theme.matrixActiveShadowOffsetY}px ${theme.matrixActiveShadowBlur}px ${theme.matrixActiveShadowSpread}px ${theme.matrixActiveShadowColor}`;
          } else {
            obj.boxShadow = 'none';
          }

          return obj;
        })()
      }),
    [
      theme.matrixActiveEnable,
      theme.matrixActiveShadow,
      theme.matrixActiveShadowBlur,
      theme.matrixActiveShadowColor,
      theme.matrixActiveShadowOffsetX,
      theme.matrixActiveShadowOffsetY,
      theme.matrixActiveShadowSpread,
      theme.matrixHoverEnable,
      theme.matrixHoverShadow,
      theme.matrixHoverShadowBlur,
      theme.matrixHoverShadowColor,
      theme.matrixHoverShadowOffsetX,
      theme.matrixHoverShadowOffsetY,
      theme.matrixHoverShadowSpread,
      theme.matrixIdleShadow,
      theme.matrixIdleShadowBlur,
      theme.matrixIdleShadowColor,
      theme.matrixIdleShadowOffsetX,
      theme.matrixIdleShadowOffsetY,
      theme.matrixIdleShadowSpread
    ]
  );

  const headStyle = useMemo(
    () =>
      css({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: '100%',
        boxSizing: 'border-box'
      }),
    []
  );

  const rowStyle = useMemo(
    () =>
      css({
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        boxSizing: 'border-box',
        '&:last-of-type > div:last-child': {
          borderRadius: `0 0 ${theme.matrixIdleRoundness}px 0`
        },
        '&:last-of-type > div:first-of-type': {
          borderRadius: `0 0 0 ${theme.matrixIdleRoundness}px`
        },
        '&:last-child': {
          borderBottom: `${theme.matrixIdleBorderSize}px solid ${theme.matrixIdleBorderColor}`,
          borderRadius: `0 0 ${theme.matrixIdleRoundness}px ${theme.matrixIdleRoundness}px`
        },

        '&:first-of-type': {
          borderRadius: `${theme.matrixIdleRoundness}px 0 0 0`
        },
        '&:first-of-type > div:first-of-type': {
          borderRadius: `${theme.matrixIdleRoundness}px 0 0 0`
        }
      }),
    [theme.matrixIdleBorderColor, theme.matrixIdleBorderSize, theme.matrixIdleRoundness]
  );

  const emptyLabel = useMemo(
    () =>
      css({
        width: '40%',
        minWidth: '40%',
        maxWidth: '40%',
        boxSizing: 'border-box',
        borderTop: `${theme.matrixIdleBorderSize}px solid ${theme.matrixIdleBorderColor}`,
        borderLeft: `${theme.matrixIdleBorderSize}px solid ${theme.matrixIdleBorderColor}`,
        background: theme.matrixIdleOptionBackground,
        borderRadius: `${theme.matrixIdleRoundness}px 0 0 0`
      }),
    [theme.matrixIdleBorderColor, theme.matrixIdleBorderSize, theme.matrixIdleOptionBackground, theme.matrixIdleRoundness]
  );

  const scaleLabel = useMemo(
    () =>
      css({
        width: optionWidth,
        minWidth: '70px',
        maxWidth: optionWidth,
        fontFamily: theme.typographyContentFontFamily,
        color: theme.matrixIdleLeftTextColor,
        fontWeight: theme.matrixIdleLeftTextWeight,
        fontSize: `${theme.matrixIdleLeftTextFontSize}px`,
        background: theme.matrixIdleLabelBackground,
        lineHeight: '142%',
        borderTop: `${theme.matrixIdleBorderSize}px solid ${theme.matrixIdleBorderColor}`,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderLeft: `${theme.matrixIdleBorderSize}px solid ${theme.matrixIdleBorderColor}`,
        padding: `${theme.matrixIdleVerticalPadding}px ${theme.matrixIdleHorizontalPadding}px`,
        wordBreak: 'break-word',
        '&:last-child': {
          borderRight: `${theme.matrixIdleBorderSize}px solid ${theme.matrixIdleBorderColor}`,
          borderRadius: `0 ${theme.matrixIdleRoundness}px 0 0`
        }
      }),
    [
      optionWidth,
      theme.matrixIdleBorderColor,
      theme.matrixIdleBorderSize,
      theme.matrixIdleLabelBackground,
      theme.matrixIdleLeftTextColor,
      theme.matrixIdleLeftTextFontSize,
      theme.matrixIdleLeftTextWeight,
      theme.matrixIdleRoundness,
      theme.typographyContentFontFamily,
      theme.matrixIdleVerticalPadding,
      theme.matrixIdleHorizontalPadding
    ]
  );

  const label = useMemo(
    () =>
      css({
        width: '40%',
        minWidth: '40%',
        fontFamily: theme.typographyContentFontFamily,
        color: theme.matrixIdleTopTextColor,
        fontWeight: theme.matrixIdleTopTextWeight,
        fontSize: theme.matrixIdleTopTextFontSize,
        background: theme.matrixIdleLabelBackground,
        lineHeight: '142%',
        borderTop: `${theme.matrixIdleBorderSize}px solid ${theme.matrixIdleBorderColor}`,
        borderLeft: `${theme.matrixIdleBorderSize}px solid ${theme.matrixIdleBorderColor}`,
        boxSizing: 'border-box',
        padding: `${theme.matrixIdleVerticalPadding}px ${theme.matrixIdleHorizontalPadding}px`
      }),
    [
      theme.matrixIdleBorderColor,
      theme.matrixIdleBorderSize,
      theme.matrixIdleLabelBackground,
      theme.matrixIdleTopTextColor,
      theme.matrixIdleTopTextFontSize,
      theme.matrixIdleTopTextWeight,
      theme.typographyContentFontFamily,
      theme.matrixIdleVerticalPadding,
      theme.matrixIdleHorizontalPadding
    ]
  );

  const input = useMemo(
    () =>
      css({
        width: optionWidth,
        minWidth: '70px',
        maxWidth: optionWidth,
        borderTop: `${theme.matrixIdleBorderSize}px solid ${theme.matrixIdleBorderColor}`,
        borderLeft: `${theme.matrixIdleBorderSize}px solid ${theme.matrixIdleBorderColor}`,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        background: theme.matrixIdleOptionBackground,
        '&:last-child': {
          borderRight: `${theme.matrixIdleBorderSize}px solid ${theme.matrixIdleBorderColor}`
        },
        '&:hover': (() => {
          if (!theme.matrixHoverEnable) return {};

          const obj = {
            background: theme.matrixHoverOptionBackground
          };

          obj['& > div'] = {
            borderColor: theme.matrixHoverInputColor
          };

          obj['& > div > div'] = {
            background: theme.matrixHoverInputColor
          };

          return obj;
        })(),
        '&:active, $.active': (() => {
          if (!theme.matrixActiveEnable) return {};

          const obj = {
            background: theme.matrixActiveOptionBackground,
            display: 'none'
          };

          obj['& > div'] = {
            borderColor: theme.matrixActiveInputColor
          };

          obj['& > div > div'] = {
            background: theme.matrixActiveInputColor
          };

          return obj;
        })()
      }),
    [
      optionWidth,
      theme.matrixActiveEnable,
      theme.matrixActiveInputColor,
      theme.matrixActiveOptionBackground,
      theme.matrixHoverEnable,
      theme.matrixHoverInputColor,
      theme.matrixHoverOptionBackground,
      theme.matrixIdleBorderColor,
      theme.matrixIdleBorderSize,
      theme.matrixIdleOptionBackground
    ]
  );

  const radio = useMemo(
    () =>
      css({
        width: `${theme.matrixIdleInputSize}px`,
        height: `${theme.matrixIdleInputSize}px`,
        borderRadius: Number(field.selectionLimitsMax || 0) === 1 ? '100%' : '10%',
        border: `1px solid ${theme.matrixIdleInputColor}`,
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
      }),
    [field.selectionLimitsMax, theme.matrixIdleInputColor, theme.matrixIdleInputSize]
  );

  const radioDot = useMemo(
    () =>
      css({
        width: `${theme.matrixIdleInputSize / 2}px`,
        height: `${theme.matrixIdleInputSize / 2}px`,
        borderRadius: Number(field.selectionLimitsMax || 0) === 1 ? '100%' : '10%',
        background: theme.matrixIdleInputColor,
        boxSizing: 'border-box'
      }),
    [field.selectionLimitsMax, theme.matrixIdleInputColor, theme.matrixIdleInputSize]
  );

  const handleOptionClick = useCallback(
    (optionId, additionalOptionId) => {
      if (field.readonly) return;

      const limit = Number(field.selectionLimitsMax || 0);
      let valueCopy = [...value];

      const optionIndex = value.findIndex((v) => v.indexOf(optionId) === 0);

      if (optionIndex === -1) {
        valueCopy.push([optionId, [additionalOptionId]]);
      } else {
        const additionalOptionIndex = valueCopy[optionIndex][1].findIndex((v) => v === additionalOptionId);

        if (additionalOptionIndex === -1 && valueCopy[optionIndex][1].length + 1 <= limit) {
          valueCopy[optionIndex][1].push(additionalOptionId);
        } else if (limit === 1) {
          // if it is radio
          valueCopy[optionIndex][1] = [additionalOptionId];
        } else if (additionalOptionIndex !== -1) {
          // if it is checkbox
          valueCopy[optionIndex][1].splice(additionalOptionIndex, 1);
        }

        // remove option if additonal options list is empty
        if (valueCopy[optionIndex][1].length === 0) {
          valueCopy.splice(optionIndex, 1);
        }
      }

      onChange({ [field._id]: JSON.stringify(valueCopy) });
      if (onAfterChange) onAfterChange({ [field._id]: JSON.stringify(valueCopy) });
      onPartialResponse(field._id);
    },
    [field, onChange, onAfterChange, onPartialResponse, value]
  );

  return (
    <>
      <div css={mainStyle}>
        <div css={contentStyle} onClick={() => onClick(field._id)}>
          <LabelAtom required={field.required} error={field.error} theme={theme}>
            {getFieldLabel(field.label, values, 'jsx', fieldsOptions, variables, urlParams)}
          </LabelAtom>

          <div css={matrixStyle}>
            <div css={headStyle}>
              <div css={emptyLabel} />
              {field.additionalOptions.map((option) => (
                <div css={scaleLabel} key={option._id}>
                  {field.differentValues ? option.label : option.value}
                </div>
              ))}
            </div>
            {field.options.map((option) => (
              <div css={rowStyle} key={option._id} onChange={console.log}>
                <div css={label}>{field.differentValues ? option.label : option.value}</div>
                {field.additionalOptions.map((additionalOption) => {
                  let active = false;
                  const activeOption = value.find((val) => val[0] === option._id);

                  if (activeOption) active = activeOption[1].findIndex((val) => val === additionalOption._id) !== -1;

                  return (
                    <div
                      key={additionalOption._id}
                      css={input}
                      onClick={() => handleOptionClick(option._id, additionalOption._id)}
                      className={active ? 'active' : ''}>
                      <div css={radio}>{active && <div css={radioDot} />}</div>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>

          <DescriptionAtom theme={theme}>
            {getFieldLabel(field.description, values, 'jsx', fieldsOptions, variables, urlParams)}
          </DescriptionAtom>
        </div>
      </div>
    </>
  );
};

export default Matrix;
