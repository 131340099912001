import React, { useMemo } from 'react';

export default ({ formId, theme, mode, device, placement, scrollbarWidth, userUiSettings, isTouchDevice }) => {
  const uiSettings = useMemo(() => userUiSettings[formId] || {}, [formId, userUiSettings]);

  let boxStyle = useMemo(() => {
    let props = {
      position: 'fixed',
      width: '100%',
      height: '100vh',
      zIndex: 1,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      userSelect: 'none',
      overflow: 'hidden',
      boxSizing: 'border-box',
      backgroundColor: theme.backgroundColor
    };

    if (mode === 'preview') {
      props.top = 0;
      props.left = 'auto';
      props.right = `${scrollbarWidth}px`;
      props.bottom = 'auto';

      if (placement === 'themes') {
        props.maxWidth = `calc(100% - 405px - ${scrollbarWidth}px)`;
      }

      if (placement === 'builder') {
        props.top = '82px';
        props.right = `${(uiSettings.builderLeftBarWidth || 300) + scrollbarWidth}px`;

        props.maxWidth = `calc(100vw - ${(uiSettings.builderLeftBarWidth || 300) + (uiSettings.builderLeftBarWidth || 300) + 51 + scrollbarWidth}px)`;
      }

      if (device === 'phone') {
        props.maxWidth = 400 - scrollbarWidth;
        props.right = 'auto';
      }

      if (device === 'tablet') {
        props.maxWidth = 800 - scrollbarWidth;
        props.right = 'auto';
      }
    }

    return props;
  }, [theme.backgroundColor, mode, scrollbarWidth, placement, device, uiSettings.builderLeftBarWidth]);

  let imageStyle = useMemo(() => {
    let props = {
      position: 'absolute',
      width: '100%',
      height: '100%',
      boxSizing: 'border-box',
      zIndex: 1,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    };

    if (theme.backgroundImage && theme.backgroundUseImage) {
      props.backgroundImage = `url(${theme.backgroundImage})`;
      props.backgroundRepeat = 'no-repeat';
      props.backgroundSize = theme.backgroundImageLayout;
      props.backgroundPosition = theme.backgroundImagePosition;
      if (!isTouchDevice) props.backgroundAttachment = 'fixed';
      props.filter = `blur(${theme.backgroundImageBlur}px)`;
    }

    if (theme.backgroundImageBlur > 0) {
      props.transform = 'scale(1.05)';
    }

    props.opacity = theme.backgroundImageOpacity;

    return props;
  }, [
    isTouchDevice,
    theme.backgroundImage,
    theme.backgroundUseImage,
    theme.backgroundImageLayout,
    theme.backgroundImagePosition,
    theme.backgroundImageBlur,
    theme.backgroundImageOpacity
  ]);

  return (
    <div style={boxStyle}>
      <div style={imageStyle} />
    </div>
  );
};
