import 'react-circular-progressbar/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

import React, { Component, } from 'react';
import QuestionscoutFormEngine from './../form';
import { connect } from 'react-redux';
import Fingerprint from 'fingerprintjs2';

import checkFieldLogic from 'helpers/checkFieldLogic.js';
import checkRequired from 'helpers/checkRequired.js';

import {
  updateValue, submitForm, setRequiredError, removeRequiredError, setGhostMode, uploadFiles, deleteFile, cancelUpload, getVisitorCountry,
  toggleFileUploadHelp, analyticsInit, setFingerprint, analyticsUpdate, updatePage, checkLimits, highlightField, clearSubmitError,
  updateFieldHighlighted, sendPartialResponses, backupResponses, restoreBackup, checkBackup, clearBackup, updateShowWelcomePage, updatePreviousLogic
} from 'store/ducks/form.js';

let firstPartialResponse = true;

function getFingerprint() {
  function get() {
    return new Promise((resolve) => {
      Fingerprint.get((components) => {
        const hash = Fingerprint.x64hash128(components.map((component) => component.value).join(''), 31);

        return resolve(hash);
      });
    });
  }

  return new Promise((resolve) => {
    if (typeof window !== 'undefined' && window.requestIdleCallback) {
      requestIdleCallback(() => get().then(resolve));
    } else {
      setTimeout(() => get().then(resolve), 500);
    }
  });
}

class Form extends Component {
  constructor(props) {
    super(props);

    this.props.checkBackup();

    getFingerprint().then((fingerprint) => {
      return this.props.setFingerprint(fingerprint);
    }).then(() => {
      return this.props.ghost ? Promise.resolve() : this.props.checkLimits();
    }).then(() => {
      // this.props.analyticsInit();
      this.props.getVisitorCountry();
    }).catch(() => { });

    this.state = {
      uiReady: false
    };
  }

  markUiAsReady = () => {
    setTimeout(() => {
      if (typeof document !== 'undefined') {
        document.getElementById('spinnerServerContainer').remove();
        document.body.classList.remove('unscrollable');
        setTimeout(() => {
          const preloads = document.getElementById('preloads');

          if (preloads !== null) preloads.remove();
        }, 1000);
      }
      
      this.setState({ uiReady: true });
    });
  };

  componentDidMount = () => {
    let invervals = 0;

    const interval = setInterval(() => {
      if (document.documentElement.classList.contains('wf-active')) {
        clearInterval(interval);

        this.markUiAsReady();
      } else if (invervals >= 400) {
        clearInterval(interval);

        this.markUiAsReady();
      }

      invervals = invervals + 1;
    }, 10);
  }

  handleChange = (valueObject, valid) => {
    this.props.updateValue(valueObject, valid).then(() => {
      // if (!this.props.disableSave) this.props.analyticsUpdate(); // analytics is disabled
    });
  }

  submitIsDisabled = () => {
    if (this.props.files && this.props.files.length === 0) return false;

    return this.props.files.findIndex((file) => file.loaded !== true) !== -1;
  }

  submitForm = () => {
    const { valid, toSet, toRemove } = checkRequired(this.props.page, this.props.fields, this.props.values, {
      ignorePages: this.props.form.type === 'conversational'
    });

    for (let id of toSet) this.props.setRequiredError(id);
    for (let id of toRemove) this.props.removeRequiredError(id);

    if (!valid || this.props.sending) return;

    this.props.submitForm().then(() => {
      if (!this.props.inIframe) {
        window.scrollTo(0, 0);
      }
    });
  }

  handleNextPage = (field) => {
    const { valid, toSet, toRemove } = checkRequired(this.props.page, this.props.fields, this.props.values, {
      ignorePages: this.props.form.type === 'conversational'
    });

    for (let id of toSet) this.props.setRequiredError(id);
    for (let id of toRemove) this.props.removeRequiredError(id);

    if (!valid) return;

    let page = this.props.page + 1;

    if (checkFieldLogic(field, this.props.values, this.props.fields, this.props.variables)) {
      page = field.conditionsPage;

      this.props.updatePreviousLogic([...this.props.previousLogic, {
        fromPage: page,
        toPage: this.props.page
      }]);
    }

    this.props.updatePage(page);

    window.scrollTo(0, 0);
  }

  handlePreviousPage = () => {
    let page = this.props.page - 1;
    let previousLogic = [ ...this.props.previousLogic ];
    const previousLogicForThatPageIndex = previousLogic.findIndex((logic) => logic.fromPage === this.props.page);

    if (previousLogicForThatPageIndex !== -1) {
      this.props.updatePage(previousLogic[previousLogicForThatPageIndex].toPage);

      previousLogic.splice(previousLogicForThatPageIndex, 1);

      this.props.updatePreviousLogic(previousLogic);
    } else {
      this.props.updatePage(page);
    }

    window.scrollTo(0, 0);
  }

  submitAgain = () => {
    window.location.replace(`${window.location.origin}/${window.location.pathname.split('/')[1]}`);
  }

  handleFormSubmit = (e) => {
    e.preventDefault();

    if (this.props.page !== this.props.pages) {
      this.handleNextPage(null);
    } else {
      this.submitForm();
    }
  }

  handleFileUpload = (newFiles, fieldId) => {
    this.props.uploadFiles(newFiles, fieldId);
  }

  handleFileDelete = (fileId, fieldId) => {
    this.props.deleteFile(fileId, fieldId);
  }

  handleFileCancel = (ref) => {
    this.props.cancelUpload(ref);
  }

  handleRestoreBackup = () => {
    firstPartialResponse = false;
    this.props.restoreBackup();
  }

  handleClearBackup = () => {
    firstPartialResponse = true;
    this.props.clearBackup();
  }

  handlePartialResponses = (fieldId) => {
    this.props.backupResponses();

    if (!this.props.partialResponses || this.props.ghost || this.props.disableSave) return;

    this.props.sendPartialResponses(fieldId, firstPartialResponse);

    firstPartialResponse = false;
  }

  handleWelcomePageClick = () => this.props.updateShowWelcomePage(false);

  render() {
    let {
      form, reactReady, seed, fields, submitError, progressBar, variables, paymentsProvider, urlParams, country, theme, values, submitted, status, inIframe, files, sending, backupExist, waitingForSubmissionId, thankYouPages, defaultThankYouPage, welcomePage, showWelcomePage
    } = this.props;
    
    const {
      uiReady
    } = this.state;

    return (
      <>
        {(reactReady && uiReady && !sending) ? <>
          <QuestionscoutFormEngine
            form={form}
            theme={theme}
            seed={seed}
            values={values}
            inIframe={inIframe}
            mode={'life'}
            status={status}
            submitted={submitted}
            files={files}
            country={country}
            submitError={submitError}
            clearSubmitError={this.props.clearSubmitError}
            backupExist={backupExist}
            thankYouPages={thankYouPages}
            welcomePage={welcomePage}
            showWelcomePage={showWelcomePage}
            showProgressBar={progressBar}
            variables={variables || []}
            urlParams={urlParams}
            paymentsProvider={paymentsProvider}
            handleWelcomePageClick={this.handleWelcomePageClick}
            defaultThankYouPage={defaultThankYouPage}      
            waitingForSubmissionId={waitingForSubmissionId}
            updateFieldHighlighted={this.props.updateFieldHighlighted}
            onChange={this.handleChange}
            submitIsDisabled={this.submitIsDisabled}
            handleNextPage={this.handleNextPage}
            handlePreviousPage={this.handlePreviousPage}
            handleSubmit={() => this.submitForm(fields)}
            handleRestoreBackup={this.handleRestoreBackup}
            handleClearBackup={this.handleClearBackup}
            handleUpload={this.handleFileUpload}
            handleDelete={this.handleFileDelete}
            handleCancel={this.handleFileCancel}
            handleSubmitAgain={this.submitAgain}
            handleHighlightField={this.props.highlightField}
            handlePartialResponses={this.handlePartialResponses}
            builderControl={{}}
          />
        </> : <>
          <div style={{ minHeight: '200px' }}>
            <div className="spinnerServer" />
          </div>
        </>}

        {inIframe && form.type === 'classic' && <div data-iframe-height />}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    form: state.form,

    reactReady: state.form.ready,

    fields: state.form.fields,
    values: state.form.values,
    status: state.form.status,
    error: state.form.error,
    align: state.form.align,
    sending: state.form.sending,
    submissionsLimit: state.form.submissionsLimit,
    files: state.form.files,
    page: state.form.page,
    partialResponses: state.form.partialResponses,
    disableSave: state.form.disableSave,
    pages: state.form.pages,
    owner: state.form.owner || {},
    seed: state.form.seed,
    ghost: state.form.ghost,
    welcomePage: state.form.welcomePage,
    showWelcomePage: state.form.showWelcomePage,
    progressBar: state.form.progressBar,
    backupExist: state.form.backupExist,
    waitingForSubmissionId: state.form.waitingForSubmissionId,
    previousLogic: state.form.previousLogic,
    country: state.form.country,
    variables: state.form.variables,

    thankYouPages: state.form.thankYouPages,
    defaultThankYouPage: state.form.defaultThankYouPage,

    respondentLimitsNumber: state.form.respondentLimitsNumber,

    inIframe: state.form.inIframe,

    submitted: state.form.submitted,

    submittedText: state.form.submittedText,

    paymentsProvider: state.form.paymentsProvider,

    urlParams: state.form.urlParams,

    submitError: state.form.submitError
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setGhostMode: () => dispatch(setGhostMode()),
    updateValue: (valueObject, valid) => dispatch(updateValue(valueObject, valid)),
    submitForm: () => dispatch(submitForm()),
    setRequiredError: (id) => dispatch(setRequiredError(id)),
    removeRequiredError: (id) => dispatch(removeRequiredError(id)),
    uploadFiles: (files, fieldId) => dispatch(uploadFiles(files, fieldId)),
    deleteFile: (fileId, fieldId) => dispatch(deleteFile(fileId, fieldId)),
    cancelUpload: (ref) => dispatch(cancelUpload(ref)),
    toggleFileUploadHelp: (id, value) => dispatch(toggleFileUploadHelp(id, value)),
    analyticsInit: () => dispatch(analyticsInit()),
    setFingerprint: (value) => dispatch(setFingerprint(value)),
    analyticsUpdate: () => dispatch(analyticsUpdate()),
    updatePage: (page) => dispatch(updatePage(page)),
    checkLimits: () => dispatch(checkLimits()),
    highlightField: (id, time) => dispatch(highlightField(id, time)),
    updateFieldHighlighted: (id, index) => dispatch(updateFieldHighlighted(id, index)),
    sendPartialResponses: (fieldId, first) => dispatch(sendPartialResponses(fieldId, first)),
    backupResponses: () => dispatch(backupResponses()),
    restoreBackup: () => dispatch(restoreBackup()),
    checkBackup: () => dispatch(checkBackup()),
    clearBackup: () => dispatch(clearBackup()),
    updateShowWelcomePage: (value) => dispatch(updateShowWelcomePage(value)),
    updatePreviousLogic: (value) => dispatch(updatePreviousLogic(value)),
    getVisitorCountry: (ip) => dispatch(getVisitorCountry(ip)),
    clearSubmitError: () => dispatch(clearSubmitError())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
