/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import React, { useState, useRef, useEffect } from 'react';
import getFieldLabel from 'helpers/getFieldLabel.js';

import { LabelAtom, DescriptionAtom } from './atoms.js';

const ddPipe = createAutoCorrectedDatePipe('dd');
const mmPipe = createAutoCorrectedDatePipe('mm');
const yyyyPipe = createAutoCorrectedDatePipe('yyyy');
const HHPipe = createAutoCorrectedDatePipe('HH', );
const MMPipe = createAutoCorrectedDatePipe('MM');
const AMPMPipe = (value) => {
  if (value.replace(/\s/, '').length === 1 && ['a', 'p'].indexOf(value[0].toLowerCase()) !== -1) {
    return `${value[0]}${value[0] === value[0].toUpperCase() ? 'M' : 'm'}`;
  }

  return value;
};

const HHMax12Pipe = (value) => {
  const afterFirstPipe = HHPipe(value);

  if(afterFirstPipe && Number(afterFirstPipe.value) > 12){
    return "12";
  }
  else{
    return afterFirstPipe
  }
};


const inputPlaceholers = ['dd', 'mm', 'yyyy', '00', '00', 'am/pm'];

const DateTime = ({ form, field, theme, value, values, onChange, onPartialResponse, fieldsOptions, variables, urlParams, onClick, onAfterChange }) => {
  if (!onClick) onClick = () => { };

  const [modified, setModified] = useState(false);
  const [active, setActive] = useState(false);
  const [hideContent, setHideContent] = useState(true);

  let inputsOrder = field.dateTimeFormat.replace(/:|\s/gi, '.').split('.');

  if (field.dateTimeHourMode === '12') {
    inputsOrder.push('ampm');
  }

  if (field.dateTimeMode === 'date') {
    inputsOrder = inputsOrder.filter((element) => element !== 'hour' && element !== 'minute' && element !== 'ampm');
  }

  if (field.dateTimeMode === 'time') {
    inputsOrder = inputsOrder.filter((element) => element !== 'day' && element !== 'month' && element !== 'year');
  }

  const inputsOrderIds = inputsOrder.map((name) => {
    const options = {
      'day': 1,
      'month': 2,
      'year': 3,
      'hour': 4,
      'minute': 5,
      'ampm': 6
    };

    return options[name];
  });

  const alignOptions = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end'
  };

  const inputContainerStyleTypes = {
    style1: {
      borderBottom: `${theme.inputFieldsIdleBorderSize}px solid ${theme.inputFieldsIdleBorderColor}`,
    },
    style2: {
      border: 'none'
    },
    style3: {
      border: `${theme.inputFieldsIdleBorderSize}px solid ${theme.inputFieldsIdleBorderColor}`,
    },
  }

  const mainStyle = css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: alignOptions[theme.inputFieldsDropdownsAlignment],
    margin: `${theme.inputFieldsDropdownsTopMargin || 0}px 0 ${theme.inputFieldsDropdownsBottomMargin || 0}px 0`
  });

  const contentStyle = css({
    width: '100%',
    maxWidth: `${theme.inputFieldsDropdownsWidth}${theme.inputFieldsDropdownsWidthType}`
  });

  const inputContainerStyle = css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'text',
    fontFamily: theme.typographyContentFontFamily,
    background: theme.inputFieldsIdleBackground,
    padding: `${theme.inputFieldsIdleVerticalPadding || 0}px ${theme.inputFieldsIdleHorizontalPadding || 0}px`,
    color: theme.inputFieldsIdleTextColor,
    fontWeight: theme.inputFieldsIdleTextWeight,
    fontSize: theme.inputFieldsIdleTextFontSize,
    borderRadius: `${theme.inputFieldsIdleRoundness}px`,
    boxShadow: theme.inputFieldsIdleShadow ? `${theme.inputFieldsIdleShadowOffsetX}px ${theme.inputFieldsIdleShadowOffsetY}px ${theme.inputFieldsIdleShadowBlur}px ${theme.inputFieldsIdleShadowSpread}px ${theme.inputFieldsIdleShadowColor}` : 'none',
    ...inputContainerStyleTypes[theme.inputFieldsDropdownsType],
    '& input': {
      opacity: hideContent ? 0 : 1,
      padding: '0px',
      margin: '0px',
      color: theme.inputFieldsIdleTextColor,
      fontWeight: theme.inputFieldsIdleTextWeight,
      fontSize: theme.inputFieldsIdleTextFontSize,
      lineHeight: '142%'
    },
    '& input::placeholder': {
      opacity: hideContent ? 0 : 1,
      color: theme.inputFieldsIdlePlaceholderColor,
      fontWeight: theme.inputFieldsIdleTextWeight,
      fontSize: theme.inputFieldsIdlePlaceholderFontSize,
      lineHeight: '142%'
    },
    '& span': {
      opacity: hideContent ? 0 : 1,
      display: 'block',
      lineHeight: '142%'
    },
    '&:hover': (() => {
      if (!theme.inputFieldsHoverEnable) return {};

      const obj = {
        background: theme.inputFieldsHoverBackground,
        borderColor: theme.inputFieldsHoverBorderColor,
        color: theme.inputFieldsHoverTextColor,
        '& input': {
          color: theme.inputFieldsHoverTextColor
        },
        '& input::placeholder': {
          color: theme.inputFieldsHoverPlaceholderColor
        },
      };

      if (theme.inputFieldsHoverShadow) {
        obj.boxShadow = `${theme.inputFieldsHoverShadowOffsetX}px ${theme.inputFieldsHoverShadowOffsetY}px ${theme.inputFieldsHoverShadowBlur}px ${theme.inputFieldsHoverShadowSpread}px ${theme.inputFieldsHoverShadowColor}`;
      } else {
        obj.boxShadow = 'none';
      }

      return obj;
    })(),
    '&.isActive': (() => {
      if (!theme.inputFieldsActiveEnable) return {};

      const obj = {
        background: theme.inputFieldsActiveBackground,
        borderColor: theme.inputFieldsActiveBorderColor,
        color: theme.inputFieldsActiveTextColor,
        '& input': {
          color: theme.inputFieldsActiveTextColor
        },
        '& input::placeholder': {
          color: theme.inputFieldsActivePlaceholderColor
        },
      };

      if (theme.inputFieldsActiveShadow) {
        obj.boxShadow = `${theme.inputFieldsActiveShadowOffsetX}px ${theme.inputFieldsActiveShadowOffsetY}px ${theme.inputFieldsActiveShadowBlur}px ${theme.inputFieldsActiveShadowSpread}px ${theme.inputFieldsActiveShadowColor}`;
      } else {
        obj.boxShadow = 'none';
      }

      return obj;
    })()
  });

  const inputStyle = css({
    background: 'transparent',
    boxSizing: 'border-box',
    position: 'relative',
    zIndex: 2
  });

  const hiddenStyle = css({
    fontSize: theme.inputFieldsIdleTextFontSize,
    fontFamily: theme.typographyContentFontFamily,
    visibility: 'hidden',
    position: 'absolute',
    zIndex: '-1024',
    left: '-10240px',
    display: 'block'
  });

  let refs = {
    ref1: useRef(),
    ref2: useRef(),
    ref3: useRef(),
    ref4: useRef(),
    ref5: useRef(),
    ref6: useRef(),

    hidden1: useRef(),
    hidden2: useRef(),
    hidden3: useRef(),
    hidden4: useRef(),
    hidden5: useRef(),
    hidden6: useRef()
  };

  const handleChange = (e, type, id, size, action) => {
    if (!modified) setModified(true);

    let valueObject = value.value;
    let valid = true;

    const target = e.target;
    const valueWithoutSpaces = target.value.replace(/\s/, '');
    const nextId = action === 'blur' ? id : inputsOrderIds[inputsOrderIds.indexOf(id) + 1];
    const nextElement = document.getElementById(`${field._id}_ref${nextId}`);
    const currentHiddenElement = document.getElementById(`${field._id}_hidden${id}`);

    if (typeof nextElement !== 'undefined' && valueWithoutSpaces.length === size) {
      nextElement[action]();
    }

    if (valueWithoutSpaces.length === 0) {
      currentHiddenElement.innerHTML = inputPlaceholers[id - 1];
    } else {
      currentHiddenElement.innerHTML = target.value;
    }

    if (valueWithoutSpaces.length > 0) {
      target.setAttribute('style', `width: ${currentHiddenElement.getBoundingClientRect().width}px`);
    }

    if (valueObject === null) {
      valueObject = {};

      for (let input of inputsOrder) {
        valueObject[input] = '';
      }
    } else {
      valueObject = JSON.parse(valueObject);
    }

    valueObject[type] = valueWithoutSpaces;

    for (let entry of Object.entries(valueObject)) {
      if (entry[1] === '') valid = false;
    }

    onChange({ [field._id]: JSON.stringify(valueObject) }, valid);
    if (onAfterChange) onAfterChange({ [field._id]: JSON.stringify(valueObject) }, valid);
  };

  const handleKeyDown = (e, currentId, type) => {
    const nextId = inputsOrderIds[inputsOrderIds.indexOf(currentId) + 1] || null;
    const prevId = inputsOrderIds[inputsOrderIds.indexOf(currentId) - 1] || null;

    if (e.keyCode === 37 && prevId === null) return; // left
    if (e.keyCode === 39 && nextId === null) return; // right

    if (e.keyCode === 37) { // left
      refs[`ref${prevId}`].inputElement.focus();
    }

    if (e.keyCode === 39) { // right
      refs[`ref${nextId}`].inputElement.focus();
    }
  };

  const inputs = {};

  if (field.dateTimeMode !== 'time') {
    inputs.day = (ampm, isFirst, isLast) => <MaskedInput mask={[/\d/, /\d/]}
      placeholderChar="&nbsp;"
      placeholder="dd"
      showMask={false}
      inputMode="numeric"
      css={inputStyle}
      readOnly={!isFirst && !modified}
      autoComplete="off"
      guide={true}
      keepCharPositions={true}
      pipe={ddPipe}
      id={`${field._id}_ref1`}
      ref={(input) => { refs[`ref${1}`] = input }}
      onFocus={(e) => {
        setActive(true);
        if (!modified) return;
        const target = e.target;

        setTimeout(() => target.select());
      }}
      onBlur={() => {
        setActive(false);
        onPartialResponse(field._id);
      }}
      onKeyDown={(e) => handleKeyDown(e, 1, 'day')}
      onChange={(e) => handleChange(e, 'day', 1, 2, !isLast ? 'focus' : 'blur')} />;

    inputs.month = (ampm, isFirst, isLast) => <MaskedInput mask={[/\d/, /\d/]}
      placeholderChar="&nbsp;"
      placeholder="mm"
      showMask={false}
      inputMode="numeric"
      css={inputStyle}
      readOnly={!isFirst && !modified}
      autoComplete="off"
      guide={true}
      keepCharPositions={true}
      pipe={mmPipe}
      id={`${field._id}_ref2`}
      ref={(input) => { refs[`ref${2}`] = input }}
      onFocus={(e) => {
        setActive(true);
        if (!modified) return;
        const target = e.target;

        setTimeout(() => target.select());
      }}
      onBlur={() => {
        setActive(false);
        onPartialResponse(field._id);
      }}
      onKeyDown={(e) => handleKeyDown(e, 2, 'month')}
      onChange={(e) => handleChange(e, 'month', 2, 2, !isLast ? 'focus' : 'blur')} />;

    inputs.year = (ampm, isFirst, isLast) => <MaskedInput mask={[/\d/, /\d/, /\d/, /\d/]}
      placeholderChar="&nbsp;"
      placeholder="yyyy"
      showMask={false}
      inputMode="numeric"
      css={inputStyle}
      readOnly={!isFirst && !modified}
      autoComplete="off"
      guide={true}
      keepCharPositions={true}
      pipe={yyyyPipe}
      id={`${field._id}_ref3`}
      ref={(input) => { refs[`ref${3}`] = input }}
      onFocus={(e) => {
        setActive(true);
        if (!modified) return;
        const target = e.target;

        setTimeout(() => target.select());
      }}
      onBlur={() => {
        setActive(false);
        onPartialResponse(field._id);
      }}
      onKeyDown={(e) => handleKeyDown(e, 3, 'year')}
      onChange={(e) => handleChange(e, 'year', 3, 4, !isLast ? 'focus' : 'blur')} />;
  }

  if (field.dateTimeMode !== 'date') {
    inputs.minute = (ampm, isFirst, isLast) => <MaskedInput mask={[/\d/, /\d/]}
      placeholderChar="&nbsp;"
      placeholder="00"
      showMask={false}
      inputMode="numeric"
      css={inputStyle}
      readOnly={!isFirst && !modified}
      autoComplete="off"
      guide={true}
      keepCharPositions={true}
      pipe={MMPipe}
      id={`${field._id}_ref5`}
      ref={(input) => { refs[`ref${5}`] = input }}
      onFocus={(e) => {
        setActive(true);
        if (!modified) return;
        const target = e.target;

        setTimeout(() => target.select());
      }}
      onBlur={() => {
        setActive(false);
        onPartialResponse(field._id);
      }}
      onKeyDown={(e) => handleKeyDown(e, 5, 'minute')}
      onChange={(e) => handleChange(e, 'minute', 5, 2, ampm ? 'focus' : 'blur')} />;

    inputs.hour = (ampm, isFirst, isLast) => <MaskedInput mask={[/\d/, /\d/]}
      placeholderChar="&nbsp;"
      placeholder="00"
      showMask={false}
      inputMode="numeric"
      css={inputStyle}
      readOnly={!isFirst && !modified}
      autoComplete="off"
      guide={true}
      keepCharPositions={true}
      pipe={ampm ? HHMax12Pipe : HHPipe}
      id={`${field._id}_ref4`}
      ref={(input) => { refs[`ref${4}`] = input }}
      onFocus={(e) => {
        setActive(true);
        if (!modified) return;
        const target = e.target;

        setTimeout(() => target.select());
      }}
      onBlur={() => {
        setActive(false);
        onPartialResponse(field._id);
      }}
      onKeyDown={(e) => handleKeyDown(e, 4, 'hour')}
      onChange={(e) => handleChange(e, 'hour', 4, 2, 'focus')} />;

    inputs.ampm = (ampm, isFirst, isLast) => <MaskedInput mask={[/[AaPp]/, /[Mm]/]}
      placeholderChar="&nbsp;"
      placeholder="am/pm"
      showMask={false}
      css={inputStyle}
      readOnly={!modified}
      autoComplete="off"
      guide={false}
      keepCharPositions={false}
      id={`${field._id}_ref6`}
      ref={(input) => { refs.ref6 = input }}
      pipe={AMPMPipe}
      onFocus={(e) => {
        setActive(true);
        if (!modified) return;
        const target = e.target;

        setTimeout(() => target.select());
      }}
      onBlur={() => {
        setActive(false);
        onPartialResponse(field._id);
      }}
      onKeyDown={(e) => handleKeyDown(e, 6, 'ampm')}
      onChange={(e) => handleChange(e, 'ampm', 6, 2, 'blur')} />;
  }

  const forceSizesUpdate = (options = {}) => {
    let loops = 5;

    let initialValue;
    let ref1Node, ref2Node, ref3Node, ref4Node, ref5Node, ref6Node;
    let hidden1Node, hidden2Node, hidden3Node, hidden4Node, hidden5Node, hidden6Node;

    const updateInitialValue = () => {
      if (!value.value) return;

      initialValue = JSON.parse(value.value);

      if (ref1Node) ref1Node.value = initialValue.day || '';
      if (ref2Node) ref2Node.value = initialValue.month || '';
      if (ref3Node) ref3Node.value = initialValue.year || '';
      if (ref4Node) ref4Node.value = initialValue.hour || '';
      if (ref5Node) ref5Node.value = initialValue.minute || '';
      if (ref6Node) ref6Node.value = initialValue.ampm || '';

      if (ref1Node) handleChange({ target: ref1Node }, 'day', 1, 2, 'blur');
      if (ref2Node) handleChange({ target: ref2Node }, 'month', 2, 2, 'blur');
      if (ref3Node) handleChange({ target: ref3Node }, 'year', 3, 4, 'blur');
      if (ref4Node) handleChange({ target: ref4Node }, 'hour', 5, 2, 'blur');
      if (ref5Node) handleChange({ target: ref5Node }, 'minute', 4, 2, 'blur');
      if (ref6Node) handleChange({ target: ref6Node }, 'ampm', 6, 2, 'blur');
    }

    const updateInputsSize = () => {
      if (ref1Node && hidden1Node) ref1Node.setAttribute('style', `width: ${hidden1Node.getBoundingClientRect().width}px`);
      if (ref2Node && hidden2Node) ref2Node.setAttribute('style', `width: ${hidden2Node.getBoundingClientRect().width}px`);
      if (ref3Node && hidden3Node) ref3Node.setAttribute('style', `width: ${hidden3Node.getBoundingClientRect().width}px`);
      if (ref4Node && hidden4Node) ref4Node.setAttribute('style', `width: ${hidden4Node.getBoundingClientRect().width}px`);
      if (ref5Node && hidden5Node) ref5Node.setAttribute('style', `width: ${hidden5Node.getBoundingClientRect().width}px`);
      if (ref6Node && hidden6Node) ref6Node.setAttribute('style', `width: ${hidden6Node.getBoundingClientRect().width}px`);
    };

    setTimeout(() => {
      ref1Node = document.getElementById(`${field._id}_ref1`);
      ref2Node = document.getElementById(`${field._id}_ref2`);
      ref3Node = document.getElementById(`${field._id}_ref3`);
      ref4Node = document.getElementById(`${field._id}_ref4`);
      ref5Node = document.getElementById(`${field._id}_ref5`);
      ref6Node = document.getElementById(`${field._id}_ref6`);
      hidden1Node = document.getElementById(`${field._id}_hidden1`);
      hidden2Node = document.getElementById(`${field._id}_hidden2`);
      hidden3Node = document.getElementById(`${field._id}_hidden3`);
      hidden4Node = document.getElementById(`${field._id}_hidden4`);
      hidden5Node = document.getElementById(`${field._id}_hidden5`);
      hidden6Node = document.getElementById(`${field._id}_hidden6`);

      if (options.updateInitialValue) updateInitialValue();
      if (options.updateInputsSize) updateInputsSize();
      setHideContent(false);

      let useEffectInterval = setInterval(() => {
        if (loops <= 0) {
          clearInterval(useEffectInterval);
        }

        loops = loops - 1;

        if (options.updateInitialValue) updateInitialValue();
        if (options.updateInputsSize) updateInputsSize();
      }, 100);
    });
  };

  useEffect(() => {
    forceSizesUpdate({
      updateInitialValue: true,
      updateInputsSize: true
    });
  }, []);

  useEffect(() => {
    if (value.visible) forceSizesUpdate({
      updateInitialValue: false,
      updateInputsSize: true
    });
  }, [value.visible]);

  return <>
    <div css={mainStyle} onClick={() => onClick(field._id)}>
      <div css={hiddenStyle} id={`${field._id}_hidden1`} ref={(input) => { refs.hidden1 = input }}>{inputPlaceholers[0]}</div>
      <div css={hiddenStyle} id={`${field._id}_hidden2`} ref={(input) => { refs.hidden2 = input }}>{inputPlaceholers[1]}</div>
      <div css={hiddenStyle} id={`${field._id}_hidden3`} ref={(input) => { refs.hidden3 = input }}>{inputPlaceholers[2]}</div>
      <div css={hiddenStyle} id={`${field._id}_hidden4`} ref={(input) => { refs.hidden4 = input }}>{inputPlaceholers[3]}</div>
      <div css={hiddenStyle} id={`${field._id}_hidden5`} ref={(input) => { refs.hidden5 = input }}>{inputPlaceholers[4]}</div>
      <div css={hiddenStyle} id={`${field._id}_hidden6`} ref={(input) => { refs.hidden6 = input }}>{inputPlaceholers[5]}</div>

      <div css={contentStyle} >
        <LabelAtom required={field.required} error={field.error} theme={theme}>{getFieldLabel(field.label, values, 'jsx', fieldsOptions, variables, urlParams)}</LabelAtom>

        <div css={inputContainerStyle} className={active ? 'isActive' : ''} onClick={() => {
          if (!modified) {
            if (field.dateTimeMode === 'datetime' || field.dateTimeMode === 'date') {
              if (field.dateTimeFormat === 'day.month.year hour:minute') refs.ref1.inputElement.focus();
              if (field.dateTimeFormat === 'month.day.year hour:minute') refs.ref2.inputElement.focus();
              if (field.dateTimeFormat === 'year.month.day hour:minute') refs.ref3.inputElement.focus();
            }

            if (field.dateTimeMode === 'time') refs.ref4.inputElement.focus();
          }
        }}>
          {inputsOrder.map((name, index) => {
            let separator = name === 'ampm' ? undefined : field.dateTimeFormat[field.dateTimeFormat.indexOf(name) + name.length];
            let isLast;

            if (field.dateTimeMode === 'datetime') {
              isLast = index === inputsOrder.length - 1;
            } else if (field.dateTimeMode === 'date') {
              isLast = index === 2;
            } else if (field.dateTimeMode === 'time' && field.dateTimeHourMode === '12') {
              isLast = index === 2;
            } else if (field.dateTimeMode === 'time' && field.dateTimeHourMode === '24') {
              isLast = index === 1;
            }

            return <React.Fragment key={name}>
              {inputs[name] && inputs[name](field.dateTimeHourMode === '12', index === 0, isLast)}

              {field.dateTimeMode === 'datetime' && <>
                {separator === '.' && <span>{field.dateTimeDateSeparator}</span>}
                {separator === ' ' && <span>&nbsp;</span>}
                {separator === ':' && <span>:</span>}
              </>}

              {field.dateTimeMode === 'date' && <>
                {separator === '.' && <span>{field.dateTimeDateSeparator}</span>}
              </>}

              {field.dateTimeMode === 'time' && <>
                {separator === ':' && <span>:</span>}
              </>}
            </React.Fragment>;
          })}
        </div>

        <DescriptionAtom theme={theme}>{getFieldLabel(field.description, values, 'jsx', fieldsOptions, variables, urlParams)}</DescriptionAtom>
      </div>
    </div>
  </>;
};

export default DateTime;
