const actions = {
  '=': (a, b) => a === b,
  '!=': (a, b) => a !== b,
  '<': (a, b) => a < b,
  '>': (a, b) => a > b,
  '>=': (a, b) => a >= b,
  '<=': (a, b) => a <= b
};

const isFieldVisible = (fieldId, values) => {
  // Missing data
  if (!fieldId || !values) {
    return false;
  }

  const fieldState = values[fieldId];
  if (!fieldState) {
    return false;
  }

  if (fieldState.visible) {
    const parentFieldId = fieldState.section;

    // It's top level field so this is a final visibility
    if (!parentFieldId || parentFieldId === 'root') {
      return true;
    }
    // True visibility depends on parent section visibility
    else {
      return isFieldVisible(parentFieldId, values);
    }
  }
  // Hidden by logic
  else {
    return false;
  }
};

export default function checkLogic(conditions, conditionsType, calculationConditions, values, fields, variables = []) {
  let results = 0;
  let result;
  let type;
  let valueObj;
  let variableAValue;
  let variableBValue;

  conditions = (conditions || []).filter((condition) => condition.triggerField);

  if (conditions && conditions.length > 0) {
    for (let condition of conditions) {
      const triggerField = fields.find((f) => String(f._id) === String(condition.triggerField));
      const valueIsDefined = values && values[condition.triggerField] && values[condition.triggerField].value !== null;

      if (triggerField && valueIsDefined) {
        // Trigger field is hidden so it can't provide that that will show other fields
        if (!isFieldVisible(triggerField._id, values)) {
          continue;
        }

        type = triggerField.type;

        // Fix for legacy forms with refs
        const getTriggerFieldOption = (optionIdOrRef) => {
          return (
            (triggerField.options || []).find((option) => {
              return option._id === optionIdOrRef || option.ref === optionIdOrRef;
            }) || {}
          );
        };
        const isOptionIdentifierWithin = (option, optionIdsOrRefs) => {
          if (!option) {
            return false;
          } else if (Array.isArray(optionIdsOrRefs)) {
            return (
              optionIdsOrRefs.findIndex((idOrRef) => {
                return option._id === idOrRef || option.ref === idOrRef;
              }) !== -1
            );
          } else {
            return option._id === optionIdsOrRefs || option.ref === optionIdsOrRefs;
          }
        };

        if (type === 'radio') {
          valueObj = JSON.parse(values[condition.triggerField].value);

          const demandedOption = getTriggerFieldOption(condition.value);
          const isDemandedOptionSelected = isOptionIdentifierWithin(demandedOption, valueObj.value);

          if (condition.type === 'equal' && isDemandedOptionSelected) {
            results += 1;
          }
          if (condition.type === 'notequal' && !isDemandedOptionSelected) {
            results += 1;
          }
        }

        if (type === 'dropdown') {
          valueObj = values[condition.triggerField];

          const demandedOption = getTriggerFieldOption(condition.value);
          const isDemandedOptionSelected = isOptionIdentifierWithin(demandedOption, valueObj.value);

          if (condition.type === 'equal' && isDemandedOptionSelected) {
            results += 1;
          }
          if (condition.type === 'notequal' && !isDemandedOptionSelected) {
            results += 1;
          }
        }

        if (type === 'scale') {
          valueObj = values[condition.triggerField];

          if (condition.type === 'equal' && String(valueObj.value) === String(condition.value)) {
            results += 1;
          }

          if (condition.type === 'notequal' && String(valueObj.value) !== String(condition.value)) {
            results += 1;
          }
        }

        if (type === 'checkbox') {
          valueObj = JSON.parse(values[condition.triggerField].value);

          const demandedOption = getTriggerFieldOption(condition.value);
          const isDemandedOptionSelected = isOptionIdentifierWithin(demandedOption, valueObj.values);

          if (condition.type === 'equal' && isDemandedOptionSelected) {
            results += 1;
          }
          if (condition.type === 'notequal' && !isDemandedOptionSelected) {
            results += 1;
          }
        }

        if (type === 'imageChoice') {
          valueObj = JSON.parse(values[condition.triggerField].value);

          const demandedOption = getTriggerFieldOption(condition.value);
          const isDemandedOptionSelected = isOptionIdentifierWithin(demandedOption, valueObj.values);

          if (condition.type === 'equal' && isDemandedOptionSelected) {
            results += 1;
          }
          if (condition.type === 'notequal' && !isDemandedOptionSelected) {
            results += 1;
          }
        }
      }
    }
  }

  if (calculationConditions && calculationConditions.length > 0) {
    for (let condition of calculationConditions) {
      variableAValue = (variables.find((v) => v._id === condition.variableA) || { value: null }).value;
      variableBValue = (variables.find((v) => v._id === condition.variableB) || { value: null }).value;

      if (variableAValue === null || (condition.compare === 'variable' && variableBValue === null)) continue;

      if (condition.compare === 'number' && actions[condition.type](variableAValue, condition.value)) {
        results += 1;
      }

      if (condition.compare === 'variable' && actions[condition.type](variableAValue, variableBValue)) {
        results += 1;
      }
    }
  }

  if (conditionsType === 'any') {
    result = results > 0;
  } else if (conditionsType === 'all') {
    result = results === (conditions || []).length + (calculationConditions || []).length;
  }

  return result;
}
