/** @jsx jsx */
/* @jsxFrag React.Fragment */
import 'react-intl-tel-input/dist/main.css';

import { jsx, css } from '@emotion/core';
import React, { useCallback, useMemo } from 'react';

import Title from '../fields/Title.js';
import Description from '../fields/Description.js';
import Signature from '../fields/Signature.js';
import DateTime from '../fields/DateTime.js';
import DateTimeNative from '../fields/DateTimeNative.js';
import ShortText from '../fields/ShortText.js';
import Dropdown from '../fields/Dropdown.js';
import LongText from '../fields/LongText.js';
import Radio from '../fields/Radio.js';
import Checkbox from '../fields/Checkbox.js';
import Image from '../fields/Image.js';
import Scale from '../fields/Scale.js';
import FileUpload from '../fields/FileUpload.js';
import ImageChoice from '../fields/ImageChoice.js';
import Section from '../fields/Section.js';
import PageBreak from '../fields/PageBreak.js';
import Divider from '../fields/Divider.js';
import Matrix from '../fields/Matrix.js';

import ConversationalTopHelper from './ConversationalTopHelper.js';
import ConversationalBottomHelper from './ConversationalBottomHelper.js';

const alignOptions = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

const ColumnsWrapper = ({ field, children }) => {
  const styles = useMemo(
    () => ({
      main: css({
        display: 'grid',
        gridGap: '1rem',
        alignItems: 'flex-start',
        gridTemplateColumns: (field.columnSizes || [])
          .map((size, index) => (index !== (field.columnSizes || []).length - 1 ? `calc(${size}% - 1rem)` : '1fr'))
          .join(' '),
        '@media (max-width: 600px)': {
          gridTemplateColumns: `repeat(1, 100%)`
        }
      })
    }),
    [field.columnSizes]
  );

  if (field.type !== 'section') return children;
  if (!field.columns || field.columns === 1) return children;

  return <div css={styles.main}>{children}</div>;
};

const FormWrapper = (props) => {
  const {
    placement,
    form,
    fields,
    values,
    theme,
    device,
    mode,
    seed,
    variables,
    fieldsOptions,
    isTouchDevice,
    waitingForSubmissionId,
    handlePartialResponses,
    handleOnNextClick,
    handleOnPrevClick,
    submitIsDisabled,
    handleSubmit,
    handleChange,
    handleAfterChange,
    country,
    handleNextPage,
    handlePreviousPage,
    handleInputBlur,
    handleFieldClick,
    handleUpload,
    handleDelete,
    handleCancel,
    files,
    paymentsProvider,
    urlParams,
    fullpageReady
  } = props;

  const fieldsList = useCallback(
    (field) => {
      let conversationalProps = {};

      if (form.type === 'conversational') {
        conversationalProps = {
          onAfterChange: handleAfterChange
        };
      }

      return (
        <>
          {field && field.type === 'shortText' && (
            <ShortText
              onClick={handleFieldClick}
              onBlur={handleInputBlur}
              isTouchDevice={isTouchDevice}
              urlParams={urlParams}
              field={field}
              fieldsOptions={fieldsOptions}
              variables={variables}
              theme={theme}
              onChange={handleChange}
              values={values}
              onPartialResponse={handlePartialResponses}
              country={country}
              owner={form.owner}
              {...conversationalProps}
            />
          )}
          {field && field.type === 'longText' && (
            <LongText
              onClick={handleFieldClick}
              onBlur={handleInputBlur}
              isTouchDevice={isTouchDevice}
              urlParams={urlParams}
              field={field}
              form={form}
              fieldsOptions={fieldsOptions}
              variables={variables}
              theme={theme}
              onChange={handleChange}
              value={values[field._id].value}
              values={values}
              onPartialResponse={handlePartialResponses}
              {...conversationalProps}
            />
          )}
          {field && field.type === 'dropdown' && (
            <Dropdown
              onClick={handleFieldClick}
              isTouchDevice={isTouchDevice}
              urlParams={urlParams}
              field={field}
              form={form}
              fieldsOptions={fieldsOptions}
              variables={variables}
              theme={theme}
              seed={seed}
              onChange={handleChange}
              value={values[field._id].value}
              values={values}
              onPartialResponse={handlePartialResponses}
              onNextClick={handleOnNextClick}
              {...conversationalProps}
            />
          )}
          {field && field.type === 'radio' && (
            <Radio
              onClick={handleFieldClick}
              onBlur={handleInputBlur}
              isTouchDevice={isTouchDevice}
              urlParams={urlParams}
              field={field}
              form={form}
              fieldsOptions={fieldsOptions}
              variables={variables}
              theme={theme}
              seed={seed}
              onChange={handleChange}
              value={values[field._id].value}
              values={values}
              onPartialResponse={handlePartialResponses}
              onNextClick={handleOnNextClick}
              {...conversationalProps}
            />
          )}
          {field && field.type === 'checkbox' && (
            <Checkbox
              onClick={handleFieldClick}
              onBlur={handleInputBlur}
              isTouchDevice={isTouchDevice}
              urlParams={urlParams}
              field={field}
              form={form}
              fieldsOptions={fieldsOptions}
              variables={variables}
              theme={theme}
              seed={seed}
              onChange={handleChange}
              value={values[field._id].value}
              values={values}
              onPartialResponse={handlePartialResponses}
              onNextClick={handleOnNextClick}
              {...conversationalProps}
            />
          )}
          {field && field.type === 'imageChoice' && (
            <ImageChoice
              onClick={handleFieldClick}
              isTouchDevice={isTouchDevice}
              urlParams={urlParams}
              field={field}
              form={form}
              fieldsOptions={fieldsOptions}
              variables={variables}
              theme={theme}
              seed={seed}
              onChange={handleChange}
              value={values[field._id].value}
              values={values}
              onPartialResponse={handlePartialResponses}
              mode={mode}
              device={device}
              onNextClick={handleOnNextClick}
              {...conversationalProps}
            />
          )}
          {field && field.type === 'datetime' && (!field.dateTimeType || field.dateTimeType === 'custom') && (
            <DateTime
              onClick={handleFieldClick}
              isTouchDevice={isTouchDevice}
              urlParams={urlParams}
              field={field}
              fieldsOptions={fieldsOptions}
              variables={variables}
              theme={theme}
              onChange={handleChange}
              value={values[field._id]}
              values={values}
              onPartialResponse={handlePartialResponses}
              {...conversationalProps}
            />
          )}
          {field && field.type === 'datetime' && field.dateTimeType === 'native' && (
            <DateTimeNative
              onClick={handleFieldClick}
              isTouchDevice={isTouchDevice}
              urlParams={urlParams}
              field={field}
              fieldsOptions={fieldsOptions}
              variables={variables}
              theme={theme}
              onChange={handleChange}
              value={values[field._id]}
              values={values}
              onPartialResponse={handlePartialResponses}
              {...conversationalProps}
            />
          )}
          {field && field.type === 'fileUpload' && (
            <FileUpload
              onClick={handleFieldClick}
              isTouchDevice={isTouchDevice}
              urlParams={urlParams}
              field={field}
              form={form}
              fieldsOptions={fieldsOptions}
              variables={variables}
              theme={theme}
              files={files.filter((file) => file.field === field._id)}
              handleUpload={handleUpload}
              handleDelete={handleDelete}
              handleCancel={handleCancel}
              value={values[field._id].value}
              values={values}
              {...conversationalProps}
            />
          )}
          {field && field.type === 'signature' && (
            <Signature
              onClick={handleFieldClick}
              isTouchDevice={isTouchDevice}
              urlParams={urlParams}
              field={field}
              form={form}
              fieldsOptions={fieldsOptions}
              variables={variables}
              theme={theme}
              onChange={handleChange}
              value={values[field._id].value}
              values={values}
              onPartialResponse={handlePartialResponses}
              {...conversationalProps}
            />
          )}
          {field && field.type === 'title' && (
            <Title
              onClick={handleFieldClick}
              isTouchDevice={isTouchDevice}
              urlParams={urlParams}
              field={field}
              form={form}
              fieldsOptions={fieldsOptions}
              variables={variables}
              theme={theme}
              onChange={handleChange}
              value={values[field._id].value}
              values={values}
              {...conversationalProps}
            />
          )}
          {field && field.type === 'description' && (
            <Description
              onClick={handleFieldClick}
              isTouchDevice={isTouchDevice}
              urlParams={urlParams}
              field={field}
              form={form}
              fieldsOptions={fieldsOptions}
              variables={variables}
              theme={theme}
              onChange={handleChange}
              value={values[field._id].value}
              values={values}
              {...conversationalProps}
            />
          )}
          {field && field.type === 'image' && (
            <Image
              onClick={handleFieldClick}
              isTouchDevice={isTouchDevice}
              urlParams={urlParams}
              field={field}
              form={form}
              fieldsOptions={fieldsOptions}
              variables={variables}
              theme={theme}
              onChange={handleChange}
              value={values[field._id].value}
              values={values}
              {...conversationalProps}
            />
          )}
          {field && field.type === 'scale' && (
            <Scale
              onClick={handleFieldClick}
              isTouchDevice={isTouchDevice}
              urlParams={urlParams}
              field={field}
              form={form}
              fieldsOptions={fieldsOptions}
              variables={variables}
              theme={theme}
              onChange={handleChange}
              value={values[field._id].value}
              values={values}
              onPartialResponse={handlePartialResponses}
              onNextClick={handleOnNextClick}
              {...conversationalProps}
            />
          )}
          {field && field.type === 'matrix' && (
            <Matrix
              onClick={handleFieldClick}
              isTouchDevice={isTouchDevice}
              urlParams={urlParams}
              field={field}
              form={form}
              fieldsOptions={fieldsOptions}
              variables={variables}
              theme={theme}
              onChange={handleChange}
              value={values[field._id].value}
              values={values}
              onPartialResponse={handlePartialResponses}
              onNextClick={handleOnNextClick}
              {...conversationalProps}
            />
          )}

          {field && field.type === 'section' && (
            <Section
              isTouchDevice={isTouchDevice}
              urlParams={urlParams}
              {...props}
              field={field}
              fields={form.fields.filter((f) => f.section === field._id)}
              {...conversationalProps}
            />
          )}

          {field && field.type === 'pageBreak' && form.type === 'classic' && (
            <PageBreak
              onClick={handleFieldClick}
              isTouchDevice={isTouchDevice}
              urlParams={urlParams}
              field={field}
              form={form}
              fieldsOptions={fieldsOptions}
              variables={variables}
              theme={theme}
              disabled={submitIsDisabled()}
              onNextPage={handleNextPage}
              onPreviousPage={handlePreviousPage}
              {...conversationalProps}
            />
          )}
          {field && field.type === 'divider' && form.type === 'classic' && (
            <Divider
              onClick={handleFieldClick}
              isTouchDevice={isTouchDevice}
              urlParams={urlParams}
              field={field}
              form={form}
              fieldsOptions={fieldsOptions}
              variables={variables}
              theme={theme}
              onChange={handleChange}
              value={values[field._id].value}
              values={values}
              {...conversationalProps}
            />
          )}
        </>
      );
    },
    [country, device, fieldsOptions, files, form, handleAfterChange, handleCancel, handleChange, handleDelete, handleFieldClick, handleInputBlur, handleNextPage, handleOnNextClick, handlePartialResponses, handlePreviousPage, handleUpload, isTouchDevice, mode, props, seed, submitIsDisabled, theme, urlParams, values, variables]
  );

  const conversationalContent = useCallback(
    (field, index) => {
      const fieldStyles = {
        display: !values[field._id].visible ? 'none' : 'table',
        opacity: field.opacity || 1,
        transform: field.zoom ? `scale(${field.zoom})` : 'none',
        transition: 'opacity 0.4s ease-in-out, transform .2s ease-in-out',
        boxSizing: 'border-box',
        overscrollBehavior: 'none'
      };

      const conversationalFieldMainStyle = css({
        display: 'flex',
        opacity: fullpageReady ? 1 : 0,
        boxSizing: 'border-box',
        flexDirection: 'row',
        justifyContent: alignOptions[theme.displaySettingsFormDisplayAlignment],
        zIndex: 2,
        position: 'relative'
      });

      const conversationalFieldContent = css({
        margin: (() => {
          const options = {
            desktop: `70px ${theme.displaySettingsFormDisplayDesktopHorizontalMargin || 50}px 70px ${
              theme.displaySettingsFormDisplayDesktopHorizontalMargin || 50
            }px`,
            tablet: `70px ${theme.displaySettingsFormDisplayTabletHorizontalMargin || 30}px 70px ${
              theme.displaySettingsFormDisplayTabletHorizontalMargin || 30
            }px`,
            phone: `70px ${theme.displaySettingsFormDisplayMobileHorizontalMargin || 20}px 70px ${
              theme.displaySettingsFormDisplayMobileHorizontalMargin || 20
            }px`
          };

          if (theme.displaySettingsFormDisplayBox) {
            return `${theme.displaySettingsFormDisplayBoxVerticalPadding || 50}px ${
              theme.displaySettingsFormDisplayBoxHorizontalPadding || 0
            }px`;
          } else {
            if (window.screen.width <= 700) {
              return options.phone;
            } else if (isTouchDevice) {
              return options.tablet;
            } else if (!isTouchDevice) {
              return options.desktop;
            }
          }
        })(),
        marginBottom:
          theme.displaySettingsFormDisplayBoxVerticalPadding < 100 ? 100 : `${theme.displaySettingsFormDisplayBoxVerticalPadding}px`,
        transition: 'opacity 0.4s ease-in-out',
        boxSizing: 'border-box',
        position: 'relative',
        height: '100%',
        // width: `calc(100% - ${(theme.displaySettingsFormDisplayMobileHorizontalMargin || 0) * 2}px)`,
        width: `${theme.displaySettingsFormDisplayDesktopMaxWidth}${theme.displaySettingsFormDisplayDesktopMaxWidthType}`,
        '@media (max-width: 800px)': {
          width: `${theme.displaySettingsFormDisplayTabletMaxWidth}${theme.displaySettingsFormDisplayTabletMaxWidthType}`
        },
        '@media (max-width: 600px)': {
          width: `${theme.displaySettingsFormDisplayMobileMaxWidth}${theme.displaySettingsFormDisplayMobileMaxWidthType}`
        }
      });

      return (
        <div
          key={field._id}
          style={fieldStyles}
          id={field._id}
          data-anchor={field._id}
          data-hidden={field.hidden || !values[field._id].visible}
          className="section">
          <div css={conversationalFieldMainStyle}>
            <div css={conversationalFieldContent} className="sectionContent">
              <ConversationalTopHelper
                theme={theme}
                onPrevClick={handleOnPrevClick}
                messages={form.messages}
                fieldIndex={index}
                isTouchDevice={isTouchDevice}
              />

              <ColumnsWrapper field={field}>{fieldsList(field)}</ColumnsWrapper>

              <ConversationalBottomHelper
                field={field}
                theme={theme}
                form={form}
                values={values}
                value={values[field._id]}
                isTouchDevice={isTouchDevice}
                onNextClick={handleOnNextClick}
                messages={form.messages}
                submitLoading={waitingForSubmissionId}
                submitDisabled={submitIsDisabled() || waitingForSubmissionId}
                onSubmit={handleSubmit}
                paymentsProvider={paymentsProvider}
                placement={placement}
              />
            </div>
          </div>
        </div>
      );
    },
    [
      fieldsList,
      form,
      fullpageReady,
      handleOnNextClick,
      handleOnPrevClick,
      handleSubmit,
      isTouchDevice,
      paymentsProvider,
      submitIsDisabled,
      theme,
      values,
      waitingForSubmissionId
    ]
  );

  const classicContent = useCallback(
    (field) => {
      const fieldStyle = css({
        display: field.hidden ? 'none' : 'block',
        opacity: field.opacity || 1,
        transform: field.zoom ? `scale(${field.zoom})` : 'none',
        transition: 'opacity .3s ease-in-out, transform .2s ease-in-out'
      });

      return (
        <div key={field._id} css={fieldStyle} id={field._id}>
          <ColumnsWrapper field={field}>{fieldsList(field)}</ColumnsWrapper>
        </div>
      );
    },
    [fieldsList]
  );

  return (
    <>
      {form.type === 'conversational' &&
        fields &&
        fields
          .filter((f) => values && values[f._id] && !values[f._id].hidden)
          .map((field, index) => {
            if (field.section === 'root' || !field.section) {
              return conversationalContent(field, index);
            } else {
              return classicContent(field);
            }
          })}

      {form.type === 'classic' &&
        fields &&
        fields
          .filter((f) => values && values[f._id] && values[f._id].visible)
          .map((field) => {
            if ((field.section === 'root' || !field.section) && (field.page || 1) !== form.page) return <></>;

            return classicContent(field);
          })}
    </>
  );
};

export default FormWrapper;
