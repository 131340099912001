/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import Color from 'color';
import React, { useCallback, useMemo } from 'react';
import Translate from '../messages/Translate.js';

const alignOptions = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

const PageBreak = ({ field, form, theme, disabled, page, onNextPage, onPreviousPage, fieldsOptions, variables, urlParams, onClick }) => {  
  if (!onClick) onClick = () => { };

  const translate = useMemo(() => new Translate(form.messages), [form.messages]);

  const getBackground = useCallback((gradient, gradientDirection, background, secondBackground, darken) => {
    if (gradient) {
      if (gradientDirection === 'left-right') {
        return `linear-gradient(225deg, ${Color(background).darken(darken)} 0%, ${Color(secondBackground).darken(darken)} 100%)`;
      } else if (gradientDirection === 'right-left') {
        return `linear-gradient(25deg, ${Color(background).darken(darken)} 0%, ${Color(secondBackground).darken(darken)} 100%)`;
      } else if (gradientDirection === 'top-bottom') {
        return `linear-gradient(180deg, ${Color(background).darken(darken)} 0%, ${Color(secondBackground).darken(darken)} 100%)`;
      } else if (gradientDirection === 'bottom-top') {
        return `linear-gradient(0deg, ${Color(background).darken(darken)} 0%, ${Color(secondBackground).darken(darken)} 100%)`;
      }
    } else if (darken === 0) {
      return background;
    } else {
      return Color(background).darken(darken);
    }
  }, []);

  const mainStyle = useMemo(() => css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: alignOptions[theme.buttonsAlignment],
    fontFamily: theme.typographyButtonsFontFamily,
    margin: `${theme.buttonsTopMargin}px 0 ${theme.buttonsBottomMargin}px 0`
  }), [theme.buttonsAlignment, theme.buttonsBottomMargin, theme.buttonsTopMargin, theme.typographyButtonsFontFamily]);

  const buttonsStyle = useMemo(() => css({
    width: '100%',
    maxWidth: `${theme.buttonsMaxWidth}${theme.buttonsMaxWidthType}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    'div': (() => {
      if (!disabled) return;

      return {
        opacity: '0.7',
        cursor: 'default',
        pointerEvents: 'none'
      };
    })
  }), [disabled, theme.buttonsMaxWidth, theme.buttonsMaxWidthType]);

  const submitButtonStyle = useMemo(() => css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: theme.submitIdleButtonWeight,
    width: form.page === 1 ? '100%' : '65%',
    padding: '0 20px',
    opacity: !disabled ? 1 : 0.7,
    cursor: !disabled ? 'pointer' : 'default',
    pointerEvents: !disabled ? 'auto' : 'none',
    fontSize: `${theme.submitIdleButtonFontSize}px`,
    lineHeight: `142%`,
    fontFamily: theme.typographyButtonsFontFamily,
    color: theme.submitIdleButtonColor,
    textAlign: 'center',
    boxSizing: 'border-box',
    userSelect: 'none',
    minHeight: theme.submitIdleButtonHeight,
    borderRadius: `${theme.submitIdleButtonRoundness}${theme.submitIdleButtonRoundnessType}`,
    border: `${theme.submitIdleButtonBorderSize}px solid ${theme.submitIdleButtonBorderColor}`,
    boxShadow: theme.submitIdleButtonShadow ? `${theme.submitIdleButtonShadowOffsetX}px ${theme.submitIdleButtonShadowOffsetY}px ${theme.submitIdleButtonShadowBlur}px ${theme.submitIdleButtonShadowSpread}px ${theme.submitIdleButtonShadowColor}` : '',
    background: getBackground(theme.submitIdleButtonGradient, theme.submitIdleButtonGradientDirection, theme.submitIdleButtonBackground, theme.submitIdleButtonSecondBackground, 0),

    '&:hover': (() => {
      if (!theme.submitHoverButtonEnable) return {};

      const obj = {
        color: theme.submitHoverButtonColor,
        borderColor: theme.submitHoverButtonBorderColor,
        boxShadow: theme.submitHoverButtonShadow ? `${theme.submitHoverButtonShadowOffsetX}px ${theme.submitHoverButtonShadowOffsetY}px ${theme.submitHoverButtonShadowBlur}px ${theme.submitHoverButtonShadowSpread}px ${theme.submitHoverButtonShadowColor}` : '',
        background: getBackground(theme.submitHoverButtonGradient, theme.submitHoverButtonGradientDirection, theme.submitHoverButtonBackground, theme.submitHoverButtonSecondBackground, 0),
      };

      return obj;
    })(),
    '&:active': (() => {
      if (!theme.submitActiveButtonEnable) return {};

      const obj = {
        color: theme.submitActiveButtonColor,
        borderColor: theme.submitActiveButtonBorderColor,
        boxShadow: theme.submitActiveButtonShadow ? `${theme.submitActiveButtonShadowOffsetX}px ${theme.submitActiveButtonShadowOffsetY}px ${theme.submitActiveButtonShadowBlur}px ${theme.submitActiveButtonShadowSpread}px ${theme.submitActiveButtonShadowColor}` : '',
        background: getBackground(theme.submitActiveButtonGradient, theme.submitActiveButtonGradientDirection, theme.submitActiveButtonBackground, theme.submitActiveButtonSecondBackground, 0),
      };

      return obj;
    })()
  }), [disabled, form.page, getBackground, theme.submitActiveButtonBackground, theme.submitActiveButtonBorderColor, theme.submitActiveButtonColor, theme.submitActiveButtonEnable, theme.submitActiveButtonGradient, theme.submitActiveButtonGradientDirection, theme.submitActiveButtonSecondBackground, theme.submitActiveButtonShadow, theme.submitActiveButtonShadowBlur, theme.submitActiveButtonShadowColor, theme.submitActiveButtonShadowOffsetX, theme.submitActiveButtonShadowOffsetY, theme.submitActiveButtonShadowSpread, theme.submitHoverButtonBackground, theme.submitHoverButtonBorderColor, theme.submitHoverButtonColor, theme.submitHoverButtonEnable, theme.submitHoverButtonGradient, theme.submitHoverButtonGradientDirection, theme.submitHoverButtonSecondBackground, theme.submitHoverButtonShadow, theme.submitHoverButtonShadowBlur, theme.submitHoverButtonShadowColor, theme.submitHoverButtonShadowOffsetX, theme.submitHoverButtonShadowOffsetY, theme.submitHoverButtonShadowSpread, theme.submitIdleButtonBackground, theme.submitIdleButtonBorderColor, theme.submitIdleButtonBorderSize, theme.submitIdleButtonColor, theme.submitIdleButtonFontSize, theme.submitIdleButtonGradient, theme.submitIdleButtonGradientDirection, theme.submitIdleButtonHeight, theme.submitIdleButtonRoundness, theme.submitIdleButtonRoundnessType, theme.submitIdleButtonSecondBackground, theme.submitIdleButtonShadow, theme.submitIdleButtonShadowBlur, theme.submitIdleButtonShadowColor, theme.submitIdleButtonShadowOffsetX, theme.submitIdleButtonShadowOffsetY, theme.submitIdleButtonShadowSpread, theme.submitIdleButtonWeight, theme.typographyButtonsFontFamily]);

  const previousButtonStyle = useMemo(() => css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: theme.prevPageIdleButtonWeight,
    width: '30%',
    padding: '0 20px',
    opacity: !disabled ? 1 : 0.7,
    cursor: !disabled ? 'pointer' : 'default',
    pointerEvents: !disabled ? 'auto' : 'none',
    fontSize:  `${theme.prevPageIdleButtonFontSize}px`,
    lineHeight: `142%`,
    color: theme.prevPageIdleButtonColor,
    textAlign: 'center',
    boxSizing: 'border-box',
    userSelect: 'none',
    minHeight: theme.prevPageIdleButtonHeight,
    borderRadius: `${theme.prevPageIdleButtonRoundness}${theme.prevPageIdleButtonRoundnessType}`,
    border: `${theme.prevPageIdleButtonBorderSize}px solid ${theme.prevPageIdleButtonBorderColor}`,
    boxShadow: theme.prevPageIdleButtonShadow ? `${theme.prevPageIdleButtonShadowOffsetX}px ${theme.prevPageIdleButtonShadowOffsetY}px ${theme.prevPageIdleButtonShadowBlur}px ${theme.prevPageIdleButtonShadowSpread}px ${theme.prevPageIdleButtonShadowColor}` : '',
    background: getBackground(theme.prevPageIdleButtonGradient, theme.prevPageIdleButtonGradientDirection, theme.prevPageIdleButtonBackground, theme.prevPageIdleButtonSecondBackground, 0),

    '&:hover': (() => {
      if (!theme.prevPageHoverButtonEnable) return {};

      const obj = {
        color: theme.prevPageHoverButtonColor,
        borderColor: theme.prevPageHoverButtonBorderColor,
        boxShadow: theme.prevPageHoverButtonShadow ? `${theme.prevPageHoverButtonShadowOffsetX}px ${theme.prevPageHoverButtonShadowOffsetY}px ${theme.prevPageHoverButtonShadowBlur}px ${theme.prevPageHoverButtonShadowSpread}px ${theme.prevPageHoverButtonShadowColor}` : '',
        background: getBackground(theme.prevPageoverButtonGradient, theme.prevPageHoverButtonGradientDirection, theme.prevPageHoverButtonBackground, theme.prevPageHoverButtonSecondBackground, 0),
      };

      return obj;
    })(),
    '&:active': (() => {
      if (!theme.prevPageActiveButtonEnable) return {};

      const obj = {
        color: theme.prevPageActiveButtonColor,
        borderColor: theme.prevPageActiveButtonBorderColor,
        boxShadow: theme.prevPageActiveButtonShadow ? `${theme.prevPageActiveButtonShadowOffsetX}px ${theme.prevPageActiveButtonShadowOffsetY}px ${theme.prevPageActiveButtonShadowBlur}px ${theme.prevPageActiveButtonShadowSpread}px ${theme.prevPageActiveButtonShadowColor}` : '',
        background: getBackground(theme.prevPageActiveButtonGradient, theme.prevPageActiveButtonGradientDirection, theme.prevPageActiveButtonBackground, theme.prevPageActiveButtonSecondBackground, 0),
      };

      return obj;
    })(),
  }), [disabled, getBackground, theme.prevPageActiveButtonBackground, theme.prevPageActiveButtonBorderColor, theme.prevPageActiveButtonColor, theme.prevPageActiveButtonEnable, theme.prevPageActiveButtonGradient, theme.prevPageActiveButtonGradientDirection, theme.prevPageActiveButtonSecondBackground, theme.prevPageActiveButtonShadow, theme.prevPageActiveButtonShadowBlur, theme.prevPageActiveButtonShadowColor, theme.prevPageActiveButtonShadowOffsetX, theme.prevPageActiveButtonShadowOffsetY, theme.prevPageActiveButtonShadowSpread, theme.prevPageHoverButtonBackground, theme.prevPageHoverButtonBorderColor, theme.prevPageHoverButtonColor, theme.prevPageHoverButtonEnable, theme.prevPageHoverButtonGradientDirection, theme.prevPageHoverButtonSecondBackground, theme.prevPageHoverButtonShadow, theme.prevPageHoverButtonShadowBlur, theme.prevPageHoverButtonShadowColor, theme.prevPageHoverButtonShadowOffsetX, theme.prevPageHoverButtonShadowOffsetY, theme.prevPageHoverButtonShadowSpread, theme.prevPageIdleButtonBackground, theme.prevPageIdleButtonBorderColor, theme.prevPageIdleButtonBorderSize, theme.prevPageIdleButtonColor, theme.prevPageIdleButtonFontSize, theme.prevPageIdleButtonGradient, theme.prevPageIdleButtonGradientDirection, theme.prevPageIdleButtonHeight, theme.prevPageIdleButtonRoundness, theme.prevPageIdleButtonRoundnessType, theme.prevPageIdleButtonSecondBackground, theme.prevPageIdleButtonShadow, theme.prevPageIdleButtonShadowBlur, theme.prevPageIdleButtonShadowColor, theme.prevPageIdleButtonShadowOffsetX, theme.prevPageIdleButtonShadowOffsetY, theme.prevPageIdleButtonShadowSpread, theme.prevPageIdleButtonWeight, theme.prevPageoverButtonGradient]);

  return (
    <div css={mainStyle}>
      <div css={buttonsStyle} onClick={() => onClick(field._id)}>
        {form.page !== 1 && <div css={previousButtonStyle} onClick={() => !disabled && onPreviousPage(field)}>{translate.text('pageBreakReturn')}</div>}
        <div css={submitButtonStyle} onClick={() => !disabled && onNextPage(field)}>{translate.text('pageBreakNext')}</div>
      </div>
    </div>
  );
};

export default PageBreak;
